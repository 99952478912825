<template>
  <div class="header-sidebar-menu-item">
    <component :is="icon" class="header-sidebar-menu-item__icon" />
    <router-link
      v-if="item.routeName"
      :to="{ name: item.routeName, params: item.routeParams }"
      active-class=""
      exact-active-class=""
      class="header-sidebar-menu-item__link"
      :class="linkClasses"
      @click="handleClick"
    >
      {{ item.name }}
    </router-link>
    <span v-else class="header-sidebar-menu-item__span">
      {{ item.name }}
    </span>
  </div>
  <div class="header-sidebar-menu-item__children">
    <template
      v-for="child in children"
      :key="`${child.routeName}${item.routeParams || ''}`"
    >
      <router-link
        :to="{ name: child.routeName, params: child.routeParams }"
        active-class=""
        exact-active-class=""
        class="header-sidebar-menu-item__child"
        :class="{
          'header-sidebar-menu-item__child--active': child.active,
        }"
        @click="handleClick"
      >
        {{ child.name }}
      </router-link>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { NavigationItem } from '@types'

import { DASHBOARD_NAME } from '@/store/navigation/utils/const'

import { useNavigationStore } from '@/store/navigation'

import {
  BuildingLibraryIcon,
  CircleStackIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ViewColumnsIcon,
} from '@heroicons/vue/24/outline'

type Props = {
  item: NavigationItem
}

type Emits = {
  click: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const navigationStore = useNavigationStore()

const icon = computed(() => {
  switch (props.item.name) {
    case DASHBOARD_NAME:
      return HomeIcon
    case 'Data':
      return CircleStackIcon
    case 'Connectors':
      return BuildingLibraryIcon
    case 'Analytics':
      return ViewColumnsIcon
    default:
      return QuestionMarkCircleIcon
  }
})

const children = computed(() =>
  navigationStore.getChildNavigation(props.item.name),
)

const linkClasses = computed(() => ({
  'header-sidebar-menu-item__link--active': props.item.active,
}))

const handleClick = () => {
  emit('click')
}
</script>

<script lang="ts">
export default {
  name: 'HeaderSidebarMenuItem',
}
</script>

<style lang="postcss">
.header-sidebar-menu-item {
  @apply flex items-center;

  &__icon {
    @apply w-6 h-6;
    @apply text-gray-400 dark:text-gray-500;
  }

  &__link,
  &__span {
    @apply flex items-center;
    @apply p-2;
    @apply font-medium text-base;
    @apply text-gray-700 dark:text-gray-300;

    &--active {
      @apply text-indigo-400 dark:text-indigo-300;
    }
  }

  &__children {
    @apply flex flex-col;
    @apply ml-0.5;
  }

  &__child {
    @apply flex items-center;
    @apply gap-4 p-2;
    @apply text-base text-gray-500 dark:text-gray-400;

    @apply before:content-[''];
    @apply before:w-1.5 before:h-1.5;
    @apply before:bg-gray-100 dark:before:bg-gray-700;
    @apply before:rounded-full;

    &--active {
      @apply text-indigo-400 dark:text-indigo-300;
    }
  }
}
</style>
