<template>
  <router-link
    ref="mainRef"
    :to="{ name: item.routeName, params: item.routeParams }"
    class="header-menu-item"
    :data-refid="`headerMenu${item.name}`"
    :class="itemClasses"
  >
    {{ item.name }}
  </router-link>
</template>

<script setup lang="ts">
import { computed, inject, useTemplateRef, WritableComputedRef } from 'vue'

import { NavigationItem } from '@types'

type Props = {
  item: NavigationItem
  invisible?: boolean
  disabled?: boolean
}

const props = defineProps<Props>()

defineExpose({
  getEl() {
    // @ts-ignore
    return mainRef.value?.$el
  },
})

const mainRef = useTemplateRef('mainRef')

const sidebarOpen = inject<WritableComputedRef<boolean, boolean>>('sidebarOpen')

const itemClasses = computed(() => ({
  'header-menu-item--shrink': sidebarOpen?.value,
  'header-menu-item--active': props.item?.active,
  'header-menu-item--inactive':
    props.item && ['Analytics'].includes(props.item?.name),
  'header-menu-item--invisible': props.invisible,
  'header-menu-item--disabled': props.disabled,
}))
</script>

<script lang="ts">
export default {
  name: 'HeaderMenuItem',
}
</script>

<style scoped lang="postcss">
.header-menu-item {
  @apply flex items-center;
  @apply px-3.5;
  @apply rounded-t-lg;
  @apply text-sm leading-none;
  @apply text-ellipsis;
  @apply text-slate-950 dark:text-gray-300;
  @apply hover:text-indigo-700 dark:hover:text-indigo-300;
  @apply whitespace-nowrap;

  &--active {
    @apply bg-white text-indigo-700 shadow-md;
    @apply dark:bg-gray-800 dark:text-indigo-300;
  }

  &--inactive {
    @apply opacity-40;
  }

  &--invisible {
    @apply invisible;
    @apply pointer-events-none;
  }

  &--disabled {
    @apply pointer-events-none;
  }
}
</style>
