import { computed, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import {
  AuthSession,
  AuthTokens,
  AuthUser,
  ConfirmResetPasswordInput,
  ConfirmSignUpInput,
  FetchUserAttributesOutput,
  ResetPasswordInput,
  SignInInput,
  SignInWithRedirectInput,
  SignUpInput,
  UpdatePasswordInput,
  confirmResetPassword,
  confirmSignIn,
  confirmSignUp,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  setUpTOTP,
  signIn,
  signInWithRedirect,
  signOut as authSignOut,
  signUp,
  updateMFAPreference,
  updatePassword,
  verifyTOTPSetup,
} from 'aws-amplify/auth'

import { WSInstance } from '@/services/ws/utils/types'
import { UserConnectedAccount } from './utils/types'

import {
  CUSTOM_ALLPOSIT_UUID_KEY,
  CUSTOM_PASSWORD_PROVIDED,
} from './utils/const'
import { ROUTE_NAME } from '@/const'

import { prepareResponseError } from '@/store/utils/helpers'
import { handleCatchedError } from '@/helpers/common'

import { useCapacitor } from '@/plugins/capacitor'
import { useIntercom } from '@/plugins/intercom'
import { useNotifications } from '@/plugins/notification'

import faro from '@/services/faro'
import api from '@/store/api'

import { useMainStore } from '@/store/main'
import { useUserSettingsStore } from './settings'
import { useUserSubscriptionsStore } from './subscriptions'

export const useUserStore = defineStore('user', () => {
  const mainStore = useMainStore()
  const userSettingsStore = useUserSettingsStore()
  const userSubscriptionsStore = useUserSubscriptionsStore()

  const { clearAll, error, success } = useNotifications()

  const router = useRouter()
  const intercom = useIntercom()

  // INIT

  const user = ref<AuthUser>()
  const tokens = ref<AuthTokens>()
  const attributes = ref<FetchUserAttributesOutput>()

  const isMFAEnabled = ref(false)
  const isPasswordProvided = ref(false)

  const loading = ref(false)

  const earlyAccessRequested = useLocalStorage<boolean>(
    'earlyAccessRequested',
    false,
  )

  const ws = inject<WSInstance>('ws')

  // GETTERS

  const isAuthenticated = computed(() => !!getUserId.value)

  const isEarlyAccessRequested = computed(() => earlyAccessRequested.value)

  const getUserId = computed(() => user.value?.userId)
  const getIdToken = computed(() => tokens.value?.idToken?.toString())
  const getAccessToken = computed(() => tokens.value?.accessToken?.toString())

  const getEmail = computed(() => attributes.value?.email)

  const getConnectedAccounts = computed<UserConnectedAccount[]>(() =>
    JSON.parse(attributes.value?.identities || '[]'),
  )

  const getAllpositUuid = computed(
    () => attributes.value?.[CUSTOM_ALLPOSIT_UUID_KEY],
  )

  // SETTERS

  const setUser = async (data: AuthUser) => {
    const session = await fetchAuthSession()

    user.value = data
    tokens.value = session.tokens

    // Init attributes
    attributes.value = await fetchUserAttributes()
    isPasswordProvided.value =
      attributes.value?.[CUSTOM_PASSWORD_PROVIDED] === 'true'

    // WS
    await connectToWS(session)
    const mfa = await fetchMFAPreference()
    isMFAEnabled.value = !!mfa.enabled?.length

    // Init Intercom
    fetchIntercomHash().then(hash => {
      intercom.init(getEmail.value, hash)
    })

    // Init Faro
    getAndValidateUuid().then(allpositUuid => {
      if (!allpositUuid) return
      faro?.initUser(allpositUuid)
    })

    await userSubscriptionsStore.fetchCurrentPlan()
  }

  const setPasswordProvided = () => {
    isPasswordProvided.value = true
  }

  const setEarlyAccessRequested = () => {
    earlyAccessRequested.value = true
  }

  // ACTIONS

  const auth = async () => {
    if (isAuthenticated.value) return
    const user = await getCurrentUser()
    if (user.userId) {
      await setUser(user)
    } else {
      signOut()
    }
  }

  const connectToWS = async (session: AuthSession) => {
    if (!session.tokens?.idToken) return
    await ws?.connect(session.tokens.idToken.toString())
  }

  const signUpEmail = async (data: SignUpInput) => {
    loading.value = true
    try {
      const { isSignUpComplete, userId } = await signUp(data)
      if (!isSignUpComplete) throw 'Unknown user'
      await router.push({
        name: ROUTE_NAME.SIGNUP_CONFIRMATION_CODE,
        query: {
          username: userId,
        },
      })
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Sign up error',
        description,
      })
    } finally {
      loading.value = false
    }
  }

  const signUpConfirm = async (data: ConfirmSignUpInput) => {
    loading.value = true
    try {
      const { isSignUpComplete } = await confirmSignUp(data)
      return isSignUpComplete
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Sign up confirm error',
        description,
      })
      return false
    } finally {
      loading.value = false
    }
  }

  const signInPassword = async (data: SignInInput) => {
    loading.value = true
    try {
      const { nextStep } = await signIn(data)
      if (nextStep.signInStep === 'DONE') {
        await clearAll()
        await useCapacitor()?.savePassword(data as any)
        await router.push({ name: ROUTE_NAME.DASHBOARDS })
      } else {
        await router.push({ name: ROUTE_NAME.SIGNIN_CONFIRMATION })
      }
    } catch (e) {
      handleCatchedError(prepareResponseError(e))
      await error({
        message: 'Incorrect password or email',
        description:
          'Make sure you typed the email and password correctly and then try again',
      })
    } finally {
      loading.value = false
    }
  }

  const signInMFA = async (challengeResponse: string) => {
    loading.value = true
    try {
      const { nextStep } = await confirmSignIn({
        challengeResponse,
      })
      if (nextStep.signInStep !== 'DONE') throw 'Unknown user'
      isMFAEnabled.value = true
      await clearAll()
      await router.push({ name: ROUTE_NAME.DASHBOARDS })
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Signin confirm error',
        description,
      })
    } finally {
      loading.value = false
    }
  }

  const signInSocial = async (data: SignInWithRedirectInput) => {
    loading.value = true
    try {
      await signInWithRedirect(data)
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Signin error',
        description,
      })
    } finally {
      loading.value = false
    }
  }

  const signOut = async (checkBlockers = true) => {
    if (checkBlockers && mainStore.getBlockers.length) {
      for await (const blocker of mainStore.getBlockers) {
        try {
          await blocker()
        } catch (e) {
          return
        }
      }
      mainStore.clearBlockers()
    }
    loading.value = true
    try {
      clear()
      await mainStore.globalClear()
      await authSignOut()
      ws?.disconnect()
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Error during signout',
        description,
      })
    } finally {
      loading.value = false
    }
    await intercom.shutdown()
    router.push({ name: ROUTE_NAME.SIGNIN })
  }

  const forgotPassword = async (data: ResetPasswordInput) => {
    loading.value = true
    try {
      await resetPassword(data)
      return true
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Password reset error',
        description,
      })
      return false
    } finally {
      loading.value = false
    }
  }

  const changePassword = async (data: ConfirmResetPasswordInput) => {
    loading.value = true
    try {
      await confirmResetPassword(data)
      await success({
        message: 'Password successfully changed',
      })
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Error during password change',
        description,
      })
      return false
    } finally {
      loading.value = false
    }
    return true
  }

  const newPassword = async (data: UpdatePasswordInput) => {
    loading.value = true
    try {
      await updatePassword(data)
      await success({
        message: 'Password successfully changed',
      })
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Error during password change',
        description,
      })
    } finally {
      loading.value = false
    }
  }

  const refreshToken = async (): Promise<AuthTokens | undefined> => {
    const session = await fetchAuthSession({ forceRefresh: true })
    if (session.tokens && session.userSub) {
      await connectToWS(session)
      return session.tokens
    } else {
      await signOut(false)
      return undefined
    }
  }

  const setupTOTP = async () => {
    try {
      const output = await setUpTOTP()
      return output.sharedSecret
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Error during installation of two-factor authentication',
        description,
      })
    }
  }

  const verifyTOTP = async (code: string) => {
    loading.value = true
    try {
      await verifyTOTPSetup({ code })
      await updateMFAPreference({ totp: 'PREFERRED' })
      isMFAEnabled.value = true
      await success({
        message: 'You have successfully enabled Two-Factor Authentication',
      })
      return true
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Error setting verification code. Please try again',
        description,
      })
      return false
    } finally {
      loading.value = false
    }
  }

  const disableTOTP = async () => {
    try {
      await updateMFAPreference({ totp: 'DISABLED' })
      isMFAEnabled.value = false
      await success({
        message: 'You disabled Two-Factor Authentication',
      })
    } catch (e) {
      const description = prepareResponseError(e)
      handleCatchedError(description)
      await error({
        message: 'Error during deactivation of two-factor authentication',
        description,
      })
    }
  }

  const fetchIntercomHash = async () => (await api.get('/intercom')).data.hash

  const getAndValidateUuid = async () => {
    if (!getAllpositUuid.value) {
      await error({
        message: 'Settings fetch error',
        description:
          'Something went wrong, please contact support to resolve the problem',
      })
      await signOut(false)
      return undefined
    }
    return getAllpositUuid.value
  }

  const clear = () => {
    user.value = undefined
    tokens.value = undefined
    attributes.value = undefined

    isMFAEnabled.value = false
    isPasswordProvided.value = false

    faro?.resetUser()
    userSettingsStore.clear()
    userSubscriptionsStore.clear()
  }

  return {
    isAuthenticated,

    isEarlyAccessRequested,

    loading,

    setUser,
    setPasswordProvided,
    setEarlyAccessRequested,

    getUserId,
    getIdToken,
    getAccessToken,
    getEmail,
    getAllpositUuid,
    getConnectedAccounts,

    getAndValidateUuid,

    isPasswordProvided,
    isMFAEnabled,

    auth,

    signUpEmail,
    signUpConfirm,

    signInPassword,
    signInMFA,
    signInSocial,

    signOut,

    forgotPassword,
    changePassword,
    newPassword,
    refreshToken,

    setupTOTP,
    verifyTOTP,
    disableTOTP,

    clear,
  }
})
