import { markRaw } from 'vue'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { PaginatedRequest } from '@types'

import { handleCatchedRequestError } from '@/helpers/common'
import { NotificationAction, useNotifications } from '@/plugins/notification'

import { useUserStore } from '@/store/user'
import { useModalsStore } from '@/store/modals'

import CurrenciesTrashPopup from '@/views/Data/Currencies/components/CurrenciesTrashPopup.vue'
import AccountsTrashPopup from '@/views/Data/Accounts/components/AccountsTrashPopup.vue'
import AssetsTrashPopup from '@/views/Data/Assets/components/AssetsTrashPopup.vue'

const {
  VITE_APP_MODE,
  VITE_APP_API_ID,
  VITE_APP_AWS_REGION,
  VITE_APP_API_URL,
} = import.meta.env

let backendUrl: string
let withCredentials: boolean | undefined

if (VITE_APP_API_URL !== undefined) {
  backendUrl = VITE_APP_API_URL
} else {
  if (VITE_APP_MODE === 'local') {
    backendUrl = 'http://localhost:5002/api/'
  } else {
    backendUrl = `https://${VITE_APP_API_ID}.execute-api.${
      VITE_APP_AWS_REGION || 'us-east-1'
    }.amazonaws.com/Prod/api/`
  }
}

if (/api\.dev\S*\.allposit\.com/.test(backendUrl)) {
  withCredentials = true
}

export { backendUrl }

const api = axios.create({
  baseURL: backendUrl,
  withCredentials,
})

api.interceptors.request.use(async config => {
  const correlationId = uuidv4()

  const userStore = useUserStore()

  if (config.headers) {
    config.headers['request-startTime'] = new Date().getTime()
  }

  if (userStore.getIdToken && userStore.getAccessToken) {
    if (!config.url?.includes('s3.amazonaws.com')) {
      config.headers.set('Authorization', userStore.getIdToken)
    }
    config.headers.set('access-token', userStore.getAccessToken)
    const allpositUuid = userStore.getAllpositUuid
    if (allpositUuid) {
      config.headers.set('x-allposit-user', allpositUuid)
    }
    config.headers['x-correlation-id'] = correlationId
  }
  return config
})

api.interceptors.response.use(
  response => {
    const currentTime = new Date().getTime()
    if (response.config.headers) {
      const startTime = Number(response.config.headers['request-startTime'])
      response.headers['request-duration'] = `${currentTime - startTime}`
    }
    return response
  },
  async e => {
    handleCatchedRequestError(e.config, e.response)

    const { error } = useNotifications()
    const userStore = useUserStore()
    const modalsStore = useModalsStore()

    const openDeletedModal = (assetType: string) => {
      let component
      let props: { type?: string } = {}
      let key: string = ''

      if (assetType === 'currency') {
        key = 'deleted-currencies'
        component = CurrenciesTrashPopup
      } else if (assetType === 'income account') {
        key = 'deleted-income-accounts'
        props = { type: assetType }
        component = AccountsTrashPopup
      } else if (assetType === 'expense account') {
        key = 'deleted-expense-accounts'
        props = { type: assetType }
        component = AccountsTrashPopup
      } else {
        key = 'deleted-assets'
        component = AssetsTrashPopup
      }

      const modalInstance = modalsStore.init(key, markRaw(component))

      modalInstance?.open(modalsStore.getZIndex(), props)
    }

    if (e.message === 'canceled') return Promise.reject(e)

    const originalRequest = e.config
    if (!e.response?.status || e.response.status >= 500) {
      await error({
        message: 'Unexpected error',
        description:
          'An error occurred while handling your request. We have been notified of the error and will correct as soon as possible. Please try again. If the problem persists, please try again later.',
      })
      if (!e.response?.status) {
        await userStore.signOut(false)
      }
      return Promise.reject()
    }
    if (e.response.status === 400) {
      let message = 'Something went wrong'
      if (typeof e.response?.data?.message === 'object') {
        message = Object.entries(e.response?.data?.message)
          .map(([title, errors]) => {
            return `${title}: ${
              Array.isArray(errors) ? errors.join(', ') : error
            }`
          })
          .join('; ')
      }
      await error({
        errors: e.response?.data?.errors || null,
        message,
      })
      throw Error(message)
    }
    if (e.response.status === 403) {
      await error({
        message: 'You don`t have permissions for this action',
      })
    }
    if (e.response.status === 409) {
      const message = e.response?.data?.message || 'Something went wrong'

      const actions: NotificationAction[] = []

      if (
        message.includes('asset') &&
        message.includes('soft') &&
        message.includes('deleted')
      ) {
        const assetType = JSON.parse(originalRequest.data).type
        actions.push({
          label: 'Go to deleted assets',
          buttonVariant: 'primary',
          onClick: remove => {
            remove && remove()
            openDeletedModal(assetType)
          },
        })
        actions.push({
          label: 'Close',
          buttonVariant: 'light',
          onClick: remove => {
            remove && remove()
          },
        })
      }

      await error({ message, actions })
      throw Error(message)
    }
    if (!originalRequest._retry && e.response.status === 401) {
      originalRequest._retry = true
      const tokens = await userStore.refreshToken()
      if (tokens) {
        if (!e.config.url?.includes('s3.amazonaws.com')) {
          originalRequest.headers.set(
            'Authorization',
            tokens.idToken?.toString(),
          )
        }
        originalRequest.headers.set(
          'access-token',
          tokens.accessToken.toString(),
        )
        return api(originalRequest)
      } else {
        return Promise.reject()
      }
    } else {
      return Promise.reject(e.response?.data)
    }
  },
)

export const getPaginatedRequest: PaginatedRequest = async (
  url,
  params,
  signal,
) => {
  let sort: any = params.sort
  if (sort && typeof sort !== 'string') {
    sort = JSON.stringify(sort)
  }
  let filter: any = params.filter
  if (filter && typeof filter !== 'string') {
    filter = JSON.stringify(filter)
  }

  const result = await api.get(url, {
    params: {
      ...params,
      sort,
      filter,
    },
    signal,
  })
  return result.data
}

export default api
