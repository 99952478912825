<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="mapping-rules-change">
    <div class="mapping-rules-change__data">
      <span v-html="itemData" />
      <div class="mapping-rules-change__data__description">
        {{ item.description }}
      </div>
    </div>
    <div class="mapping-rules-change__list">
      <div
        v-for="(change, index) in changes"
        :key="index"
        class="mapping-rules-change__row"
      >
        <span>{{ change.title }}:</span>
        <template v-if="change.original">
          <span class="mapping-rules-change__row__original">{{
            change.original
          }}</span>
          <ArrowRightIcon aria-hidden="true" />
        </template>
        <span class="mapping-rules-change__row__replace">{{
          change.replace
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  LinkedDataTransaction,
  TransactionRule,
  TransactionRuleActionRuleType,
} from '@types'

import { numberFormat } from '@/helpers/numbers'

import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'

import { ArrowRightIcon } from '@heroicons/vue/24/outline'

type Props = {
  item: LinkedDataTransaction
  actions: TransactionRule['data']['actions']
}

const props = defineProps<Props>()

const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()

const tagsList = computed(() => linkedDataTransactionsTagsStore.getList)

const itemTags = computed(() => props.item.tags.map(tag => tag.id))

const changes = computed(() =>
  props.actions.reduce(
    (acc, action) => {
      let title, original, replace
      switch (action.type) {
        case TransactionRuleActionRuleType.ADD_TAG:
          title = 'Tags'
          original = itemTags.value
            .map(id => tagsList.value.find(item => item.id === id)?.name)
            .join(', ')
          if (
            action.tag_ids?.some(id => !itemTags.value?.find(tag => tag === id))
          ) {
            const tags = [...new Set([...itemTags.value, ...action.tag_ids])]
            replace =
              tags
                ?.map(id => tagsList.value.find(item => item.id === id)?.name)
                .join(', ') || ''
          }
          break
        case TransactionRuleActionRuleType.SET_CATEGORY:
        case TransactionRuleActionRuleType.SET_SUB_CATEGORY:
          // eslint-disable-next-line no-case-declarations
          if (action.type === TransactionRuleActionRuleType.SET_CATEGORY) {
            title = 'Category'
            original = props.item.category
          } else {
            title = 'Subcategory'
            original = props.item.subcategory
          }
          if (action.value && original !== action.value) {
            replace = action.value
          }
          break
      }
      if (!replace) {
        replace = original
        original = undefined
      }
      acc.push({ title, original, replace })
      return acc
    },
    [] as Record<string, string | undefined>[],
  ),
)

const itemData = computed(() => {
  const { connector_name, account_name, account_number, type, amount } =
    props.item
  return [
    connector_name,
    account_name,
    account_number,
    type,
    `<span class="blurable-number">${numberFormat(amount)}</span>`,
  ].join(' ')
})

defineOptions({
  name: 'LinkedDataRulesChange',
})
</script>

<style lang="postcss">
.mapping-rules-change {
  @apply grid grid-cols-1 sm:grid-cols-2;
  @apply gap-2;
  @apply text-xs;

  &__data {
    @apply text-gray-950 dark:text-gray-50;

    &__description {
      @apply text-gray-400 dark:text-gray-500;
    }
  }

  &__row {
    @apply flex items-center;
    @apply gap-2;

    &__original {
      @apply text-red-500 dark:text-red-400;
      @apply line-through;
    }

    &__replace {
      @apply text-green-600 dark:text-green-500;
    }

    svg {
      @apply w-2;
    }
  }
}
</style>
