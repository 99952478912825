<template>
  <div class="ui-tree__search">
    <div class="ui-tree__search-input">
      <UIPanelSearch
        v-model="search"
        v-model:storage="modelValue"
        v-bind="{ placeholder, size }"
        clearable
        @keydown="handleKeyDown"
      />
    </div>
    <template v-if="search">
      <UILoading v-if="isLoading" message="" />
      <template v-else>
        <span class="ui-tree__search-result">
          {{ displayNumberFound }}
        </span>
        <template v-if="numberFound > 1">
          <UIButton
            v-bind="buttonProps"
            :icon="ArrowUpIcon"
            @click="handleClickUp"
          />
          <UIButton
            v-bind="buttonProps"
            :icon="ArrowDownIcon"
            @click="handleClickDown"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'

import { InputSize } from '@/components/UI/Input/utils/types'

import { scrollToNode } from '../utils/helpers'

import { UILoading, UIButton, UIPanelSearch } from '@ui'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/vue/24/outline'

type Props = {
  numberFound: number

  isLoading: boolean

  placeholder?: string
  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = defineModel<string>({ default: '' })
const search = defineModel<string>('search', { default: '' })

const highlightedNodeIndex = inject<Ref<number>>('highlightedNodeIndex', ref(0))

const buttonProps = computed(
  () =>
    ({
      size: props.size,
      label: '',
      variant: 'light',
    }) as any,
)

const displayNumberFound = computed(
  () =>
    props.numberFound +
    ' result' +
    (props.numberFound > 1 || !props.numberFound ? 's' : ''),
)

const handleClickUp = () => {
  highlightedNodeIndex.value--
  if (highlightedNodeIndex.value < 1) {
    highlightedNodeIndex.value = props.numberFound
  }
  scrollToCurrentNode()
}

const handleClickDown = () => {
  highlightedNodeIndex.value++
  if (highlightedNodeIndex.value > props.numberFound) {
    highlightedNodeIndex.value = 1
  }
  scrollToCurrentNode()
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    handleClickDown()
  }
  if (e.key === 'Enter' && e.shiftKey) {
    handleClickUp()
  }
}

const scrollToCurrentNode = () => {
  scrollToNode(highlightedNodeIndex.value)
}
</script>

<script lang="ts">
export default {
  name: 'TreeSearch',
}
</script>

<style lang="postcss">
.ui-tree {
  &__search {
    @apply flex justify-between;
    @apply shrink-0;
    @apply gap-x-2;
    @apply mb-2;
  }

  &__search-input {
    @apply w-full;
  }

  &__search-result {
    @apply flex items-center;
    @apply whitespace-nowrap;
    @apply text-gray-400;
    @apply text-xs;
  }
}
</style>
