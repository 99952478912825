<template>
  <UIModal
    v-bind="{ isOpen }"
    :type="ModalType.DIALOG"
    title="Connect"
    size="md"
    @hide="handleClose"
  >
    <template #title>
      <button
        v-if="isMoreOptions"
        class="back-btn"
        @click="isMoreOptions = false"
      >
        <ArrowLeftIcon />
      </button>
      <span>{{ addAccountTitle }}</span>
    </template>
    <div v-if="isMoreOptions" class="add-account__buttons">
      <UIButton
        v-for="button in buttonsList"
        :key="button.label"
        :label="button.label"
        full
        variant="secondary"
        @click="button.action()"
      />
    </div>
    <template v-else>
      <template v-if="isExistingConnectorsVisible">
        <div class="add-account__caption">Use existing connector:</div>
        <div class="add-account__list">
          <a
            v-for="stranger in strangers"
            :key="stranger.id"
            class="add-account__item"
            @click="handleClickExisting(stranger)"
          >
            <div class="add-account__item-text">
              {{ stranger.name }}
            </div>
          </a>
        </div>
        <div class="add-account__caption">or new:</div>
      </template>
      <InstitutionsSearch
        ref="InstitutionsSearchRef"
        @click-institution="handleClickInstitution"
      />
      <UIButton
        label="More options"
        full
        variant="secondary"
        @click="isMoreOptions = true"
      />
    </template>
  </UIModal>

  <AddAccountArchlab ref="archlabRef" @cancel="handleCancel" />
  <AddAccountAkoya ref="akoyaRef" @cancel="handleCancel" />
  <AddAccountJPM ref="jpmRef" @cancel="handleCancel" />
  <AddAccountOFX ref="ofxRef" @cancel="handleCancel" />
  <AddAccountYodlee ref="yodleeRef" />
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef, watch } from 'vue'
import { useRouter } from 'vue-router'

import { LinkedDataConnector, LinkedDataManualLoader, ModalType } from '@types'
import { LinkedDataConnectors } from '../../utils/enums'
import { InstatutionData } from '../../utils/types'

import { ROUTE_NAME } from '@/const'
import { IS_PROD_MODE, OVERLAY_DURATION } from '@/const/common'
import { DEFAULT_INSTITUTIONS } from '../../utils/const'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'
import { useLinkedDataConnector } from '../../hooks/useLinkedDataConnector'

import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useLinkedDataStore } from '@/store/linkedData'

import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
import { UIButton, UIModal } from '@ui'
import InstitutionsSearch from './InstitutionsSearch.vue'
import AddAccountAkoya from './AddAccountAkoya.vue'
import AddAccountArchlab from './AddAccountArchlab.vue'
import AddAccountJPM from './AddAccountJPM.vue'
import AddAccountOFX from './AddAccountOFX.vue'
import AddAccountYodlee from './AddAccountYodlee.vue'

defineExpose({
  show(data?: { include?: string[]; exclude?: string[] }) {
    isOpen.value = true
    restrictionInclude.value = data?.include
    restrictionExclude.value = data?.exclude
  },
  reconnect(connector: LinkedDataConnector) {
    handleReconnect(connector)
  },
  import(data: string) {
    switch (data as LinkedDataManualLoader) {
      case LinkedDataManualLoader.ARCHLAB:
        archlabRef.value?.show(true)
        break
      case LinkedDataManualLoader.JPM_CIB:
        jpmRef.value?.show(true)
        break
      case LinkedDataManualLoader.OFX:
        ofxRef.value?.show(true)
        break
    }
  },
  async callback(state: string, code: string) {
    const [providerName, connectorName] = state.split(',')
    if (providerName === undefined || !connectorsList.includes(providerName))
      return
    try {
      await linkedDataStore.callback(code, providerName, connectorName)
    } catch (e) {
      handleCatchedError(e as string)
    }
    router.push({ name: ROUTE_NAME.LINKED_DATA })
  },
})

const connectorsList = Object.values(LinkedDataConnectors).map(item =>
  item.toLowerCase(),
)

const linkedDataStore = useLinkedDataStore()
const linkedDataConnectorsStore = useLinkedDataConnectorsStore()

const router = useRouter()
const { error } = useNotifications()

const InstitutionsSearchRef = useTemplateRef('InstitutionsSearchRef')

const { plaid: handlePlaid, yodlee: handleYodlee } = useLinkedDataConnector(
  (flag: boolean) => {
    yodleeRef.value?.toggle(flag)
  },
  (flag: boolean) => {
    isOpen.value = flag
  },
)

const isProdMode = inject(IS_PROD_MODE)

const loading = ref(false)

const archlabRef = useTemplateRef('archlabRef')
const akoyaRef = useTemplateRef('akoyaRef')
const jpmRef = useTemplateRef('jpmRef')
const ofxRef = useTemplateRef('ofxRef')
const yodleeRef = useTemplateRef('yodleeRef')

const isOpen = ref(false)
const isMoreOptions = ref(false)
const restrictionInclude = ref<string[]>()
const restrictionExclude = ref<string[]>()

const strangers = ref<LinkedDataConnector[]>()

const addAccountTitle = computed(() =>
  isMoreOptions.value ? 'More options' : 'Connect',
)

const isExistingConnectorsVisible = computed(
  () => !restrictionInclude.value && strangers.value?.length,
)

const accountsList = computed(() =>
  DEFAULT_INSTITUTIONS.filter(
    item =>
      (!restrictionExclude.value ||
        !restrictionExclude.value.includes(item.source)) &&
      (!restrictionInclude.value ||
        restrictionInclude.value.includes(item.source)),
  ),
)

const buttonsList = computed(() => {
  if (restrictionInclude.value) return []
  const list = [
    {
      label: `Use ${LinkedDataConnectors.PLAID} connector`,
      action: handlePlaid,
    },
    {
      label: `Use ${LinkedDataConnectors.YODLEE} connector`,
      action: handleYodlee,
    },
    {
      label: `Use ${LinkedDataConnectors.OFX} connector`,
      action: () => {
        isOpen.value = false
        ofxRef.value?.show()
      },
    },
  ]
  if (!isProdMode) {
    list.push(
      {
        label: `Use ${LinkedDataConnectors.JPM_CIB} connector`,
        action: () => {
          isOpen.value = false
          jpmRef.value?.show()
        },
      },
      {
        label: `Use ${LinkedDataConnectors.ARCHLAB} connector`,
        action: () => {
          isOpen.value = false
          archlabRef.value?.show()
        },
      },
    )
  }
  return list
})

const handleClose = () => {
  isOpen.value = false
}

const handleCancel = () => {
  isOpen.value = true
}

const handleClickExisting = async (connector: LinkedDataConnector) => {
  isOpen.value = false
  loading.value = true
  try {
    await linkedDataConnectorsStore.map(connector.id)
    await Promise.all([
      await linkedDataStore.fetch(),
      await linkedDataConnectorsStore.fetch(),
    ])
  } catch (e) {
    handleCatchedError(e as string)
    error({
      message: `Error while mapping Existing connector "${connector.name}"`,
    })
    return
  } finally {
    loading.value = false
  }
}

const handleClickAccount = async (
  source: string,
  data: { name: string; institution_id?: string; logo?: string | null },
) => {
  isOpen.value = false
  loading.value = true

  let connector: LinkedDataConnectors | undefined = undefined
  const params: Record<string, string> = {}

  switch (source) {
    case 'akoya':
      connector = LinkedDataConnectors.AKOYA
      params.connector = data.institution_id || ''
      break
    case 'coinbase':
      connector = LinkedDataConnectors.COINBASE
      break
    default:
      break
  }

  if (!connector) {
    error({
      message: 'Connector not available',
    })
    loading.value = false
    return
  }

  const url = await linkedDataConnectorsStore.getUrl(connector, params)
  if (!url) {
    error({
      message: `${source
        ?.charAt(0)
        .toUpperCase()}${source?.slice(1)} URL not available`,
    })
    loading.value = false
    return
  }

  loading.value = false

  if (connector === LinkedDataConnectors.AKOYA) {
    akoyaRef.value?.show(data.name, url, data.logo)
    return
  }

  location.href = url
}

const handleClickInstitution = async (item: InstatutionData) => {
  switch (item.source) {
    case 'plaid':
      handlePlaid(item.institution_id, item.routing_number)
      break
    case 'yodlee':
      handleYodlee(item.institution_id)
      break
    default:
      handleClickAccount(item.source, item)
  }
}

const handleReconnect = (connector: LinkedDataConnector) => {
  switch (connector.access_method) {
    case 'plaid':
      handlePlaid(connector.id)
      break
    case 'yodlee':
      handleYodlee()
      break
    default: {
      handleClickAccount(connector.access_method, {
        name: connector.name,
        institution_id: connector.source,
        logo: connector.logo,
      })
    }
  }
}

watch(isOpen, async value => {
  if (!value) {
    InstitutionsSearchRef.value?.clearSearch()
    setTimeout(() => {
      restrictionInclude.value = undefined
      restrictionExclude.value = undefined
    }, OVERLAY_DURATION)
    return
  }
  if (restrictionInclude.value) return
  try {
    const data = await linkedDataConnectorsStore.fetchStrangers()
    strangers.value = data
  } catch (e) {
    handleCatchedError(e as string)
    return
  }
})

defineOptions({
  name: 'ConnectorsAddAccount',
})
</script>

<style lang="postcss" scoped>
.back-btn {
  @apply w-6 h-6;
  @apply cursor-pointer;
  @apply text-gray-400 hover:text-gray-500;
  @apply dark:text-gray-300 dark:hover:text-gray-400;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
}
.add-account {
  &__caption {
    @apply mb-2 py-1;
    @apply rounded;
    @apply font-medium text-xs;
    @apply text-center;
    @apply bg-indigo-50 dark:bg-indigo-900;
    @apply text-indigo-600 dark:text-indigo-400;
  }

  &__list {
    @apply mb-4;
    @apply grid gap-4 sm:grid-cols-3;
  }

  &__item {
    @apply min-h-16 p-2 pb-6;
    @apply relative;
    @apply flex items-center justify-center;
    @apply bg-white;
    @apply border border-gray-200 dark:border-gray-700;
    @apply hover:shadow-md;
    @apply overflow-hidden rounded cursor-pointer;

    img {
      @apply max-w-full max-h-[5rem];
    }
  }

  &__buttons {
    @apply space-y-4;
  }
}
</style>
