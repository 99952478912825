<template>
  <div class="wizzard__form">
    <RealEstateTag
      v-model="assetClass"
      :name="ASSET_CLASS"
      :list="ASSET_CLASS_LIST"
    />
    <RealEstateTag
      v-model="investmentStrategy"
      :name="INVESTMENT_STRATEGY"
      :list="INVESTMENT_STRATEGY_LIST"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  ASSET_CLASS,
  ASSET_CLASS_LIST,
  INVESTMENT_STRATEGY,
  INVESTMENT_STRATEGY_LIST,
} from './utils/const'

import RealEstateTag from './RealEstateTag.vue'

const tags = defineModel<Record<string, string>>('tags', { required: true })

const assetClass = computed({
  get() {
    return tags.value[ASSET_CLASS]
  },
  set(value) {
    tags.value[ASSET_CLASS] = value
  },
})

const investmentStrategy = computed({
  get() {
    return tags.value[INVESTMENT_STRATEGY]
  },
  set(value) {
    tags.value[INVESTMENT_STRATEGY] = value
  },
})
</script>

<script lang="ts"></script>

<style lang="postcss"></style>
