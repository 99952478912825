import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { UserPlanName, UserSubscription } from '@types'

import api from '@/store/api'

import { prepareResponseError } from '../utils/helpers'

import { useRepositoriesStore } from '../repositories'

export const useUserSubscriptionsStore = defineStore(
  'user-subscriptions',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()

    const loading = ref(false)

    const currentSubscription = ref<UserSubscription>()

    let abortController = new AbortController()

    const repositoryId = computed(() => repositoriesStore.currentRepositoryId)
    const repositoryPlan = computed(() => repositoriesStore.getCurrentPlan)

    // GETTERS
    const getCurrentSubscription = computed(() => currentSubscription.value)

    const getCurrentPlanName = computed(
      () => getCurrentSubscription.value?.plan.name || UserPlanName.STANDARD,
    )

    const getCurrentPlan = computed(
      () => repositoryPlan.value || getCurrentSubscription.value?.plan,
    )

    const getAvailableWidgets = computed(
      () => getCurrentPlan.value?.dashboard_widgets || [],
    )

    const getDisabledViews = computed<string[]>(() => [])

    const getMaxReposNumber = computed(
      () => getCurrentSubscription.value?.plan.max_repos_number || 1,
    )

    // ACTIONS

    const fetch = async () => {
      loading.value = true
      try {
        const result = await api.get('subscription/plans', {
          signal: abortController.signal,
        })
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const fetchCurrentPlan = async () => {
      loading.value = true
      try {
        const result = await api.get('subscription', {
          signal: abortController.signal,
        })
        currentSubscription.value = result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const fetchSession = async (pricing_page_id: string) => {
      loading.value = true
      try {
        const result = await api.get('pricing_page_session', {
          params: {
            pricing_page_id,
            repository_id: repositoryId.value,
          },
          signal: abortController.signal,
        })
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      currentSubscription.value = undefined
    }

    return {
      loading,

      fetch,
      fetchCurrentPlan,
      fetchSession,

      getCurrentSubscription,
      getCurrentPlanName,
      getAvailableWidgets,
      getDisabledViews,
      getMaxReposNumber,

      cancel,
      clear,
    }
  },
)
