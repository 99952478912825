<template>
  <Dropdown
    ref="inputRef"
    v-bind="{ ...$attrs, ...outerProps, idKey, valueKey }"
    v-model="modelValue"
    v-model:search="searchModel"
    @select="handleSelect"
    @search="handleSearch"
    @click:esc="handleClickEsc"
    @click:enter="handleClickEnter"
    @click:tab="handleClickTab"
    @blur="handleBlur"
    @focus="handleFocus"
  >
    <template #item="slotProps">
      <template v-if="frozen">
        <slot v-bind="slotProps" name="item" />
      </template>
      <EditItem
        v-else
        v-bind="{ ...slotProps, valueKey, lockedKey, disallowDelete }"
        @update="handleUpdate"
        @delete="handleDelete"
      >
        <slot v-bind="slotProps" name="item" />
      </EditItem>
    </template>
    <template v-if="isNewButtonVisible" #footer>
      <div class="ui-input-dropdown-editable__footer">
        <UIButton
          ref="buttonRef"
          :label="`${t('New')}: ${searchModel}`"
          type="button"
          full
          @mousedown.stop="handleCreate"
          @keyup.enter="handleCreate"
          @keydown.tab="handleButtonTab"
        />
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { CommonEmits, CommonProps, InputData } from '../utils/types'
import { InputDropdownItem } from './utils/types'

import { useLocale } from '@/plugins/i18n'

import EditItem from './components/EditItem.vue'
import Dropdown from './Dropdown.vue'
import { UIButton } from '@ui'

export type Props = CommonProps & {
  data?: InputDropdownItem[]
  disallowDelete?: boolean
  frozen?: boolean
  idKey?: string
  lockedKey?: string
  valueKey?: string
}

type Emits = CommonEmits & {
  select: [data: InputDropdownItem]
  search: [data: string]
  create: [data: string]
  update: [data: string, value: string]
  delete: [data: string, callback: () => void]
}

const { idKey = 'value', valueKey = 'value', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<InputData>()
const searchModel = defineModel<string>('search', { default: '' })

const exposeObj = {
  toggle(flag: boolean) {
    inputRef.value?.toggle(flag)
    return exposeObj
  },
  blur() {
    inputRef.value?.blur()
    return exposeObj
  },
  focus() {
    inputRef.value?.focus()
    return exposeObj
  },
  select() {
    inputRef.value?.select()
    return exposeObj
  },
  clear() {
    inputRef.value?.clear()
    return exposeObj
  },
  reset() {
    inputRef.value?.reset()
    return exposeObj
  },
  begin() {
    inputRef.value?.begin()
    return exposeObj
  },
}

defineExpose(exposeObj)

const { t } = useLocale('components.UI.Input')

const inputRef = useTemplateRef('inputRef')
const buttonRef = useTemplateRef('buttonRef')

const outerProps = computed(() => props)

const isNewButtonVisible = computed(
  () =>
    searchModel.value &&
    !props.data?.find(item => item[valueKey] === searchModel.value),
)

const handleSelect = (data: InputDropdownItem) => {
  emit('select', data)
}

const handleSearch = (data: string) => {
  emit('search', data)
}

const handleClickEsc = (e: KeyboardEvent) => {
  emit('click:esc', e)
}

const handleClickEnter = (e: KeyboardEvent) => {
  emit('click:enter', e)
}

const handleClickTab = async (e: KeyboardEvent) => {
  emit('click:tab', e)
  if (!searchModel.value) return
  setTimeout(() => {
    buttonRef.value?.focus()
  })
}

const handleBlur = () => {
  emit('blur')
}

const handleFocus = () => {
  if (isNewButtonVisible.value) {
    exposeObj.toggle(true)
  }
  emit('focus')
}

const handleButtonTab = () => {
  exposeObj.blur()
}

const handleCreate = () => {
  exposeObj.toggle(false)
  emit('create', searchModel.value)
}

const handleUpdate = (data: InputDropdownItem, value: string) => {
  emit('update', data[idKey], value)
}

const handleDelete = (data: InputDropdownItem, callback: () => void) => {
  emit('delete', data[idKey], callback)
}
</script>

<script lang="ts">
export default {
  name: 'InputEditableDropdown',
}
</script>

<style lang="postcss">
.ui-input-dropdown-editable {
  &__footer {
    @apply px-4 py-2;
  }
}
</style>
