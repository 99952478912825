<template>
  <router-link
    v-if="item"
    ref="mainRef"
    :to="{ name: item.routeName, params: item.routeParams }"
    class="main-menu-item"
    :data-refid="`mainMenu${item.name}`"
    :class="itemClasses"
  >
    {{ item.name }}
    <span v-if="isDirty" class="main-menu-item__dirty"> *</span>
  </router-link>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { NavigationItem } from '@types'

type Props = {
  item?: NavigationItem
  invisible?: boolean
}

const props = defineProps<Props>()

defineExpose({
  getEl() {
    // @ts-ignore
    return mainRef.value?.$el
  },
})

const mainRef = useTemplateRef('mainRef')

const itemClasses = computed(() => ({
  'main-menu-item--active': props.item?.active,
  'main-menu-item--invisible': props.invisible,
}))

const isDirty = computed(() => props.item?.isDirty)
</script>

<script lang="ts">
export default {
  name: 'MenuItem',
}
</script>

<style scoped lang="postcss">
.main-menu-item {
  @apply pt-3.5 pb-3;
  @apply text-sm text-gray-600 dark:text-gray-300;
  @apply hover:text-indigo-700 dark:hover:text-indigo-300;
  @apply border-b-2 border-transparent;
  @apply whitespace-nowrap;

  &--active {
    @apply text-indigo-700 dark:text-indigo-300;
    @apply border-indigo-700 dark:border-indigo-300;
  }

  &--invisible {
    @apply invisible;
    @apply pointer-events-none;
  }

  &__dirty {
    @apply text-yellow-500;
  }
}
</style>
