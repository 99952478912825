<template>
  <div ref="containerRef" class="e-chart" />
</template>

<script setup lang="ts" generic="T extends EChartOption.Series">
import { nextTick, shallowRef, useTemplateRef, watch } from 'vue'
import { useDark, useResizeObserver } from '@vueuse/core'

import {
  dispose as disposeEcharts,
  EChartOption,
  ECharts,
  init as initEcharts,
  registerTheme,
} from 'echarts'

import lightTheme from './utils/themes/light'
import darkTheme from './utils/themes/dark'

type Props = {
  option: EChartOption<T>
  selectedIndex?: number
}

type Emits = {
  'click:item': [index: number, name: string]
  'click:legend': [name: string, selected: Record<string, boolean>]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  getInstance() {
    return instance.value
  },
})

props.option.series?.[0]?.data

const isDark = useDark()

const containerRef = useTemplateRef('containerRef')

const instance = shallowRef<ECharts>()

registerTheme('light', lightTheme)
registerTheme('dark', darkTheme)

const init = () => {
  if (!containerRef.value) return

  const theme = isDark.value ? 'dark' : 'light'
  const chart = initEcharts(containerRef.value, theme)

  chart.on('click', (data: any) => {
    emit('click:item', data.dataIndex, data.name)
  })

  chart.on('legendselectchanged', (data: any) => {
    emit('click:legend', data.name, data.selected)
  })

  if (props.selectedIndex !== undefined) {
    nextTick(() => {
      instance.value?.dispatchAction({
        type: 'select',
        dataIndex: props.selectedIndex,
      })
    })
  }

  useResizeObserver(containerRef, () => {
    chart?.resize()
  })

  return chart
}

const dispose = () => {
  if (!instance.value) return
  disposeEcharts(instance.value)
  instance.value = undefined
}

watch(
  () => props.option,
  async value => {
    if (!containerRef.value) {
      await nextTick()
    }
    if (!instance.value) {
      instance.value = init()
    } else {
      instance.value.clear()
    }
    instance.value?.setOption(value)
  },
  { immediate: true, deep: true },
)

watch(
  () => props.selectedIndex,
  (dataIndex, prevIndex) => {
    if (dataIndex === undefined) {
      if (prevIndex !== undefined) {
        instance.value?.dispatchAction({
          type: 'unselect',
          dataIndex: prevIndex,
        })
      }
      return
    }
    instance.value?.dispatchAction({ type: 'select', dataIndex })
  },
  { immediate: true },
)

watch(isDark, () => {
  dispose()
  instance.value = init()
  instance.value?.setOption(props.option)
})
</script>

<script lang="ts">
export default {
  name: 'EChart',
}
</script>

<style lang="postcss">
.e-chart {
  @apply w-full min-h-[15rem];
}
</style>
