<template>
  <div
    v-tooltip.permanent="tooltip"
    :data-cell="isCell"
    :data-edit-cell="editCell || undefined"
    :data-checked="isDataChecked"
    :data-column="columnName"
    class="ui-grid__cell"
    :class="mainClasses"
    @click="handleClick"
    @dblclick="handleDblClick"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <RowCellActions v-if="isActionsVisible" @mouseup.stop @mousedown.stop>
      <slot name="actions" v-bind="{ item }" :size="'small' as ButtonSizes" />
    </RowCellActions>
    <RowCellMenu v-if="isMenuVisible" @click="handleClickMenu" />
    <div v-if="isCaptionVisible" class="ui-grid__caption">
      {{ displayCaption }}:&nbsp;
    </div>
    <div ref="valueRef" class="ui-grid__cell-value" :class="valueClasses">
      <slot
        v-bind="{
          active,
          columnName,
          displayValue,
          editCell,
          editRow,
          item,
        }"
      >
        {{ displayValue }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import {
  ComputedRef,
  Ref,
  computed,
  inject,
  ref,
  useSlots,
  useTemplateRef,
} from 'vue'
import { get } from 'lodash'
import { useResizeObserver } from '@vueuse/core'

import {
  GridColumn,
  GridItem,
  GridValueGetter,
  GridValuePaster,
} from '../utils/types'

import RowCellMenu from './RowCellMenu.vue'
import RowCellActions from './RowCellActions.vue'
import { ButtonSizes } from '../../Button/utils/types'

type Props = {
  column?: GridColumn
  item: T

  active?: boolean
  editRow?: boolean
  editCell?: boolean

  isFirst?: boolean
  isLast?: boolean

  isCollapsed?: boolean
}

type Emits = {
  click: []
  dblclick: []
  'click:menu': []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  paste(data: string | number, check = true) {
    if (!columnName.value) return
    valuePaster?.(columnName.value, props.item, data, check)
  },
})

const slots = useSlots()

const disabled = inject('disabled', false)
const hasRowMenu = inject('hasRowMenu', false)
const isHovered = inject<Ref<boolean>>('isHovered')
const isChecked = inject<ComputedRef<boolean>>('isChecked')
const valueGetter = inject<GridValueGetter>('valueGetter')
const valuePaster = inject<GridValuePaster>('valuePaster')

const valueRef = useTemplateRef('valueRef')
const isValueEllipsis = ref(false)

const columnName = computed(() => props.column?.name)
const displayCaption = computed(() => props.column?.caption)

const value = computed(() => {
  return (
    props.item &&
    columnName.value &&
    (valueGetter?.(columnName.value, props.item) ||
      get(props.item, columnName.value.split('.')))
  )
})

const displayValue = computed(() => {
  if (!props.item) return value.value
  const formatter = props.column?.formatter
  return formatter
    ? formatter(value.value, props.item as GridItem)
    : value.value
})

const isCell = computed(() =>
  disabled || !props.column || props.column.silent || props.editRow
    ? undefined
    : 'true',
)
const isDataChecked = computed(() =>
  !isCell.value || !isChecked?.value ? undefined : 'true',
)

const isEditing = computed(() => props.editRow || props.editCell)

const mainClasses = computed(() => ({
  'ui-grid__cell--first': !props.isCollapsed && props.isFirst,
  'ui-grid__cell--last': !props.isCollapsed && props.isLast,
  'ui-grid__cell--hovered': !props.isCollapsed && !disabled && isHovered?.value,
  'ui-grid__cell--highlighted':
    !props.isCollapsed && props.active && !props.editRow,
  'ui-grid__cell--checkbox': !props.column,
  [`${props.column?.cellClasses}`]: props.column?.cellClasses,
}))

const valueClasses = computed(() => ({
  'ui-grid__cell-value--truncated':
    props.column && !props.column?.lineClamp && !isEditing.value,
  'ui-grid__cell-value--edit': props.editCell,
  [`line-clamp-${props.column?.lineClamp}`]:
    props.column?.lineClamp && !isEditing.value,
  [`${props.column?.cellValueClasses}`]: props.column?.cellValueClasses,
}))

const isMenuVisible = computed(
  () =>
    hasRowMenu &&
    !props.editRow &&
    ((!props.isCollapsed && props.isFirst) ||
      (props.isCollapsed && props.isLast)),
)

const isActionsVisible = computed(
  () =>
    slots.actions &&
    !props.editRow &&
    ((!props.isCollapsed && props.isFirst) ||
      (props.isCollapsed && props.isLast)),
)

const isCaptionVisible = computed(() => props.column && props.isCollapsed)

const tooltip = computed(() => {
  let content
  if (props.column?.tooltip && columnName.value) {
    content = props.column.tooltip(columnName.value, props.item)
  } else if (isValueEllipsis.value) {
    content = displayValue.value
  }
  if (!content) return
  return {
    content,
    handleResize: true,
  }
})

const handleDblClick = () => {
  emit('dblclick')
}

const handleClick = () => {
  emit('click')
}

const handleClickMenu = () => {
  emit('click:menu')
}

const handleMouseEnter = () => {
  isHovered && (isHovered.value = true)
}

const handleMouseLeave = () => {
  isHovered && (isHovered.value = false)
}

useResizeObserver(valueRef, () => {
  if (!valueRef.value || isEditing.value) return
  isValueEllipsis.value =
    !props.isCollapsed &&
    (valueRef.value.offsetHeight < valueRef.value.scrollHeight ||
      valueRef.value.offsetWidth < valueRef.value.scrollWidth)
})
</script>

<script lang="ts">
export default {
  name: 'GridRowCell',
}
</script>

<style scoped lang="postcss">
.ui-grid__cell {
  @apply relative;
  @apply flex items-center;
  @apply relative;
  @apply px-4 py-1;

  &:not(.ui-grid__cell--edit) {
    @apply cursor-pointer;
  }

  &--hovered {
    @apply bg-gray-50 dark:bg-gray-850;
  }

  &--highlighted {
    @apply bg-indigo-50;
    @apply dark:bg-indigo-900 dark:bg-opacity-40;
  }

  &--checkbox {
    @apply absolute top-[0.575rem] -right-1.5;
    @apply z-10;
  }

  &--checkbox + & {
    @apply pr-10;
  }

  &--span-2 {
    @apply col-span-2;
  }

  &[data-selected]:not([data-edit-cell]) {
    @apply before:content-[''];
    @apply before:absolute before:inset-[1px];
    @apply before:border;
    @apply before:border-indigo-300;

    &[data-copy] {
      @apply before:border-dashed;
    }
  }

  &[data-edit-cell] {
    @apply min-w-[7rem];
  }
}

.ui-grid__cell-value {
  @apply relative;
  @apply text-xs;
  @apply text-gray-500 dark:text-gray-400;

  &--truncated {
    @apply truncate;
  }

  &--edit {
    @apply -mx-1.5;
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__cell {
    @apply min-h-[3.1rem];
    @apply px-2 py-0;
    @apply border-b border-gray-200 dark:border-gray-600;

    &--first {
      @apply pl-6 lg:pl-8;
    }

    &--last {
      @apply pr-6 lg:pr-8;
    }

    &--checkbox {
      @apply relative top-auto right-auto;
      @apply sticky left-0 z-20;
      @apply bg-white dark:bg-gray-800;

      &.ui-grid__cell--hovered {
        @apply bg-gray-50 dark:bg-gray-850;
      }
      &.ui-grid__cell--highlighted {
        @apply bg-indigo-50 dark:bg-indigo-900 dark:bg-opacity-40;
      }
    }

    &--checkbox + & {
      @apply pr-2;
    }

    &--span-2 {
      @apply col-auto;
    }

    &--right {
      @apply text-right;
    }
  }

  .ui-grid__cell-value {
    @apply flex-auto;
  }
}

.ui-grid__caption {
  @apply shrink-0;
  @apply font-medium uppercase;
  @apply tracking-wider;
  @apply text-xs;
  @apply text-ellipsis overflow-x-hidden;
}
</style>
