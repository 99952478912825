import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { LinkedDataTransaction, PaginatedMeta, PaginatedResponse } from '@types'
import { TransactionRule } from './utils/types'

import { PAGE_SIZE } from '@/const/common'

import api, { getPaginatedRequest } from '@/store/api'
import { prepareResponseError, updateStoreListItem } from '../utils/helpers'

import { useLinkedDataTransactionsPivotStore } from './transactionsPivot'
import { useRepositoriesStore } from '../repositories'

export const useLinkedDataMappingRulesStore = defineStore(
  'linked-data-mapping-rules',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()
    const linkedDataTransactionsPivotStore =
      useLinkedDataTransactionsPivotStore()

    const list = ref<TransactionRule[]>([])
    const pagination = ref<PaginatedMeta>()

    const initFlag = ref(false)

    const loading = ref(true)
    const loadingAction = ref(false)

    let abortController = new AbortController()

    const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

    // GETTERS
    const getList = computed(() => list.value)
    const getPagination = computed(() => pagination.value)

    // SETTERS

    const setList = (data: TransactionRule[], meta?: PaginatedMeta) => {
      list.value = data
      pagination.value = meta
    }

    // ACTIONS

    const fetch = async (
      search?: string,
      page_no = 0,
      signal?: AbortSignal,
    ) => {
      loading.value = true
      try {
        const { data, meta } = await getPaginatedRequest<TransactionRule>(
          'rules',
          {
            repository_id: repositoryId.value,
            search,
            page_no,
          },
          signal,
        )
        setList(data, meta)
        initFlag.value = true
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const fetchTransactions = async (
      ruleData: TransactionRule['data'],
      page_no = 0,
      page_size = PAGE_SIZE,
      signal?: AbortSignal,
    ) => {
      try {
        const { data } = await api.post<
          PaginatedResponse<LinkedDataTransaction>
        >(
          `rules/transactions_preview`,
          {
            ...ruleData,
            repository_id: repositoryId.value,
            page_no,
            page_size,
          },
          { signal },
        )
        return data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const store = async (
      data: TransactionRule,
    ): Promise<TransactionRule | undefined> => {
      loadingAction.value = true
      try {
        const result = await api.post(`rules`, {
          ...data,
          repository_id: repositoryId.value,
        })
        if (data.apply_to_existing_transactions) {
          linkedDataTransactionsPivotStore.clear()
        }
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const update = async (
      id: string,
      data: Partial<TransactionRule>,
    ): Promise<TransactionRule | undefined> => {
      loadingAction.value = true
      try {
        const result = await api.put(`rules/${id}`, data)
        updateStoreListItem(list.value, result.data)
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const reorder = async (
      id: string,
      newIndex: number,
      oldIndex: number,
    ): Promise<TransactionRule | undefined> => {
      try {
        const result = await api.put(`rules/${id}/reorder`, {
          repository_id: repositoryId.value,
          order: newIndex + 1,
        })
        const item = list.value[oldIndex]
        list.value.splice(oldIndex, 1)
        list.value.splice(newIndex, 0, item)
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const destroy = async (id: string): Promise<void> => {
      loadingAction.value = true
      try {
        await api.delete(`rules/${id}?repository_id=${repositoryId.value}`)
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const apply = async (ruleData: TransactionRule['data']): Promise<void> => {
      loadingAction.value = true
      try {
        const result = await api.post('rules/apply', {
          ...ruleData,
          repository_id: repositoryId.value,
        })
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const enable = async (id: string): Promise<void> => {
      loadingAction.value = true
      try {
        const result = await api.put(`rules/${id}/enable`, {
          repository_id: repositoryId.value,
        })
        updateStoreListItem(list.value, result.data)
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const disable = async (id: string): Promise<void> => {
      loadingAction.value = true
      try {
        const result = await api.put(`rules/${id}/disable`, {
          repository_id: repositoryId.value,
        })
        updateStoreListItem(list.value, result.data)
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loadingAction.value = false
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      initFlag.value = false

      loading.value = true
      loadingAction.value = false

      setList([], undefined)
    }

    return {
      initFlag,
      loading,
      loadingAction,

      getList,
      getPagination,

      fetch,
      fetchTransactions,

      store,
      update,
      reorder,
      destroy,
      apply,
      enable,
      disable,

      cancel,
      clear,
    }
  },
)
