<template>
  <div v-tooltip="'Analytics end date'" class="timeline-end">
    <UIDatePickerField
      :model-value="modelValue"
      v-bind="props"
      @update:late="handleUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import { DataFieldSizes, RepositoryTimeline } from '@types'

import { UIDatePickerField } from '@ui'

type Props = {
  disabled?: boolean
  size?: DataFieldSizes
}

type Emits = {
  update: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<RepositoryTimeline['end']>()

const handleUpdate = (value: string | null | undefined) => {
  if (value === undefined) return
  modelValue.value = value as string
  emit('update')
}
</script>

<script lang="ts">
export default {
  name: 'TimelineEnd',
}
</script>

<style scoped lang="postcss">
.timeline-end {
  @apply flex-auto;
}
</style>
