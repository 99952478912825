<template>
  <div ref="gridRef" class="ui-grid" :class="mainClasses">
    <slot name="top" />
    <div
      ref="containerRef"
      class="ui-grid__container"
      :class="containerClasses"
      :style="containerStyles"
    >
      <template v-if="isHeaderVisible">
        <Header v-if="selectable" is-first>
          <slot name="headerCheckbox">
            <Checkbox
              v-model="checked"
              v-bind="{ indeterminate }"
              @change="handleSelectAll"
            />
          </slot>
        </Header>
        <Header
          v-for="(column, index) in outputColumns"
          :key="column.name"
          v-model:sort="sort"
          v-bind="{ column, isHeaderChecked }"
          :is-first="!selectable && index === 0"
          :is-last="index === outputColumns.length - 1"
          @resize="hadnleColumnResize"
          @reset="handleColumnReset"
        >
          <template #default="slotProps">
            <slot
              v-if="$slots[getSlotName(column.name, 'header')]"
              :name="getSlotName(column.name, 'header')"
              v-bind="slotProps"
            />
            <slot v-else name="header" v-bind="slotProps" />
          </template>
        </Header>
      </template>
      <template v-if="areThereResult">
        <Row
          v-for="(item, index) in outputItems"
          :ref="el => setRowRef(el, item, index)"
          :key="getKeyValue(item, index)"
          v-model:selected="selected"
          v-bind="{ item, template, isCollapsed }"
          :columns="outputColumns"
          :active="getKeyValue(item, index) === activeId"
          :edit-row="
            editableRows ? getKeyValue(item, index) === editRowId : undefined
          "
          :edit="index + 1 === edit.row ? edit : undefined"
          :item-key="getKeyValue(item, index)"
          @click:row="handleClickRow(item, index)"
          @dblclick:cell="handleDblClickCell"
          @click:menu="handleClickMenu"
        >
          <template #checkbox="slotProps">
            <slot name="checkbox" v-bind="slotProps" />
          </template>
          <template #actions="slotProps">
            <slot name="actions" v-bind="slotProps" />
          </template>
          <template v-for="(_, slot) in $slots" #[slot]="slotProps">
            <slot :name="slot" v-bind="slotProps" />
          </template>
        </Row>
      </template>
      <template v-if="isFooterVisible">
        <Footer v-if="selectable" is-first />
        <Footer
          v-for="(column, index) in outputColumns"
          :key="column.name"
          v-bind="{ column, footer }"
          :is-first="!selectable && index === 0"
          :is-last="index === outputColumns.length - 1"
        >
          <template #default="slotProps">
            <slot
              v-if="$slots[getSlotName(column.name, 'footer')]"
              :name="getSlotName(column.name, 'footer')"
              v-bind="slotProps"
            />
            <slot v-else name="footer" v-bind="slotProps" />
          </template>
        </Footer>
      </template>
      <div v-if="isLazyIndicatorVisible" ref="lazyRef" class="ui-grid__lazy" />
    </div>
    <EmptyResult v-if="!areThereResult" />
    <slot name="bottom" />
  </div>
</template>

<script setup lang="ts" generic="T extends GridItem">
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  provide,
  reactive,
  ref,
  useTemplateRef,
  watch,
} from 'vue'
import {
  onClickOutside,
  useActiveElement,
  useElementVisibility,
  useMagicKeys,
  useResizeObserver,
} from '@vueuse/core'
import { isEqual, min } from 'lodash'

import { Sort } from '@types'
import {
  GridColumn,
  GridColumnWidth,
  GridEdit,
  GridFooter,
  GridItem,
  GridValueGetter,
  GridValuePaster,
} from './utils/types'

import {
  getSlotName,
  getTemplateColumns,
  pasteFromClipboard,
} from './utils/helpers'

import useSelectionService from '@/components/hooks/selection'

import { useResponsive } from '@/plugins/responsiveUI'

import Checkbox from './components/Checkbox.vue'
import EmptyResult from './components/EmptyResult.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import Row from './components/Row.vue'

type Props = {
  columns: GridColumn[]
  items: T[]

  footer?: GridFooter

  disabled?: boolean

  idField?: keyof T
  activeId?: string | number
  editRowId?: string | number

  sm?: string

  wrapped?: boolean

  scrollable?: boolean
  selectable?: boolean
  editable?: boolean
  editableRows?: boolean

  readonly?: boolean

  unitarySort?: boolean

  displayMenu?: boolean

  collapseWidth?: number

  lazy?: number

  valueGetter?: GridValueGetter
  valuePaster?: GridValuePaster
}

type Emits = {
  'click:menu': [data: T]
  'click:row': [data: T, index: number]
  pasted: []
}

const {
  idField = 'id' as keyof T,
  collapseWidth = 480,
  ...props
} = defineProps<Props>()
const emit = defineEmits<Emits>()

let selectionService: ReturnType<typeof useSelectionService>

const exposeObj = {
  getContainer() {
    return containerRef.value
  },
  clickTab() {
    selectionService?.selectNext()
    return exposeObj
  },
  clickEnter() {
    selectionService?.selectBellow()
    return exposeObj
  },
  deselect() {
    checked.value = false
    selected.value = []
    return exposeObj
  },
  release() {
    release()
    return exposeObj
  },
  // shift the lazy start if needed
  lazyShift(count: number) {
    if (count < itemsPerPage.value) return
    itemsPerPage.value += count
    return exposeObj
  },
}

defineExpose(exposeObj)

const { isTablet, isDesktop } = useResponsive()

const sort = defineModel<Sort[]>('sort')
const selected = defineModel<(string | number)[]>('selected')
const externalColumnsWidth = defineModel<GridColumnWidth>('width')

const gridRef = useTemplateRef('gridRef')
const gridWidth = ref(0)

useResizeObserver(gridRef, entries => {
  const { width } = entries[0].contentRect
  gridWidth.value = width
})

const isCollapsed = computed(() => gridWidth.value < collapseWidth)

provide('disabled', props.disabled)
provide('unitarySort', props.unitarySort)
provide('hasRowMenu', props.displayMenu)
provide('selectable', props.selectable)
provide('editable', props.editable)
provide('valueGetter', props.valueGetter)
provide('valuePaster', props.valuePaster)
provide('isReadonly', props.readonly)

const containerRef = useTemplateRef('containerRef')
const rowRef = ref<Record<string, any>>({})

const lazyRef = useTemplateRef('lazyRef')
const isScrolledDown = useElementVisibility(lazyRef)

const internalColumnsWidth = ref<GridColumnWidth>({})

const itemsPerPage = ref(0)

const checked = ref(false)

const edit = reactive<GridEdit>({
  cell: undefined,
  row: undefined,
})

const disableSelection = computed(
  () => edit.cell !== undefined && edit.row !== undefined,
)

const silentColumnsCount = props.columns.filter(column => column.silent).length

if (!props.disabled) {
  const selectionShift = Number(props.selectable) + silentColumnsCount
  selectionService = useSelectionService(
    containerRef,
    selectionShift,
    disableSelection,
    release,
  )
}

const areThereResult = computed(() => !!outputItems.value.length)

const columnsWidth = computed({
  get() {
    return externalColumnsWidth.value || internalColumnsWidth.value
  },
  set(value) {
    internalColumnsWidth.value = value
    externalColumnsWidth.value = value
  },
})

const outputColumns = computed(() =>
  props.columns.filter(column => !column.hidden),
)

const outputItems = computed<T[]>(oldValue => {
  let result
  if (itemsPerPage.value) {
    result = props.items.slice(0, itemsPerPage.value)
  } else {
    result = props.items
  }
  if (isEqual(oldValue, result)) {
    return oldValue || []
  } else {
    return result
  }
})

const indeterminate = computed(
  () =>
    props.selectable &&
    selected.value &&
    selected.value.length !== outputItems.value.length &&
    selected.value.length !== 0,
)

const isHeaderChecked = computed(() => checked.value || indeterminate.value)

const currentTemplate = computed(() => {
  let width: keyof GridColumn | undefined = undefined
  if (isTablet.value) {
    width = 'md'
  } else if (isDesktop.value) {
    width = 'lg'
  }
  return outputColumns.value
    .map(column => `${(width && column[width]) || column.default || '1fr'} `)
    .join('')
})
const template = computed(() =>
  isCollapsed.value ? currentTemplate.value : undefined,
)

const isHeaderVisible = computed(() => !isCollapsed.value)
const isFooterVisible = computed(
  () => props.footer && !isCollapsed.value && areThereResult.value,
)

const isLazyIndicatorVisible = computed(
  () => props.lazy && itemsPerPage.value < props.items.length,
)

const containerStyles = computed(() =>
  isCollapsed.value
    ? undefined
    : getTemplateColumns(
        outputColumns.value,
        columnsWidth.value,
        currentTemplate.value,
        props.selectable,
      ),
)

const mainClasses = computed(() => ({
  'ui-grid--wrapped': props.wrapped,
  'ui-grid--collapsed': isCollapsed.value,
}))

const containerClasses = computed(() => ({
  'ui-grid__container--scrollable': props.scrollable && areThereResult.value,
  'ui-grid__container--no-result': !areThereResult.value,
}))

const handleSelectAll = (flag: boolean) => {
  if (indeterminate.value || !flag) {
    selected.value = []
    checked.value = false
  } else {
    selected.value = Object.values(rowRef.value)?.reduce((acc, item) => {
      const itemKey = item?.getCheckableItemKey()
      itemKey && acc.push(itemKey)
      return acc
    }, [])
    checked.value = true
  }
}

const handleClickMenu = (data: T) => {
  emit('click:menu', data)
}

const handleClickRow = (data: T, index: number) => {
  emit('click:row', data, index)
}

const hadnleColumnResize = (column: string, width: number) => {
  columnsWidth.value[column] = width
}

const handleColumnReset = (column: string) => {
  columnsWidth.value[column] = 0
}

const calculateSelectIndexes = (editCell: number) => {
  const columnsLength = outputColumns.value.length
  const diff = editCell % (columnsLength - silentColumnsCount)
  const cell = silentColumnsCount ? diff + silentColumnsCount : diff
  const row = (editCell - diff) / (columnsLength - silentColumnsCount)
  return { cell, row }
}

const handleDblClickCell = () => {
  if (props.readonly || !selectionService.hasSelected.value) return
  const range = selectionService.range
  const editCell = min(Object.values(range)) || 0
  if (!editCell) return
  const { cell, row } = calculateSelectIndexes(editCell)
  if (!props.editable || outputColumns.value[cell].uneditable) return
  edit.cell = cell
  edit.row = row
}

const getKeyValue = (item: T, index: number) =>
  (idField && `${item?.[idField as keyof T]}`) || index

const setRowRef = (el: any, item: T, index: number) => {
  rowRef.value[getKeyValue(item, index)] = el
}

const getRowRef = (rowIndex: number) => {
  const index = rowIndex - 1
  const item = outputItems.value[index]
  const key = getKeyValue(item, index) as string
  return rowRef.value?.[key]
}

const handlePaste = (event: ClipboardEvent) => {
  if ((edit.cell && edit.row) || !selectionService.hasSelected.value) return
  const pasteData = pasteFromClipboard(event)
  const { rangeStart, rangeEnd, cols, step } = selectionService.getRangeCells()
  let shift = rangeStart + cols
  let startCell, startRow
  for (let i = rangeStart; i <= rangeEnd; i++) {
    if (i == shift) {
      i += step
      shift += step + cols
    }
    const { cell, row } = calculateSelectIndexes(i)
    const ref = getRowRef(row)
    if (!cell || !row || !ref) return
    if (startCell === undefined || startRow === undefined) {
      startCell = cell
      startRow = row
    }
    const pasteCellIndex = cell - startCell
    const pasteRowIndex = row - startRow
    const pasteRow = pasteData[pasteRowIndex] || pasteData.at(-1)
    const pasteCell = pasteRow[pasteCellIndex] || pasteRow.at(-1)
    ref.paste(cell, pasteCell)
  }
  emit('pasted')
}

const startInput = (data: string) => {
  if (!selectionService.hasSelected.value) return
  const range = selectionService.range
  if (!range.start || range.start !== range.end) return
  const { cell, row } = calculateSelectIndexes(range.start)
  const ref = getRowRef(row)
  if (!props.editable || !cell || !row || !ref) return
  ref.paste(cell, data, false)
  edit.cell = cell
  edit.row = row
}

if (props.editable) {
  const activeElement = useActiveElement()
  const isUsingInput = computed(
    () =>
      activeElement.value?.tagName === 'INPUT' ||
      activeElement.value?.tagName === 'TEXTAREA',
  )

  useMagicKeys({
    passive: false,
    onEventFired(e) {
      if (edit.cell && edit.row) return
      if (isUsingInput.value || e.type !== 'keydown') return
      if (e.key === 'Enter') {
        handleDblClickCell()
      }
      if (/^[a-z0-9]$/i.test(e.key) && !e.metaKey) {
        startInput(e.key)
      }
    },
  })
}

watch(isScrolledDown, (value, prev) => {
  if (!props.lazy || prev || !value) return
  itemsPerPage.value += props.lazy
})

watch(indeterminate, (value, prev) => {
  if (value || !prev) return
  checked.value = !!selected.value?.length
})

watch(
  () => props.editRowId,
  value => {
    if (!value) return
    selectionService?.reset()
  },
)

function release() {
  edit.cell = undefined
  edit.row = undefined
}

onClickOutside(containerRef, event => {
  const path =
    (event as any).path || (event.composedPath && event.composedPath())
  if (
    path.find((item: HTMLElement) =>
      item.classList?.contains('v-popper__popper'),
    )
  )
    return
  release()
})

onBeforeMount(() => {
  itemsPerPage.value = props.lazy || 0
  if (!props.editable) return
  document.addEventListener('paste', handlePaste)
})

onBeforeUnmount(() => {
  if (!props.editable) return
  document.removeEventListener('paste', handlePaste)
})
</script>

<script lang="ts">
export default {
  name: 'UIGrid',
}
</script>

<style scoped lang="postcss">
.ui-grid {
  @apply flex flex-col flex-auto;
  @apply relative;
  @apply select-none;
  @apply overflow-hidden;

  &--wrapped {
    @apply -mx-4 sm:-mx-6 lg:-mx-8;
  }

  &__container--no-result {
    @apply flex-none;
    @apply overflow-hidden;
  }

  &__lazy {
    @apply h-[1px];
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__container {
    @apply grid content-start;
    @apply overflow-auto;
  }
}

.ui-grid--collapsed {
  .ui-grid__container {
    @apply flex-auto;
    @apply px-3 py-2;
    @apply bg-gray-100 dark:bg-gray-800;
    @apply sm:overflow-auto;
  }
}
</style>
