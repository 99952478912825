<template>
  <UIDatePickerField
    ref="fieldRef"
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      ...$attrs,
      disabled,
      error,
      label,
      lazyFocus: false,
      silentError,
      size,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef } from 'vue'

import { TransactionClass } from '..'
import { DataFieldSizes } from '@types'

import { TRANSACTION_FIELD } from '../utils/const'

import { UIDatePickerField } from '@ui'

type Props = {
  dataRefid?: string
  disabled?: boolean
  instance: TransactionClass
  label?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = useTemplateRef('fieldRef')

const modelValue = props.instance.field<string>(TRANSACTION_FIELD.DATE)

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const error = computed(() => props.instance.errors.date)
const silentError = computed(() => !isSavedTransaction.value)
</script>

<script lang="ts">
export default {
  name: 'TransactionFormDate',
}
</script>
