<template>
  <LinkedDataAccountsPositions
    v-if="loading || items?.length"
    v-model:search="search"
    v-model:sort="sort"
    v-bind="{ items, loading }"
  />
  <UILayoutNoData v-else inline hide-question-icon />
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'

import { LinkedDataConnectorItemPosition, Sort } from '@types'

import { useLinkedDataStore } from '@/store/linkedData'

import LinkedDataAccountsPositions from '@/views/LinkedData/Accounts/components/Positions.vue'
import { UILayoutNoData } from '@ui'

type Props = {
  linkedAccountId?: string | null
}

const props = defineProps<Props>()

const linkedDataStore = useLinkedDataStore()

const loading = ref(false)

const search = ref<string>()
const sort = ref<Sort[]>()

const items = ref<LinkedDataConnectorItemPosition[]>([])

onBeforeMount(async () => {
  if (!props.linkedAccountId) return
  loading.value = true
  const result = await linkedDataStore.fetchEvents(props.linkedAccountId)
  items.value = result?.events.find(
    item => item.event_type === 'account_positions_sync',
  )?.data
  loading.value = false
})
</script>

<script lang="ts">
export default {
  name: 'AccountConnectorPositions',
}
</script>

<style scoped lang="postcss"></style>
