<template>
  <ModalFactoryItem
    v-for="(instance, key) in instances.values()"
    :key="key"
    :instance="instance"
  />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useActiveElement, useMagicKeys } from '@vueuse/core'
import { isEqual } from 'lodash'

import { ModalFactoryInstances } from './utils/types'
import { Entity } from '@/entities/utils/common'
import { EntityEventCallback } from '@/entities/utils/types'
import { ModalEvent, ModalState } from '@types'

import { ROUTE_NAME } from '@/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useContactsBunchStore } from '@/store/contacts/bunch'
import { useDocumentsBunchStore } from '@/store/documents/bunch'
import { useModalsStore } from '@/store/modals'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import ModalFactoryItem from './ModalFactoryItem.vue'

type Props = {
  instances: ModalFactoryInstances
}

defineProps<Props>()

const route = useRoute()
const router = useRouter()

const assetsBunchStore = useAssetsBunchStore()
const contactsBunchStore = useContactsBunchStore()
const documentsBunchStore = useDocumentsBunchStore()
const modalsStore = useModalsStore()
const tagsBunchStore = useTagsBunchStore()
const transactionsBunchStore = useTransactionsBunchStore()

const activeElement = useActiveElement()

const notInputIsUsed = computed(
  () =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA',
)

const { escape } = useMagicKeys()

const routeName = computed(() => route.name)

const assetsInstances = computed(() => assetsBunchStore.getList)
const contactsInstances = computed(() => contactsBunchStore.getList)
const documentsInstances = computed(() => documentsBunchStore.getList)
const tagsInstances = computed(() => tagsBunchStore.getList)
const transactionsInstances = computed(() => transactionsBunchStore.getList)

const isAssetsInit = computed(() => !!assetsInstances.value.size)
const isContactsInit = computed(() => !!contactsInstances.value.size)
const isDocumentsInit = computed(() => !!documentsInstances.value.size)
const isTagsInit = computed(() => !!tagsInstances.value.size)
const isTransactionsInit = computed(() => !!transactionsInstances.value.size)

watch(escape, value => {
  if (!notInputIsUsed.value || !value) return
  modalsStore.hideLatest()
})

watch(
  () => ({
    id: route.params.id?.toString(),
    assets: isAssetsInit.value,
    contacts: isContactsInit.value,
    documents: isDocumentsInit.value,
    tags: isTagsInit.value,
    transactions: isTransactionsInit.value,
  }),
  (data, prev) => {
    if (isEqual(data, prev)) return
    let entityInstance: any
    let onCloseCallback: EntityEventCallback | undefined = undefined
    switch (routeName.value) {
      case ROUTE_NAME.TRANSACTIONS_ITEM:
        if (!data.transactions) return
        entityInstance = transactionsInstances.value?.get(data.id)
        onCloseCallback = () => {
          if (!route.name?.toString().startsWith(ROUTE_NAME.TRANSACTIONS))
            return
          router.push({ name: ROUTE_NAME.TRANSACTIONS })
        }
        break
      case ROUTE_NAME.ASSETS_ITEM:
      case ROUTE_NAME.ASSETS_ITEM_SECURITY_MASTER:
      case ROUTE_NAME.ASSETS_ITEM_PRICES:
      case ROUTE_NAME.ASSETS_ITEM_PRICES_CHART:
      case ROUTE_NAME.ASSETS_ITEM_PRICES_TABLE:
      case ROUTE_NAME.ASSETS_ITEM_TAGS:
      case ROUTE_NAME.ASSETS_ITEM_DOCUMENTS:
      case ROUTE_NAME.ASSETS_ITEM_CONTACTS:
      case ROUTE_NAME.CURRENCIES_ITEM:
      case ROUTE_NAME.CURRENCIES_ITEM_SECURITY_MASTER:
      case ROUTE_NAME.CURRENCIES_ITEM_PRICES:
      case ROUTE_NAME.CURRENCIES_ITEM_PRICES_CHART:
      case ROUTE_NAME.CURRENCIES_ITEM_TAGS:
      case ROUTE_NAME.INCOME_ACCOUNTS_ITEM:
      case ROUTE_NAME.EXPENSE_ACCOUNTS_ITEM:
        if (!data.assets) return
        entityInstance = assetsInstances.value?.get(data.id)
        onCloseCallback = () => {
          if (route.name?.toString().startsWith(ROUTE_NAME.ASSETS)) {
            router.push({ name: ROUTE_NAME.ASSETS })
          }
          if (route.name?.toString().startsWith(ROUTE_NAME.CURRENCIES)) {
            router.push({ name: ROUTE_NAME.CURRENCIES })
          }
          if (route.name?.toString().startsWith(ROUTE_NAME.INCOME_ACCOUNTS)) {
            router.push({ name: ROUTE_NAME.INCOME_ACCOUNTS })
          }
          if (route.name?.toString().startsWith(ROUTE_NAME.EXPENSE_ACCOUNTS)) {
            router.push({ name: ROUTE_NAME.EXPENSE_ACCOUNTS })
          }
        }
        break
      case ROUTE_NAME.CONTACTS_ITEM:
        if (!data.contacts) return
        entityInstance = contactsInstances.value?.get(data.id)
        onCloseCallback = () => {
          if (!route.name?.toString().startsWith(ROUTE_NAME.CONTACTS)) return
          router.push({ name: ROUTE_NAME.CONTACTS })
        }
        break
      case ROUTE_NAME.DOCUMENTS_ITEM:
        if (!data.documents) return
        entityInstance = documentsInstances.value?.get(data.id)
        onCloseCallback = () => {
          if (!route.name?.toString().startsWith(ROUTE_NAME.DOCUMENTS)) return
          router.push({ name: ROUTE_NAME.DOCUMENTS })
        }
        break
      case ROUTE_NAME.TAGS_ITEM:
        if (!data.tags) return
        entityInstance = tagsInstances.value?.get(data.id)
        onCloseCallback = () => {
          if (!route.name?.toString().startsWith(ROUTE_NAME.TAGS)) return
          router.push({ name: ROUTE_NAME.TAGS })
        }
        break
    }
    if (!(entityInstance instanceof Entity)) return

    const modalInstance = modalsStore.init(entityInstance.id, entityInstance)
    if (modalInstance?.state !== ModalState.CLOSED) return

    modalInstance?.open(modalsStore.getZIndex())

    // change route after form hide
    if (onCloseCallback) {
      modalInstance?.addEventListener(ModalEvent.CLOSE, onCloseCallback)
    }
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'AppModalFactory',
}
</script>
