<template>
  <UIInputEditableDropdown
    ref="inputRef"
    v-model="modelValue"
    :data-refid="dataRefid"
    v-bind="{
      data,
      disabled,
      error,
      frozen: true,
      idKey: 'key',
      label,
      silentError,
      size,
      readonly,
    }"
    @create="handleCreate"
  />
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref, useTemplateRef } from 'vue'

import { useTagsBunchStore } from '@/store/tags/bunch'

import { TagClass } from '../'
import { DataFieldSizes } from '@types'

import { orderedList } from '@/helpers/common'
import { TAG_FIELD } from '../utils/const'

import { UIInputEditableDropdown } from '@ui'

type Props = {
  instance: TagClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const modelValue = props.instance.field<string>(TAG_FIELD.TAG_NAME)

const tagsBunchStore = useTagsBunchStore()

const inputRef = useTemplateRef('inputRef')
const isSavedTag = inject('isSavedTag', ref(false))

const readonly = computed(() => !props.instance.isCommon)
const error = computed(() => props.instance.errors.name)
const silentError = computed(() => !isSavedTag.value)

const tagsList = computed(() => tagsBunchStore.getCommonList)

const data = computed(() => {
  const list = Array.from(tagsList.value)
  const mappedList = list.map(
    ([, instance]) => instance.field<string>(TAG_FIELD.TAG_NAME).value,
  )
  const names = [...new Set(mappedList)].filter(name => !!name)
  return orderedList(names)
})

const handleCreate = (name: string) => {
  modelValue.value = name
}

onMounted(() => {
  if (props.focusOnLoad) {
    inputRef.value?.focus()
  }
})
</script>

<script lang="ts">
export default {
  name: 'TagFormName',
}
</script>
