<template>
  <UIInputDropdown v-model="actionSign" v-bind="{ size, data }" id-key="key" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'

import { TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { UIInputDropdown } from '@ui'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const data = [
  { key: 'positive', value: 'Positive' },
  { key: 'negative', value: 'Negative' },
  { key: 'flip sign', value: 'Flip Sign' },
]

const actionSign = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})

onBeforeMount(() => {
  if (actionSign.value !== undefined) return
  actionSign.value = ''
})

defineOptions({
  name: 'LinkedDataRulesActionSign',
})
</script>
