<template>
  <UITagsList v-model="selected" :opened="show" @plus:click="show = !show" />
  <TagsListDropdown
    v-if="show"
    v-bind="{ selected }"
    v-model="selectedTag"
    v-model:search="searchTag"
    :disabled="loading"
    @select="handleSelectTag"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { UITagsList } from '@ui'
import TagsListDropdown from '../../Transactions/components/TagsListDropdown.vue'

import { CommonTag, TransactionRule } from '@types'

import { useLinkedDataTransactionsTagsStore } from '@/store/linkedData/transactionsTags'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const selectedTag = ref<string>()
const searchTag = ref<string>()

const linkedDataTransactionsTagsStore = useLinkedDataTransactionsTagsStore()

const tagsList = computed(() => linkedDataTransactionsTagsStore.getList)

const show = ref(false)
const loading = computed(() => linkedDataTransactionsTagsStore.loading)

const selected = computed<CommonTag[]>({
  get() {
    return tagsList.value.filter(tag =>
      modelValue.value.tag_ids?.includes(`${tag.id}`),
    )
  },
  set(values) {
    modelValue.value = {
      ...modelValue.value,
      tag_ids: values.map(tag => `${tag.id}`),
    }
  },
})

const handleSelectTag = (value: CommonTag) => {
  selected.value = [...selected.value, value]
  selectedTag.value = undefined
  searchTag.value = undefined
}

onBeforeMount(() => {
  if (selected.value.length) return
  selected.value = []
})

defineOptions({
  name: 'LinkedDataRulesActionTag',
})
</script>

<style lang="postcss">
.mapping-rules-action-tag {
  &__item {
    @apply flex items-center;
    @apply gap-2;

    .v-popper {
      @apply flex-auto;
    }
  }

  &__add {
    @apply flex justify-end;
  }
}
</style>
