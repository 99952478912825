<template>
  <UILoading v-if="isLoading" message="Loading values..." inset />
  <UIComboChart
    v-else-if="series?.length"
    v-bind="{ data, series, valueFormatter }"
    class="asset-summary__values"
  />
  <UILayoutNoData v-else hide-question-icon inline />
</template>

<script setup lang="ts">
import {
  ComputedRef,
  computed,
  inject,
  onWatcherCleanup,
  ref,
  watch,
} from 'vue'
import { orderBy } from 'lodash'

import { AnalyticsPosition } from '@types'

import { stringToLocalDateString } from '@/helpers/dates'
import { numberFormat } from '@/helpers/numbers'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsStore } from '@/store/assets'

import { UIComboChart, UILayoutNoData, UILoading } from '@ui'
import { useDark } from '@vueuse/core'

type Props = {
  assetId?: string
  accountId?: string
}

const props = defineProps<Props>()

const analyticsStore = useAnalyticsStore()
const assetsStore = useAssetsStore()

const isDark = useDark()

const currency = inject<ComputedRef<string>>('currency')

const valueFormatter = computed(
  () => (data: number, fractionDigits?: number) =>
    numberFormat(data, { currency: currency?.value, fractionDigits }),
)

const isLoading = ref(true)
const positions = ref<AnalyticsPosition[]>([])

const data = computed(() => {
  const mappedData: Record<string, [string, number, number]> = {}
  for (let i = 0; i < positions.value.length; i++) {
    const key = positions.value[i].date
    const date = stringToLocalDateString(positions.value[i].date) as string
    const value = positions.value[i].position_close
    const cost = value - positions.value[i].position_gain
    if (!mappedData[key]) {
      mappedData[key] = [date, value, cost]
      continue
    }
    mappedData[key][1] += value
    mappedData[key][2] += cost
  }
  return orderBy(Object.values(mappedData), item => item[0], 'asc')
})

const series = computed(() => {
  return [
    {
      name: 'Value',
      type: 'bar',
      itemStyle: {
        color: isDark.value ? '#416DE6' : '#1C4ED8',
      },
    },
    {
      name: 'Cost',
      type: 'bar',
      itemStyle: {
        color: isDark.value ? '#F24242' : '#EF4444',
      },
    },
  ]
})

const fetchTrigger = computed(() => assetsStore.loadingPricesAction)

const fetchPositions = async () => {
  const params: Record<string, string> = { data: 'position' }
  if (props.assetId) {
    params.assets = JSON.stringify([props.assetId])
  }
  if (props.accountId) {
    params.accounts = JSON.stringify([props.accountId])
  }
  const result = await analyticsStore.fetch(params)
  positions.value = result.position_view
  isLoading.value = false
}

watch(
  fetchTrigger,
  async value => {
    if (value) return
    fetchPositions()
    onWatcherCleanup(() => {
      analyticsStore.cancel()
    })
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'AssetSummaryValues',
  inheritAttrs: false,
}
</script>

<style lang="postcss">
.asset-summary {
  &__values {
    @apply w-full max-w-full h-[98%];
  }
}
</style>
