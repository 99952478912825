import { isNumber, remove, set } from 'lodash'

import { Asset, ListItem, ModalEvent, TransactionSettings } from '@types'
import { AssetCategoryAccount } from '@/entities/assets/utils/enums'
import { EntityEvent } from '@/entities/utils/enums'
import { AssetClass } from '@/entities/assets'
import { TransactionClass } from '../..'

import { NEGATIVE_RULES, POSITIVE_RULES } from '@/helpers/validate'
import { ASSET_FIELD, ASSET_CURRENCY_TYPE } from '@/entities/assets/utils/const'
import { DELIMETER_KEYWORD } from '@/components/UI/Input/Dropdown/utils/const'
import { TRANSFERS } from './const'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useModalsStore } from '@/store/modals'

export const getAssetListValues = (
  settings: TransactionSettings['entries'][number]['asset'],
  assetsList: Asset[],
  opposingValue?: string,
) => {
  if (settings?.value) {
    const item = assetsList.find(item => item.name === settings?.value)
    return item ? [{ key: item.id, value: item.name }] : []
  }
  let result = assetsList
  if (settings?.includes) {
    result = result.filter(item => settings.includes?.includes(item.type))
  }
  if (opposingValue) {
    const index = result.findIndex(item => item.id === opposingValue)
    if (index >= 0) {
      result.splice(index, 1)
    }
  }
  if (result.find(item => TRANSFERS.includes(item.name.toLowerCase()))) {
    return result.reduce((acc: ListItem[], item: Asset) => {
      const value = { key: item.id, value: item.name }
      if (TRANSFERS.includes(item.name.toLowerCase())) {
        acc.unshift(value, { key: DELIMETER_KEYWORD, value: DELIMETER_KEYWORD })
        return acc
      }
      acc.push(value)
      return acc
    }, [])
  } else {
    return result.map(item => ({ key: item.id, value: item.name }))
  }
}

export const preSetAsset = (
  assetId: string | null | undefined,
  settings: TransactionSettings['entries'][number]['asset'] | undefined,
  assetsBunchStore: ReturnType<typeof useAssetsBunchStore>,
  currency: string | undefined,
  defaultValue: string | undefined = undefined,
) => {
  if (!settings) return
  if (assetId) {
    const asset = assetsBunchStore.getElementById(assetId)
    const type = asset?.field<string>(ASSET_FIELD.TYPE).value
    if (type && settings?.includes?.includes(type)) {
      return assetId
    }
  }
  if (settings?.includes?.[0] === ASSET_CURRENCY_TYPE) {
    return currency
  } else {
    const asset =
      assetsBunchStore.getElementByName(settings?.default || '') ||
      assetsBunchStore.getElementByName(settings?.value || '')
    return asset?.id || defaultValue
  }
}

export const preSetAmount = (
  amount: number | null,
  settings: TransactionSettings['entries'][number]['amount'] | undefined,
) => {
  if (!settings) return null
  if (settings.rule === 'percent') {
    return 1
  }
  if (isNumber(amount)) {
    if (NEGATIVE_RULES.includes(settings.rule)) {
      return Math.abs(amount) * -1
    }
    if (POSITIVE_RULES.includes(settings.rule)) {
      return Math.abs(amount)
    }
    return amount
  }
  return settings.default || null
}

export const onCreateAsset = (
  value: string,
  restrictions: string[] | undefined,
  instance: AssetClass,
  modalsStore: ReturnType<typeof useModalsStore>,
  callback: (data: string) => void,
) => {
  let type = ''
  if (
    restrictions?.length === 2 &&
    restrictions?.includes(ASSET_CURRENCY_TYPE)
  ) {
    type = ASSET_CURRENCY_TYPE
  } else if (restrictions?.includes(AssetCategoryAccount.INCOME)) {
    type = AssetCategoryAccount.INCOME
  } else if (restrictions?.includes(AssetCategoryAccount.EXPENSE)) {
    type = AssetCategoryAccount.EXPENSE
  } else {
    type = restrictions?.length === 1 ? restrictions[0] : ''
  }
  instance.set({
    type,
    name: value,
    ticker: value,
  })
  instance.isDialog = true
  instance.addEventListener(EntityEvent.STORED, data => {
    instance.isDialog = false
    callback(data.id)
  })
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex(), { restrictions })
  modalInstance?.addEventListener(ModalEvent.CLOSE, () => {
    instance.remove()
  })
}

export const compareEntries = (
  instance: TransactionClass,
  assetsBunchStore: ReturnType<typeof useAssetsBunchStore>,
  currency: string | undefined,
) => {
  const settings = instance.settings?.entries
  if (!settings) return
  remove(instance.data.entries, (_, index) => index >= settings.length)

  for (let i = 0; i < settings.length; i++) {
    const account_id = preSetAsset(
      instance.data.entries[i]?.account_id,
      settings[i].account,
      assetsBunchStore,
      currency,
    )
    const amount = preSetAmount(
      instance.data.entries[i]?.amount,
      settings[i].amount,
    )
    const asset_id = preSetAsset(
      instance.data.entries[i]?.asset_id,
      settings[i].asset,
      assetsBunchStore,
      currency,
    )
    set(instance.data, ['entries', i], { account_id, amount, asset_id })
  }
}
