import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { Capacitor } from '@capacitor/core'

import { DashboardsItem } from './utils/types'

import {
  DEFAULT_APP_DASHBOARDS,
  DEFAULT_DESKTOP_DASHBOARDS,
} from './utils/const'

import { useUISettings } from '@/hooks/uiSettings'
import { useRepositoriesStore } from '@/store/repositories'
import { useUserSubscriptionsStore } from '@/store/user/subscriptions'

export const useDashboardsSettingsStore = defineStore(
  'dashboards-settings',
  () => {
    // INIT
    const isAppMode = Capacitor.isNativePlatform()

    const userSubscriptionsStore = useUserSubscriptionsStore()
    const repositoriesStore = useRepositoriesStore()

    const isEditMode = ref(false)

    const desktopDashboards = useUISettings<DashboardsItem[] | undefined>(
      ['dashboards'],
      undefined,
    )

    const appDashboards = useUISettings<DashboardsItem[] | undefined>(
      ['dashboards-app'],
      undefined,
    )

    // GETTERS

    const getDashboards = computed(() => {
      if (!repositoriesStore.currentRepositoryId) return
      const plan = userSubscriptionsStore.getCurrentPlanName
      if (isAppMode) {
        return appDashboards.value || DEFAULT_APP_DASHBOARDS[plan]
      } else {
        return desktopDashboards.value || DEFAULT_DESKTOP_DASHBOARDS[plan]
      }
    })

    // SETTERS

    const setDashboards = (value: DashboardsItem[]) => {
      if (isAppMode) {
        appDashboards.value = value
      } else {
        desktopDashboards.value = value
      }
    }

    // ACTIONS

    const clear = () => {
      isEditMode.value = false
    }

    return {
      isEditMode,

      getDashboards,
      setDashboards,

      clear,
    }
  },
)
