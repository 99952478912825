import { ref, watch } from 'vue'

import {
  FastLinkCloseResponse,
  FastLinkErrorResponse,
  PlaidOnExitResponseError,
  TaskStatus,
  TaskType,
} from '@types'

import { YODLEE_ONCLOSE_STATUSES } from '../utils/const'

import { appendScript, handleCatchedError, waitForNode } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import { useLinkedDataStore } from '@/store/linkedData'
import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useTasksStore } from '@/store/tasks'

const loading = ref(false)
const syncing = ref(false)

export const useLinkedDataConnector = (
  yodleeToggleCallback?: (flag: boolean) => void,
  addAccountToggleCallback?: (flag: boolean) => void,
) => {
  const linkedDataStore = useLinkedDataStore()
  const linkedDataConnectorsStore = useLinkedDataConnectorsStore()
  const tasksStore = useTasksStore()

  const { error } = useNotifications()

  const plaidToken = ref()
  const accessInfo = ref()

  const reloadToken = async () => {
    loading.value = true
    accessInfo.value = await linkedDataStore.getAccess()
    loading.value = false
  }

  const fetchPlaidTasks = async (token: string) => {
    syncing.value = true
    try {
      await linkedDataStore.plaid(token)
    } catch (e) {
      handleCatchedError(e as string)
    }
  }

  const fetchYodleeTasks = async () => {
    syncing.value = true
    try {
      await linkedDataStore.yodlee()
    } catch (e) {
      handleCatchedError(e as string)
    }
  }

  const openPlaid = () => {
    if (!plaidToken.value) return
    ;(window as Window & typeof globalThis & { Plaid: any }).Plaid.create({
      token: plaidToken.value,
      onLoad: () => {
        addAccountToggleCallback?.(false)
      },
      onSuccess: async (public_token: string) => {
        fetchPlaidTasks(public_token)
      },
      onExit: function (err: PlaidOnExitResponseError | null) {
        if (err) {
          error({
            message: err.display_message,
          })
        }
      },
    }).open()
  }

  const openYodleeFastLink = async (institutionId?: string) => {
    if (!accessInfo.value) return
    yodleeToggleCallback?.(true)
    const container = await waitForNode(
      '.account-yodlee--visible .account-yodlee__fastlink',
    )
    const containerId = container?.getAttribute('id')
    ;(window as Window & typeof globalThis & { fastlink: any }).fastlink.open(
      {
        fastLinkURL: accessInfo.value.url,
        accessToken: `Bearer ${accessInfo.value.token}`,
        params: {
          configName: 'Aggregation',
          ...(institutionId && {
            flow: 'add',
            providerId: institutionId,
          }),
        },
        // onSuccess: function (data: FastLinkSuccessResponse) {
        //   console.log('onSuccess', data)
        // },
        onError: async (data: FastLinkErrorResponse) => {
          error(data)
        },
        onClose: (data: FastLinkCloseResponse) => {
          yodleeToggleCallback?.(false)
          if (!data.status && data.sites?.length) {
            fetchYodleeTasks()
          }
          if (data.status === YODLEE_ONCLOSE_STATUSES.USER_CLOSE_ACTION) {
            addAccountToggleCallback?.(true)
          }
        },
      },
      containerId,
    )
  }

  const plaid = async (connectorId?: string, routingNumber?: string | null) => {
    try {
      const data = await linkedDataConnectorsStore.getPlaidToken(
        connectorId,
        routingNumber,
      )
      plaidToken.value = data?.link_token
    } catch (e) {
      handleCatchedError(e as string)
      error({
        message: 'Error while getting Plaid token',
      })
      return
    }
    appendScript(
      'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
      'plaid-script',
    ).then(openPlaid)
  }

  const yodlee = async (id?: string) => {
    addAccountToggleCallback?.(false)
    await reloadToken()
    appendScript(
      'https://cdn.yodlee.com/fastlink/v4/initialize.js',
      'yodlee-fastlink',
    ).then(() => openYodleeFastLink(id))
  }

  const sync = async () => {
    syncing.value = true
    try {
      await linkedDataStore.sync()
    } catch (e) {
      handleCatchedError(e as string)
    }
  }

  const resync = async (connectorId: string) => {
    syncing.value = true
    try {
      await linkedDataStore.resync(connectorId)
    } catch (e) {
      handleCatchedError(e as string)
    }
  }

  watch(syncing, value => {
    if (!value) return
    tasksStore.pushTaskToList({
      status: TaskStatus.RUNNING,
      type: TaskType.SYNC_HOLDING_ACCOUNT,
    })
  })

  return {
    loading,
    syncing,

    plaid,
    yodlee,

    sync,
    resync,
  }
}
