<template>
  <nav aria-label="Progress" class="ui-steps">
    <ol role="list" class="ui-steps__list">
      <StepsItem
        v-for="(step, index) in steps"
        :key="step.name"
        v-bind="{ index, step }"
        :is-last-item="index === steps.length - 1"
        :is-completed-item="completed.includes(index)"
        :is-skipped-item="skipped.includes(index)"
        :is-current-item="index === currentStep"
        @click="handleClick(index)"
      />
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { StepsPosition } from './utils/types'

import StepsItem from './StepsItem.vue'

type Props = {
  steps: StepsPosition[]
  completed: number[]
  skipped: number[]
}

defineProps<Props>()

const currentStep = defineModel<number>({ default: 0 })

const handleClick = (index: number) => {
  currentStep.value = index
}
</script>

<script lang="ts"></script>

<style lang="postcss">
.ui-steps {
  &__list {
    @apply overflow-hidden;
  }
}
</style>
