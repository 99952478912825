<template>
  <UIInputEditableDropdown
    v-model="actionValue"
    v-bind="{ size, data }"
    frozen
    @create="handleCreate"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'

import { TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'

import { prepareTagsList } from './utils/helpers'
import { orderedList } from '@/helpers/common'

import { useTagsBunchStore } from '@/store/tags/bunch'

import useFiltersService from '@/services/filters'

import { UIInputEditableDropdown } from '@ui'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const tagsBunchStore = useTagsBunchStore()

const { fetchOptions } = useFiltersService()

const existingList = ref<string[]>([])

const tagsList = computed(() =>
  prepareTagsList(tagsBunchStore.getCategoriesList),
)

const data = computed(() =>
  orderedList([...new Set([...existingList.value, ...tagsList.value])]),
)

const actionValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})

const handleCreate = (data: string) => {
  existingList.value.push(data)
  actionValue.value = data
}

onBeforeMount(async () => {
  const result = await fetchOptions('LinkedDataTransactions', 'category')
  existingList.value = result.map(item => item.value)
  if (actionValue.value !== undefined) {
    existingList.value.push(actionValue.value)
    return
  }
  actionValue.value = ''
})

defineOptions({
  name: 'LinkedDataRulesActionCategory',
})
</script>
