<template>
  <div ref="mainRef" class="ui-card" :class="mainClasses">
    <div
      v-if="title || description"
      class="ui-card__header"
      :class="headerClasses"
    >
      <h2 v-if="title" class="ui-card__title">{{ title }}</h2>
      <p v-if="description" class="ui-card__description">{{ description }}</p>
    </div>
    <slot />
    <div v-if="$slots.footer" class="ui-card__footer" :class="footerClasses">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue'
import { useTimeoutFn } from '@vueuse/core'

type Props = {
  title?: string
  description?: string
  unwrapped?: boolean
}

const props = defineProps<Props>()

defineExpose({
  getEl() {
    return mainRef.value
  },
  attract() {
    stop()
    attract.value = true
    start()
  },
})

const mainRef = useTemplateRef('mainRef')
const attract = ref(false)

const { stop, start } = useTimeoutFn(() => {
  attract.value = false
}, 700)

const mainClasses = computed(() => ({
  'ui-card--attract': attract.value,
  'ui-card--unwrapped': props.unwrapped,
}))

const headerClasses = computed(() => ({
  'ui-card__header--unwrapped': props.unwrapped,
}))

const footerClasses = computed(() => ({
  'ui-card__footer--unwrapped': props.unwrapped,
}))
</script>

<script lang="ts">
export default {
  name: 'UICard',
}
</script>

<style scoped lang="postcss">
.ui-card {
  @apply p-4;
  @apply dark:border dark:border-gray-600;
  @apply shadow rounded-md;
  @apply outline outline-transparent outline-offset-2;
  @apply duration-700;
  transition-property: outline;

  &--unwrapped {
    @apply p-0;
    @apply !rounded-none !bg-none !border-none !shadow-none;
  }

  &__header {
    @apply -m-4 mb-0 px-4 py-3;
    @apply flex flex-col;
    @apply gap-1;

    &--unwrapped {
      @apply m-0;
    }
  }

  &__title {
    @apply text-lg leading-6 font-medium;
    @apply text-gray-900 dark:text-gray-100;
  }

  &__description {
    @apply text-sm;
    @apply text-gray-500 dark:text-gray-400;
  }

  &__footer {
    @apply -m-4 mt-4 px-4 py-3;
    @apply rounded-md rounded-t-none;
    @apply text-right;
    @apply bg-gray-50 dark:bg-gray-850;

    &--unwrapped {
      @apply m-0 mt-4;
    }
  }

  &--attract {
    @apply outline-4 outline-indigo-200 dark:outline-indigo-800;
  }
}
</style>
