<template>
  <div v-if="isSliderVisible" class="ui-range-slider">
    <div class="ui-range-slider__area">
      <input
        :key="`${min}-${max}`"
        v-model="modelValue"
        v-bind="{ min, max, step, style }"
        type="range"
        class="ui-range-slider__input"
        autocomplete="off"
        @focus="handleFocus"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useDark } from '@vueuse/core'

type Props = {
  min: number
  max: number
  step?: number
}

type Emits = {
  focus: []
}

const { max, min, step = 1 } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<number>({ required: true })

const isDark = useDark()

const isSliderVisible = computed(() => min !== max)

const style = computed(() => {
  const value = ((modelValue.value - min) / (max - min)) * 100
  return isDark.value
    ? `background: linear-gradient(to right, #6B7280 0%, #6B7280 ${value}%, #374151 ${value}%, #374151 100%)`
    : `background: linear-gradient(to right, #E0E7FF 0%, #E0E7FF ${value}%, #E5E7EB ${value}%, #E5E7EB 100%)`
})

const handleFocus = () => {
  emit('focus')
}
</script>

<script lang="ts">
export default {
  name: 'UIRangeSlider',
}
</script>

<style lang="postcss">
.ui-range-slider {
  @apply flex justify-center items-center;

  &__area {
    @apply h-1 max-w-xl w-full;
    @apply relative;
  }

  &__input {
    @apply w-full h-1;
    @apply absolute inset-0;
    @apply rounded-md;
    @apply ring-0 outline-none;
    @apply appearance-none;

    &::-webkit-slider-runnable-track {
      @apply appearance-none;
    }

    &::-moz-range-track {
      @apply appearance-none;
    }

    &::-webkit-slider-thumb {
      @apply w-5 h-3;
      @apply rounded-md;
      @apply bg-indigo-700 dark:bg-gray-300;
      @apply appearance-none;
      @apply cursor-ew-resize;
    }

    &:focus::-webkit-slider-thumb {
      @apply ring-1 ring-offset-2;
      @apply ring-indigo-700 dark:ring-gray-300;
    }

    &::-moz-range-thumb {
      @apply w-5 h-3;
      @apply rounded-md;
      @apply bg-indigo-700 dark:bg-gray-300;
      @apply appearance-none;
      @apply cursor-ew-resize;
      @apply z-20;
    }

    &:focus::-moz-range-thumb {
      @apply ring-1 ring-offset-2;
      @apply ring-indigo-700 dark:ring-gray-300;
    }
  }
}
</style>
