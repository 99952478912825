import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useRepoLocalStorage } from '@/hooks/repoLocalStorage'
import { useResponsive } from '@/plugins/responsiveUI'

import { useRepositoriesStore } from './repositories'

export const useSidebarStore = defineStore('sidebar', () => {
  // INIT
  const repositoriesStore = useRepositoriesStore()
  const { isMobile, isTablet } = useResponsive()

  const storageFlag = useRepoLocalStorage<boolean>('SIDEBAR_OPEN', true)

  const internalFlag = ref(false)

  // GETTERS
  const getOpen = computed(() => {
    if (!repositoriesStore.list?.length) {
      return false
    }
    return isMobile.value || isTablet.value
      ? internalFlag.value
      : storageFlag.value
  })

  // SETTERS

  const setOpen = (value: boolean) => {
    if (isMobile.value || isTablet.value) {
      internalFlag.value = value
    } else {
      storageFlag.value = value
    }
  }

  return {
    getOpen,
    setOpen,
  }
})
