<template>
  <li class="steps-item" :class="[isLastItem ? '' : 'pb-10']">
    <div
      v-if="!isLastItem"
      class="steps-item__connector"
      :class="connectorClasses"
      aria-hidden="true"
    />
    <div class="steps-item__area group">
      <span class="steps-item__point">
        <span class="steps-item__circle" :class="circleClasses">
          <component
            :is="iconComponent"
            class="steps-item__icon"
            :class="iconClasses"
            aria-hidden="true"
          />
        </span>
      </span>
      <span class="steps-item__info">
        <span class="steps-item__name" :class="nameClasses">{{
          step.name
        }}</span>
        <span class="steps-item__description">{{ step.description }}</span>
      </span>
    </div>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { StepsPosition } from './utils/types'

import { CheckIcon, MinusIcon } from '@heroicons/vue/20/solid'

type Props = {
  step: StepsPosition
  isCurrentItem: boolean
  isCompletedItem: boolean
  isSkippedItem: boolean
  isLastItem: boolean
}

const props = defineProps<Props>()

const iconComponent = computed(() =>
  props.isCompletedItem ? CheckIcon : props.isSkippedItem ? MinusIcon : 'span',
)

const connectorClasses = computed(() => ({
  'steps-item__connector--completed':
    props.isCompletedItem || props.isSkippedItem,
}))

const circleClasses = computed(() => ({
  'steps-item__circle--completed': props.isCompletedItem || props.isSkippedItem,
  'steps-item__circle--current': props.isCurrentItem,
}))

const iconClasses = computed(() => ({
  'steps-item__icon--completed': props.isCompletedItem || props.isSkippedItem,
  'steps-item__icon--current': props.isCurrentItem,
}))

const nameClasses = computed(() => ({
  'steps-item__name--completed': props.isCompletedItem || props.isSkippedItem,
  'steps-item__name--current': props.isCurrentItem,
}))
</script>

<script lang="ts"></script>

<style lang="postcss">
.steps-item {
  @apply relative;
  @apply cursor-pointer;

  &__connector {
    @apply w-0.5 h-full;
    @apply absolute left-4 top-4;
    @apply -ml-px mt-0.5;
    @apply bg-gray-300 dark:bg-gray-700;

    &--completed {
      @apply bg-indigo-600 dark:bg-indigo-400;
    }
  }

  &__area {
    @apply relative;
    @apply flex items-center;
  }

  &__point {
    @apply h-9;
    @apply flex items-center;
  }

  &__circle {
    @apply w-8 h-8;
    @apply flex items-center justify-center;
    @apply relative;
    @apply bg-white dark:bg-gray-800;
    @apply border-2 border-gray-300 dark:border-gray-700 group-hover:border-gray-400 dark:group-hover:border-gray-700;
    @apply rounded-full;
    @apply z-10;

    &--completed {
      @apply bg-indigo-600 group-hover:bg-indigo-800;
      @apply dark:bg-indigo-400 dark:group-hover:bg-indigo-600;
      @apply border-none;
    }

    &--current {
      @apply !border-indigo-600 dark:!border-indigo-400;
    }
  }

  &__icon {
    @apply w-2.5 h-2.5;
    @apply rounded-full;
    @apply bg-transparent group-hover:bg-gray-300 dark:group-hover:bg-gray-700;

    &--current {
      @apply !bg-indigo-600 dark:!bg-indigo-400;
    }

    &--completed {
      @apply w-5 h-5;
      @apply rounded-none;
      @apply !bg-transparent;
      @apply text-white dark:text-gray-200;
    }
  }

  &__info {
    @apply min-w-0;
    @apply flex flex-col;
    @apply ml-4;
  }

  &__name {
    @apply text-sm font-medium;
    @apply text-gray-500 dark:text-gray-300;

    &--completed {
      @apply text-inherit;
    }

    &--current {
      @apply text-indigo-600 dark:text-indigo-400;
    }
  }

  &__description {
    @apply text-sm text-gray-400;
  }
}
</style>
