<template>
  <div v-circular-tab class="asset-tab__form__area--col">
    <div class="asset-tab__form" :class="formClasses">
      <div class="asset-tab__form__input">
        <UIInputDropdown
          v-model="priceSource"
          id-key="key"
          :data="ASSET_PRICES_LIST"
        />
      </div>
      <UIButton
        v-if="isSwitchViewButtonVisible"
        data-refid="assetPricesSwitchView"
        v-bind="{
          disabled: isLoading,
          icon: switchViewButtonIcon,
          label: switchViewButtonLabel,
        }"
        variant="light"
        hide-label-on-mobile
        @click="handleClickSwitchViewButton"
      />
      <UIButton
        v-if="isCreateButtonVisible"
        data-refid="assetPricesShowForm"
        v-bind="{
          disabled: isLoading,
          icon: newPriceButtonIcon,
          label: newPriceButtonLabel,
        }"
        variant="secondary"
        hide-label-on-mobile
        @click="handleClickNewPrice"
      />
    </div>
    <AssetTabPricesForm
      v-if="isFormShown"
      v-bind="{
        disabled: isLoading,
        values: currentPrice,
      }"
      @submit="handleAddPrice"
    />
    <div class="asset-tab__form__area--col">
      <AssetTabPricesTable
        v-if="!isChartOpened"
        ref="assetTabPricesTableRef"
        v-model="currentPrice"
        v-model:creating="isCreatingMode"
        v-bind="{ inputItems, instance, loading }"
      />
      <div v-else class="asset-tab__form__chart-container">
        <UILoading v-if="isLoading" inset message="Loading prices..." />
        <AssetTabPricesChart
          v-else
          v-bind="{ instance, priceSource }"
          :items="inputItems"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  onBeforeMount,
  ref,
  useTemplateRef,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { orderBy } from 'lodash'

import { AssetClass } from '..'
import { AssetPrice } from '../utils/types'
import { AssetPriceForm } from './utils/types'
import { AssetPriceSource } from './utils/enums'
import { ReadonlyMode } from '@types'

import { ASSET_FIELD } from '../utils/const'
import { ASSET_PRICES_LIST } from './utils/const'
import { ROUTE_NAME } from '@/const'
import { READONLY_MODE } from '@/const/common'

import { filterPricesBySource, handlePricesOnMount } from './utils/helpers'
import { handleCatchedError } from '@/helpers/common'
import { stringToDateTime } from '@/helpers/dates'

import { useNotifications } from '@/plugins/notification'

import { useAssetsStore } from '@/store/assets'

import { UIButton, UIInputDropdown, UILoading } from '@ui'
import AssetTabPricesChart from './AssetTabPricesChart.vue'
import AssetTabPricesForm from './AssetTabPricesForm.vue'
import AssetTabPricesTable from './AssetTabPricesTable.vue'
import {
  XMarkIcon,
  PlusIcon,
  TableCellsIcon,
  ChartBarSquareIcon,
} from '@heroicons/vue/24/outline'

type Props = {
  instance: AssetClass
  disallowCreating?: boolean
}

const props = defineProps<Props>()

const assetsStore = useAssetsStore()
const route = useRoute()
const router = useRouter()

const { error } = useNotifications()

const assetTabPricesTableRef = useTemplateRef('assetTabPricesTableRef')

const loading = ref(false)

const priceSource = ref(AssetPriceSource.ALL)

const currentPrice = ref<AssetPrice>()

const isCreatingMode = ref(false)
const isChartOpened = ref(false)

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const isCreateButtonVisible = computed(
  () => !props.disallowCreating && !isReadonly?.value,
)

const isLoading = computed(
  () =>
    loading.value ||
    assetsStore.loadingPrices ||
    assetsStore.loadingSecurityMasterPrices ||
    assetsStore.loadingPricesAction,
)

const formClasses = computed(() => ({
  'asset-tab__form--active': isCreatingMode.value,
}))

const isSwitchViewButtonVisible = computed(
  () =>
    !!inputItems.value.length ||
    props.instance.field<string | null>(ASSET_FIELD.LINKED_SECURITY_ID),
)

const switchViewButtonIcon = computed(() =>
  isChartOpened.value ? TableCellsIcon : ChartBarSquareIcon,
)

const switchViewButtonLabel = computed(() =>
  isChartOpened.value ? 'Table' : 'Chart',
)

const newPriceButtonIcon = computed(() =>
  isCreatingMode.value ? XMarkIcon : PlusIcon,
)

const newPriceButtonLabel = computed(() =>
  isCreatingMode.value ? '' : 'New price',
)

const isFormShown = computed(() => isCreatingMode.value && !isReadonly?.value)

const assetId = computed(() => props.instance.id)

const inputItems = computed(() => {
  const data = props.instance.prices
  if (!data) return []
  const filterFn = filterPricesBySource(priceSource.value)
  const dir = isChartOpened.value ? 'asc' : 'desc'
  return orderBy(data?.filter(filterFn), 'date', dir)
})

const handleClickNewPrice = () => {
  assetTabPricesTableRef.value?.clear()
  isCreatingMode.value = !isCreatingMode.value
}

const handleAddPrice = async (formData: AssetPriceForm) => {
  const date = stringToDateTime(formData.date || undefined)
  if (
    props.instance.prices?.find(
      item =>
        date &&
        stringToDateTime(item.date || undefined)?.hasSame(date, 'day') &&
        item.source == AssetPriceSource.MANUAL,
    )
  ) {
    await error({
      message: `A price for date: ${formData.date} already exists. Edit that record if you want to change it.`,
    })
    return
  }
  if (!formData.date || formData.close === null) return
  const data = {
    asset_id: assetId.value,
    date: formData.date,
    close: formData.close,
  }
  try {
    const result = await assetsStore.addAssetPrice(data, false)
    if (result) {
      isCreatingMode.value = false
      // eslint-disable-next-line vue/no-mutating-props
      props.instance.prices?.push(result as AssetPrice)
    }
  } catch (e) {
    handleCatchedError(e as string, data)
  }
}

const handleClickSwitchViewButton = () => {
  const name = isChartOpened.value
    ? ROUTE_NAME.ASSETS_ITEM_PRICES_TABLE
    : ROUTE_NAME.ASSETS_ITEM_PRICES_CHART

  router.push({ name, params: { id: assetId.value } })
}

watch(
  () => route.name,
  () => {
    isChartOpened.value = route.name === ROUTE_NAME.ASSETS_ITEM_PRICES_CHART
  },
  { immediate: true },
)

onBeforeMount(async () => {
  loading.value = true
  const result = await handlePricesOnMount(props.instance, assetsStore)
  // eslint-disable-next-line vue/no-mutating-props
  props.instance.prices = result
  loading.value = false
})
</script>

<script lang="ts">
export default {
  name: 'AssetTabPrices',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
