export const PERIODS_LIMIT = 15

export const FREQUENCY_VALUES = [
  {
    key: 'day',
    value: 'DAILY',
  },
  {
    key: 'week',
    value: 'WEEKLY',
  },
  {
    key: 'month',
    value: 'MONTHLY',
  },
  {
    key: 'quarter',
    value: 'QUARTERLY',
  },
  {
    key: 'year',
    value: 'YEARLY',
  },
]
