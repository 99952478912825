<template>
  <li>
    <div class="app-breadcrumb__item">
      <div class="app-breadcrumb__item__name" @click="handleClick">
        {{ displayName }}
      </div>
      <UIDropdown
        v-if="items?.length"
        v-bind="{ items }"
        value-key="label"
        placement="right-start"
        class="app-breadcrumb__item__dropdown"
      >
        <ChevronRightIcon
          class="app-breadcrumb__item__dropdown__chevron"
          aria-hidden="true"
        />
      </UIDropdown>
    </div>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy } from 'lodash'

import { Breadcrumb } from './utils/type'

import { ANALYTICS_REPO_ROOT } from '@/store/analytics/utils/const'
import { ASSET_FIELD } from '@/entities/assets/utils/const'
import { UNKNOWN_VALUE } from '@/const/common'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'

import { UIDropdown } from '@ui'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'

type Props = {
  item: Breadcrumb
}

type Emits = {
  'click:item': [path: Breadcrumb['path']]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()

const assetsBunch = computed(() => assetsBunchStore.getList)

const isRoot = computed(() => props.item.assetId === ANALYTICS_REPO_ROOT)
const displayName = computed(() => {
  let result: string | undefined
  if (isRoot.value) {
    result = repositoriesStore.getCurrentRepositoryName
  } else {
    const asset = assetsBunch.value.get(props.item.assetId)
    result = asset?.field<string>(ASSET_FIELD.NAME).value
  }
  return result || UNKNOWN_VALUE
})

const items = computed(() => {
  const result = props.item.children.map(child => {
    const asset = assetsBunch.value.get(child.assetId)
    const label = asset?.field<string>(ASSET_FIELD.NAME).value || UNKNOWN_VALUE
    return {
      label,
      action: (hide: () => void) => {
        hide()
        emit('click:item', child.path)
      },
    }
  })
  return orderBy(result, 'label')
})

const handleClick = () => {
  emit('click:item', props.item.path)
}
</script>

<script lang="ts">
export default {
  name: 'BreadcrumbItem',
}
</script>

<style lang="postcss">
.app-breadcrumb {
  &__item {
    @apply flex items-center;

    &__name {
      @apply mr-3;
      @apply text-sm font-medium;
      @apply text-gray-500 hover:text-gray-700;
      @apply cursor-pointer;
    }

    &__dropdown {
      @apply flex;

      &__chevron {
        @apply h-5 w-5;
        @apply shrink-0;
        @apply text-gray-400;
        @apply cursor-pointer;
      }
    }
  }
}
</style>
