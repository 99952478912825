<template>
  <div
    v-if="isOpenedContainer"
    class="ui-tree__item group"
    :class="mainClasses"
    :style="mainStyles"
    @contextmenu.prevent="handleOnSelfContextmenu"
  >
    <slot name="before" :key-value="props.keyValue" />
    <TreeItemBranch
      v-bind="{ branches, currentBranch, hasChildren, isRoot, isOpened }"
      @click:branch="handleClickBranch"
    />
    <div class="ui-tree__item-area" :class="areaClasses">
      <div
        class="ui-tree__name"
        :class="nameClasses"
        :data-leaf="isLeafNode || undefined"
        @click="handleClickSelfName"
      >
        <slot name="name">
          <component :is="displayName" />
        </slot>
        <div v-if="description" class="ui-tree__description">
          <component :is="displayDescription" />
        </div>
      </div>
      <div v-if="meta" class="ui-tree__value">
        <slot v-bind="{ ...meta, search, isRoot }" />
      </div>
    </div>
    <div v-if="$slots.actions" class="ui-tree__actions">
      <slot
        name="actions"
        :key-value="props.keyValue"
        :item="node"
        :is-leaf="isLeafNode"
      />
    </div>
  </div>
  <TreeItem
    v-for="([key, data], index) in nodes"
    :key="`${currentPath}.${key}`"
    v-bind="{ collapsed }"
    :node="data"
    :key-value="key"
    :path="currentPath"
    :scope-position="index"
    :scope-length="nodes.length"
    :branches="nextBranches"
    :is-opened-container="isOpened"
    @click:name="handleClickName"
    @on:contextmenu="handleOnContextmenu"
  >
    <template #before="slotProps">
      <slot name="before" v-bind="slotProps" />
    </template>
    <template #default="slotProps">
      <slot v-bind="slotProps" />
    </template>
    <template v-if="$slots.actions" #actions="slotProps">
      <slot name="actions" v-bind="slotProps" />
    </template>
  </TreeItem>
</template>

<script setup lang="ts">
import {
  Ref,
  computed,
  ComputedRef,
  h,
  inject,
  onMounted,
  ref,
  watch,
} from 'vue'
import { has, omit, orderBy, pick } from 'lodash'

import {
  Tree,
  TreeBranch,
  TreeNodeDescriptionGetter,
  TreeNodeFormatter,
} from '../utils/types'

import {
  HASH_DELIMITER,
  INFO_SEPARATOR,
  TREE_ITEM_FOUND_CLASS,
} from '../utils/const'

import TreeItemBranch from './TreeItemBranch.vue'

type Props = {
  node: Tree
  keyValue: string

  path: string

  isRoot?: boolean
  collapsed?: boolean

  branches?: TreeBranch[]

  scopePosition?: number
  scopeLength?: number

  isOpenedContainer: boolean
}

type Emits = {
  'on:contextmenu': [data: Tree, isLeaf: boolean]
  'click:name': [data: Tree, isLeaf: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({ inheritAttrs: true })

const metaFields = inject<string[]>('metaFields', [])
const foundData = inject<ComputedRef<Record<string, unknown>>>('foundData')
const search = inject<Ref<string>>('search')
const wide = inject<boolean>('wide')
const formatter = inject<TreeNodeFormatter>('formatter')
const getDescription = inject<TreeNodeDescriptionGetter>('getDescription')
const sortField = inject<string>('sortField')
const sortDir = inject<'asc' | 'desc'>('sortDir')

const isOpened = ref(false)

const isItLastNode = computed(
  () => props.scopeLength === Number(props.scopePosition) + 1,
)

const currentBranch = computed(() => (isItLastNode.value ? 'l' : 'x'))
const nextBranches = computed(() => {
  if (props.isRoot) return []
  const result = [...(props.branches || [])]
  if (isItLastNode.value) {
    result.push('o')
  } else {
    result.push('i')
  }
  return result
})

const meta = computed(() => pick(props.node, metaFields))

const nodes = computed(() => {
  const data = omit(props.node, metaFields)
  const entries = Object.entries<Tree>(data)
  if (sortField) {
    return orderBy(entries, ([, item]) => item[sortField], sortDir)
  } else {
    return entries
  }
})

const hasChildren = computed(() => !!nodes.value.length)

const isFound = computed(
  () => !props.isRoot && !!has(foundData?.value, currentPath.value),
)
const isFoundBranch = computed(
  () =>
    !!(
      foundData?.value &&
      Object.keys(foundData?.value).find(key =>
        key.startsWith(currentPath.value),
      )
    ) || isFound.value,
)

const mainStyles = computed(() => ({
  top: `${nextBranches.value.length * (wide ? 3 : 1.5)}rem`,
}))

const mainClasses = computed(() => ({
  'ui-tree__item--wide': wide,
  [TREE_ITEM_FOUND_CLASS]: isFound.value,
}))

const areaClasses = computed(() => ({
  'ui-tree__item-area--root': props.isRoot,
}))

const isLeafNode = computed(() => {
  const children = omit(props.node, metaFields || [])
  return !Object.keys(children).length
})

const nameClasses = computed(() => ({
  'ui-tree__name--wide': wide && !description.value,
}))

const formattedName = computed(() =>
  formatter ? formatter(props.keyValue) : props.keyValue,
)

const displayName = computed(() =>
  h('span', markSearchedValue(formattedName.value)),
)

const description = computed(() => {
  if (props.isRoot || !getDescription) return
  const value = getDescription(props.keyValue)
  if (value === formattedName.value) return
  return value
})

const displayDescription = computed(
  () => description.value && h('span', markSearchedValue(description.value)),
)

const currentPathName = computed(
  () =>
    `${formattedName.value}${description.value ? `${INFO_SEPARATOR}${description.value}` : ''}`,
)

const currentPath = computed(() => {
  const value = props.isRoot ? props.keyValue : currentPathName.value
  return `${props.path ? `${props.path}${HASH_DELIMITER}` : ''}${value.toLowerCase()}`
})

const markSearchedValue = (value: string) => {
  let result: any
  if (search && isFound.value) {
    const start = value.toLowerCase().indexOf(search.value.toLowerCase())
    let end
    if (start < 0) {
      return value
    } else {
      end = start + search.value.length
    }
    const part1 = value.slice(0, start)
    const part2 = value.slice(start, end)
    const part3 = value.slice(end)
    return h('span', [part1, h('mark', part2), part3])
  } else {
    result = value
  }
  return result
}

const handleClickBranch = () => {
  if (isFoundBranch.value) return
  isOpened.value = !isOpened.value
}

const handleOnSelfContextmenu = () => {
  emit('on:contextmenu', props.node, isLeafNode.value)
}

const handleOnContextmenu = (data: Tree, isLeaf: boolean) => {
  emit('on:contextmenu', data, isLeaf)
}

const handleClickSelfName = () => {
  emit('click:name', props.node, isLeafNode.value)
}

const handleClickName = (data: Tree, isLeaf: boolean) => {
  emit('click:name', data, isLeaf)
}

watch(
  () => props.isOpenedContainer,
  value => {
    if (value) return
    isOpened.value = false
  },
)

watch(isFoundBranch, value => {
  if (props.isRoot || (props.collapsed && !value)) return
  isOpened.value = value
})

onMounted(() => {
  isOpened.value = props.isRoot || props.collapsed
})
</script>

<script lang="ts">
export default {
  name: 'TreeItem',
}
</script>

<style lang="postcss">
.ui-tree {
  &__item {
    @apply h-[1.5rem];
    @apply flex;
    @apply sticky top-0;
    @apply px-1 pr-[0.15rem];
    @apply bg-white hover:bg-gray-50;
    @apply dark:bg-gray-800 dark:hover:bg-gray-750;

    &--wide {
      @apply h-[3rem];
      @apply px-4;
    }

    &--highlighted {
      @apply !bg-indigo-100 dark:!bg-indigo-900;
    }
  }

  &__item-area {
    @apply flex flex-auto items-center;
    @apply px-1 gap-x-3;
    @apply overflow-hidden;
  }

  &__name {
    @apply text-gray-500 dark:text-gray-400;
    @apply text-sm;
    @apply leading-tight;
    @apply truncate;
    @apply shrink-0;

    &--wide {
      @apply whitespace-normal;
      @apply line-clamp-2;
    }
  }

  &__description {
    @apply text-xs;
    @apply text-gray-400 dark:text-gray-500;
    @apply font-light;
  }

  &__value {
    @apply truncate;
  }

  &__actions {
    @apply flex items-center;
  }
}
</style>
