<template>
  <div
    :style="{ zIndex }"
    class="overlay-main-wrapper"
    data-refid="dialogElement"
  >
    <div
      class="overlay-wrapper"
      @mousedown="handleOverlayMouseDown"
      @mouseup="handleOverlayMouseUp"
    >
      <TransitionChild
        as="div"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="overlay"></div>
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 sm:scale-100"
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          v-circular-tab
          class="main-wrapper"
          :class="mainWrapperClasses"
          @mousedown.stop
          @mouseup="handleMouseUp"
        >
          <div v-if="!!title || $slots.title" class="title-wrapper">
            <component :is="title" v-if="typeof title === 'object'" />
            <slot v-else name="title">
              <h3 class="title">
                {{ title }}
              </h3>
            </slot>
            <button
              v-if="!uncloseable"
              type="button"
              class="close"
              tabindex="-1"
              @click="handleClose"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div
            v-else-if="!uncloseable && !hideCloseButton"
            class="absolute top-2 right-2"
          >
            <button
              type="button"
              class="close"
              tabindex="-1"
              @click="handleClose"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="content-wrapper" :class="wrapperClass">
            <div
              v-if="icon"
              class="flex items-start flex-row gap-4"
              :class="
                icon.position === 'top'
                  ? 'flex-row sm:flex-col items-center'
                  : ''
              "
            >
              <div :class="['dialog-icon', `dialog-icon--${icon?.color}`]">
                <component :is="icon.name" class="h-6 w-6" aria-hidden="true" />
              </div>
              <div :class="['content', contentClass]">
                <slot />
              </div>
            </div>
            <template v-else>
              <div v-if="contentClass" :class="[contentClass]"><slot /></div>
              <slot v-else />
            </template>
          </div>
          <div v-if="$slots.buttons" class="buttons-wrapper">
            <slot name="buttons" />
          </div>
        </div>
      </TransitionChild>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, VNode } from 'vue'

import { DialogIcon, ModalSize } from './utils/types'

import { TransitionChild } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

type Props = {
  title?: string | VNode

  size?: ModalSize
  icon?: DialogIcon
  zIndex: number
  uncloseable?: boolean
  hideCloseButton?: boolean

  wrapperClass?: string
  contentClass?: string
}

type Emits = {
  hide: []
}

const emit = defineEmits<Emits>()
const { size = 'md', ...props } = defineProps<Props>()

const overlayTargetMousedown = ref(false)

const mainWrapperClasses = computed(() => {
  const classes = [`main-wrapper--${size}`]
  if (props.icon) classes.push('main-wrapper--with-icon')
  return classes
})

const handleOverlayMouseDown = () => {
  overlayTargetMousedown.value = true
}

const handleOverlayMouseUp = () => {
  if (overlayTargetMousedown.value) {
    overlayTargetMousedown.value = false
    handleClose()
  }
}

const handleMouseUp = () => {
  overlayTargetMousedown.value = false
}

const handleClose = () => {
  if (props.uncloseable) return
  emit('hide')
}
</script>
<script lang="ts">
export default {
  name: 'UIDialog',
  inheritAttrs: false,
}
</script>

<style scoped lang="postcss">
.overlay-main-wrapper {
  @apply fixed flex flex-col justify-center inset-0 overflow-y-auto;
}
.overlay-wrapper {
  @apply flex items-end sm:items-center justify-center min-h-screen;
  @apply text-center pt-2 px-2 pb-10 sm:p-0;
}
.overlay {
  @apply fixed inset-0 transition-opacity;
  @apply bg-opacity-75 bg-gray-500;
  @apply dark:bg-opacity-10 dark:bg-gray-200;
  @apply pointer-events-none;
}

.main-wrapper {
  @apply relative flex flex-col align-bottom rounded-lg;
  @apply max-h-[calc(100vh-6rem)] max-w-[calc(100vw-2rem)];
  @apply bg-white dark:bg-gray-800;
  @apply overflow-hidden shadow-xl transform transition-all my-8;
  @apply text-left sm:align-middle sm:min-w-[initial];

  &--with-icon {
    @apply text-left;
  }

  &--sm {
    @apply w-[24rem];
  }
  &--md {
    @apply w-[28rem];
  }
  &--2md {
    @apply w-[45rem];
  }
  &--lg {
    @apply w-[72rem];
  }
  &--full {
    @apply w-[calc(100vw-2rem)];
    @apply h-[calc(100vh-2rem)];
  }
}

.title-wrapper {
  @apply flex shrink-0 items-center justify-between px-6 py-3;
  @apply border-b border-gray-200 dark:border-gray-700;
}
.title {
  @apply flex items-center shrink-0;
  @apply flex-auto;
  @apply text-base leading-tight font-medium;
  @apply text-gray-400 sm:text-gray-500 dark:text-gray-300;
}
.close {
  @apply relative z-50 rounded-full;
  @apply bg-white dark:bg-gray-800;
  @apply text-gray-400 hover:text-gray-500;
  @apply dark:text-gray-300 dark:hover:text-gray-400;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
}

.content-wrapper {
  @apply h-full shrink;
  @apply flex flex-col;
  @apply relative;
  @apply overflow-y-auto;
  @apply p-4 sm:p-6;
  @apply text-sm text-gray-500 dark:text-gray-400;
}
.dialog-icon {
  @apply mx-auto shrink-0 flex items-center justify-center;
  @apply rounded-full h-12 w-12;
}

.dialog-icon--gray {
  @apply bg-gray-100 dark:bg-gray-800;
  @apply text-gray-600 dark:text-gray-300;
}
.dialog-icon--red {
  @apply bg-red-100 dark:bg-red-800;
  @apply text-red-600 dark:text-red-300;
}
.dialog-icon--green {
  @apply bg-green-100 dark:bg-green-800;
  @apply text-green-600 dark:text-green-300;
}
.dialog-icon--yellow {
  @apply bg-yellow-100 dark:bg-yellow-800;
  @apply text-yellow-600 dark:text-yellow-300;
}
.dialog-icon--indigo {
  @apply bg-indigo-100 dark:bg-indigo-800;
  @apply text-indigo-600 dark:text-indigo-300;
}
.buttons-wrapper {
  @apply flex flex-col sm:flex-row-reverse;
  @apply gap-2 sm:gap-4 px-4 pb-4;
}

.content {
  @apply flex flex-col flex-1;
}
</style>
