export enum ValueHistoryDataTabs {
  Assets,
  Liabilities,
}

export enum DashboardWidgetName {
  AccountsByType = 'AccountsByType',
  Commitments = 'Commitments',
  IncomeChangeSnapshot = 'IncomeChangeSnapshot',
  IncomeHistoryBarsChart = 'IncomeHistoryBarsChart',
  IncomeHistoryTable = 'IncomeHistoryTable',
  IncomePivot = 'IncomePivot',
  LinkedTransactions = 'LinkedTransactions',
  LinkedTransactionsPivot = 'LinkedTransactionsPivot',
  PerformancePivot = 'PerformancePivot',
  SectionDelimiter = 'SectionDelimiter',
  ValueChangeSnapshot = 'ValueChangeSnapshot',
  ValueHistoryBarsChart = 'ValueHistoryBarsChart',
  ValueHistoryPieChart = 'ValueHistoryPieChart',
  ValueHistoryTable = 'ValueHistoryTable',
  ValuePivot = 'ValuePivot',
}
