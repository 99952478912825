<template>
  <div class="timeline-periods">
    <UIInputDropdown
      v-model="modelValue"
      v-bind="{ ...props, data }"
      id-key="key"
      simple
      disable-recovery-value
      disallow-list-placeholder
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DataFieldSizes, RepositoryTimeline } from '@types'

import { PERIODS_LIMIT } from './utils/const'

import { UIInputDropdown } from '@ui'

type Props = {
  disabled?: boolean
  size?: DataFieldSizes
}

const props = defineProps<Props>()

const modelValue = defineModel<RepositoryTimeline['periods']>()

const data = computed(() =>
  Array.from(Array(PERIODS_LIMIT), (_, index) => {
    const key = index + 1
    return {
      key,
      value: key.toString(),
    }
  }),
)
</script>

<script lang="ts">
export default {
  name: 'TimelinePeriods',
}
</script>

<style scoped lang="postcss">
.timeline-periods {
  @apply w-16;
}
</style>
