import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { AxiosResponse } from 'axios'
import api from '@/store/api'

import { CommonTag } from '@types'
import { DocumentTag } from '@/entities/documents/utils/types'
import { ApiListResponse } from '../utils/types'

import {
  addStoreListItem,
  prepareResponseError,
  removeStoreListItem,
  updateStoreListItem,
} from '../utils/helpers'

import { useRepositoriesStore } from '@/store/repositories'

export const useDocumentsTagsStore = defineStore('documents-tags', () => {
  // INIT
  const repositoriesStore = useRepositoriesStore()

  const list = ref<DocumentTag[]>([])

  const initFlag = ref(false)

  const loading = ref(true)
  const loadingAction = ref(false)

  let abortController = new AbortController()

  const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

  // GETTERS
  const getList = computed<DocumentTag[]>(() => list.value)

  // SETTERS

  const setList = (data: DocumentTag[]) => {
    list.value = data
  }

  // ACTIONS

  const fetch = async (): Promise<DocumentTag[]> => {
    loading.value = true
    try {
      const result = await api.get<ApiListResponse<DocumentTag[]>>(
        'document_tags',
        {
          params: {
            repository_id: repositoryId.value,
          },
          signal: abortController.signal,
        },
      )
      const data = result.data.data
      setList(data)
      initFlag.value = true
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loading.value = false
    }
  }

  const store = async (name: string): Promise<DocumentTag> => {
    loadingAction.value = true
    try {
      const result = await api.post<DocumentTag, AxiosResponse<DocumentTag>>(
        'document_tags',
        {
          name,
          repository_id: repositoryId.value,
        },
      )
      const data = result.data
      addStoreListItem<DocumentTag>(list.value, data)
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const update = async (
    tag: Partial<CommonTag>,
  ): Promise<DocumentTag | undefined> => {
    loadingAction.value = true
    try {
      const result = await api.put<DocumentTag, AxiosResponse<DocumentTag>>(
        `document_tags/${tag.id}`,
        tag,
      )
      const data = result.data
      updateStoreListItem<DocumentTag>(list.value, data)
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const destroy = async (id: string): Promise<void> => {
    loadingAction.value = true
    try {
      await api.delete(`document_tags/${id}`)
      removeStoreListItem<DocumentTag>(list.value, id)
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const cancel = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const clear = () => {
    initFlag.value = false
    list.value = []
  }

  return {
    initFlag,
    loading,
    loadingAction,

    getList,

    fetch,

    store,
    update,
    destroy,

    cancel,
    clear,
  }
})
