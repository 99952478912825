<template>
  <Transition name="slide-fade">
    <div v-if="isPanelVisible" class="app-reload-page">
      <UIButton
        v-bind="{ size }"
        :icon="XMarkIcon"
        variant="light"
        @click="handleClose"
      />
      <UIButton
        v-bind="{ size }"
        label="Reload the page"
        variant="secondary"
        full
        @click="handleReload"
      />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useSwipe } from '@vueuse/core'

import { XMarkIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui'

const size = 'small'

const { direction } = useSwipe(document.body, { threshold: 500 })

const isPanelVisible = ref(false)

const handleClose = () => {
  isPanelVisible.value = false
}

const handleReload = () => {
  isPanelVisible.value = false
  window.location.reload()
}

watch(direction, value => {
  if (value !== 'down') return
  isPanelVisible.value = true
})
</script>

<style lang="postcss">
.app-reload-page {
  @apply fixed top-0 inset-x-0;
  @apply flex items-center;
  @apply gap-4 p-4;
  @apply bg-gray-50 dark:bg-gray-900;
  @apply bg-opacity-90 dark:bg-opacity-50;
  @apply z-[10000];
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-4rem);
  opacity: 0;
}
</style>
