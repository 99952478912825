import { App, ref } from 'vue'

import { IntercomOptions } from './utils/type'

import { appendScript, getAppMode } from '@/helpers/common'
import { callFunction, getAppVersion } from './utils/helpers'

const { VITE_APP_INTERCOM_WORKSPACE_ID, VITE_APP_MODE } = import.meta.env

let intercomOptions: IntercomOptions

const unread = ref(0)
const inited = ref(false)
const visible = ref(false)

const methods = {
  init: async (email?: string, user_hash?: string) => {
    if (VITE_APP_MODE !== 'production') return
    try {
      await appendScript(
        `https://widget.intercom.io/widget/${intercomOptions.app_id}`,
        'widget-intercom-script',
      )
    } catch (e) {
      console.error('Intercom failure')
    }
    if (inited.value && email && user_hash) {
      await callFunction('update', {
        email,
        user_hash,
      })
    } else {
      await callFunction('boot', {
        ...intercomOptions,
        email,
        user_hash,
        created_at: new Date().getTime(),
        vertical_padding: 80,
        hide_default_launcher: true,
        session_duration: 12300000,
      })
      await callFunction('onUnreadCountChange', function (unreadCount: number) {
        unread.value = unreadCount
      })
      await callFunction('onShow', () => {
        visible.value = true
      })
      await callFunction('onHide', () => {
        visible.value = false
      })
      inited.value = true
    }
  },
  getVisitorId: async () => {
    return (await callFunction('getVisitorId')) as string | undefined
  },
  trackEvent: async (trackName: string, metadata?: Record<string, unknown>) => {
    await callFunction('trackEvent', trackName, metadata)
  },
  hide: async () => {
    await callFunction('hide')
  },
  show: async () => {
    await callFunction('show')
  },
  shutdown: async () => {
    await callFunction('shutdown')
  },
  showMessages: async () => {
    await callFunction('showMessages')
  },
  showNewMessage: async (prePopulatedMessage?: string) => {
    await callFunction('showNewMessage', prePopulatedMessage)
  },
  startTour: async (tourId: number) => {
    await callFunction('startTour', tourId)
  },
  help: async (articleId: number) => {
    await callFunction('showArticle', articleId)
  },
}

export const useIntercom = () => {
  return {
    ...methods,
    inited,
    unread,
    visible,
  }
}

export default {
  install: async function (_: App, options?: IntercomOptions) {
    intercomOptions = {
      app_id: VITE_APP_INTERCOM_WORKSPACE_ID,
      app_stand: 'stage',
      app_version: getAppVersion(),
      app_mode: getAppMode(VITE_APP_MODE),
      ...options,
    }
  },
}
