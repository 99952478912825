import { TreeNodeDescriptionGetter, TreeNodeFormatter } from './types'

import {
  HASH_DELIMITER,
  INFO_SEPARATOR,
  TREE_CONTAINER_CLASS,
  TREE_ITEM_FOUND_CLASS,
  TREE_ITEM_HIGHLIGHTED_CLASS,
} from './const'

const clearHighlighted = (highlightedClass: string) => {
  const highlighted = document.querySelectorAll(`.${highlightedClass}`)
  highlighted.forEach(el => el.classList.remove(highlightedClass))
}

export const scrollToNode = (index: number) => {
  const el = document.querySelectorAll(`.${TREE_ITEM_FOUND_CLASS}`)[index - 1]
  const treeContainer = document.querySelector(`.${TREE_CONTAINER_CLASS}`)
  if (!el || !treeContainer) return

  treeContainer.scrollTop = 0
  clearHighlighted(TREE_ITEM_HIGHLIGHTED_CLASS)
  el.classList.add(TREE_ITEM_HIGHLIGHTED_CLASS)
  el?.scrollIntoView({
    block: 'end',
  })
}

export const generateHashData = (
  obj: any,
  meta?: string[],
  formatter?: TreeNodeFormatter,
  getDescription?: TreeNodeDescriptionGetter,
  isRoot = true,
  prefix = '',
) => {
  return Object.keys(obj).reduce((acc, k) => {
    const isMeta = meta?.includes(k)
    if (isMeta && !['string', 'number'].includes(typeof obj[k])) return acc
    let value
    if (isMeta || isRoot) {
      value = k
    } else {
      value = formatter ? formatter(k) : k
      if (getDescription) {
        const description = getDescription(k)
        if (value !== description) {
          value += `${INFO_SEPARATOR}${description}`
        }
      }
    }
    const key = `${prefix}${prefix && HASH_DELIMITER}${value}`.toLowerCase()
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(
        acc,
        generateHashData(obj[k], meta, formatter, getDescription, false, key),
      )
    } else {
      acc[isMeta ? prefix : key] = obj[k]
    }
    return acc
  }, {} as any)
}
