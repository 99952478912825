<template>
  <UIInputDropdown v-model="actionValue" v-bind="{ size, data }" id-key="key" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'

import { TransactionRule } from '@types'

import { CONTROL_SIZE as size } from '../utils/const'
import { ASSET_FIELD } from '@/entities/assets/utils/const'

import { orderedList } from '@/helpers/common'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import { UIInputDropdown } from '@ui'

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  required: true,
})

const assetsBunchStore = useAssetsBunchStore()

const accountsInstancesList = computed(() =>
  Array.from(assetsBunchStore.getCommonList.values()),
)

const data = computed(() => {
  return orderedList(
    accountsInstancesList.value.map(item => ({
      id: item.id,
      value: item.field(ASSET_FIELD.NAME).value,
    })),
    'id',
    'value',
  )
})

const actionValue = computed({
  get() {
    return modelValue.value.value
  },
  set(value) {
    modelValue.value.value = value || ''
  },
})

onBeforeMount(() => {
  if (actionValue.value !== undefined) return
  actionValue.value = ''
})

defineOptions({
  name: 'LinkedDataRulesActionAccount',
})
</script>
