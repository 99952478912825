<template>
  <UIInputDropdown
    ref="inputRef"
    v-bind="{ container, data, size, loading }"
    v-model="modelValue"
    id-key="key"
    :placeholder="t('Select value')"
  />
</template>

<script setup lang="ts">
import { computed, inject, onBeforeMount, ref, useTemplateRef } from 'vue'

import { ButtonSizes, I18nTranslate, ListItem } from '@types'
import { FilterComparison } from '../utils/enums'
import { FilterField } from '../utils/types'

import { FILTER_LAST_PERIODS } from './utils/const'

import { UIInputDropdown } from '@ui'
import { monthDateToName } from '@/helpers/dates'
import { useDebounceFn } from '@vueuse/core'

type Props = {
  fieldSettings?: FilterField
  comparison?: FilterComparison
}

const props = defineProps<Props>()

const modelValue = defineModel<any>()

defineExpose({
  start,
  init() {
    initData()
  },
})

const container = inject<string>('container')
const size = inject<ButtonSizes>('size')
const t = inject<I18nTranslate>('t', (data: string) => data)

const inputRef = useTemplateRef('inputRef')
const internalListData = ref<ListItem[]>()

const loading = ref(false)

const listData = computed(() => {
  if (props.comparison === FilterComparison.LAST) {
    return FILTER_LAST_PERIODS.map(key => ({
      key,
      value: `${key} ${t('days')}`,
    }))
  } else {
    return props.fieldSettings?.list || internalListData.value
  }
})

const data = computed(() => {
  if (props.fieldSettings?.isMonth) {
    return listData.value?.map(({ key }) => ({
      key,
      value: monthDateToName(`1999-${key}-01`),
    }))
  } else {
    return listData.value
  }
})

const initData = useDebounceFn(async () => {
  internalListData.value = undefined
  if (!props.fieldSettings?.getter) return
  loading.value = true
  internalListData.value = await props.fieldSettings.getter()
  loading.value = false
}, 200)

async function start() {
  await initData()
  inputRef.value?.select().toggle(true)
}

onBeforeMount(initData)
</script>

<script lang="ts">
export default {
  name: 'FilterGroupsItemParamValueDropdown',
}
</script>

<style scoped lang="postcss"></style>
