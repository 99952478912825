<template>
  <div class="timeline">
    <UILoading
      v-if="isBreadcrumbHidden"
      :message="loadingMessage"
      class="timeline__loader"
      left-aligned
    />
    <AppBreadcrumb
      v-else-if="!hideBreadcrumb"
      v-bind="{ path, tree }"
      @click:item="setPath"
    />
    <UITimeline
      v-if="!isTmelineExists"
      v-model="sliderDate"
      v-bind="{ dates, disabled, hideSlider, key, timeline }"
      @update:timeline="handleUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, WritableComputedRef } from 'vue'

import { RepositoryTimeline } from '@types'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'

import { useResponsive } from '@/plugins/responsiveUI'

import { UILoading, UITimeline } from '@ui'
import { AppBreadcrumb } from '@app'

type Props = {
  disabled?: boolean
  hideSlider?: boolean
  hideBreadcrumb?: boolean
}

type Emits = {
  'change:date': []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()

const { isMobile } = useResponsive()

const sidebarOpen = inject<WritableComputedRef<boolean, boolean>>('sidebarOpen')

const key = computed(() => repositoriesStore.getCurrentRepository?.id)

const timeline = computed(() => repositoriesStore.getCurrentTimeline)
const dates = computed(() => analyticsStore.getDatesRange)

const sliderDate = computed({
  get() {
    return analyticsStore.getDate
  },
  set(value) {
    emit('change:date')
    analyticsStore.setDate(value)
  },
})

const isInit = computed(() => !analyticsStore.module.tree.isInit)
const isTmelineExists = computed(() => !timeline.value)

const isBreadcrumbHidden = computed(
  () =>
    (isTmelineExists.value ||
      analyticsStore.module.tree.isLoading ||
      isInit.value) &&
    !analyticsStore.getError,
)

const loadingMessage = computed(() =>
  isTmelineExists.value
    ? 'Loading timeline...'
    : isMobile.value || sidebarOpen?.value
      ? ''
      : 'Loading analytics...',
)

const tree = computed(() => analyticsStore.module.tree.getPreparedData)
const path = computed(() => analyticsStore.getPath)

const setPath = (value: string[]) => {
  analyticsStore.setPath(value)
}

const handleUpdate = async (
  timeline: RepositoryTimeline,
  isEndChanged = false,
) => {
  analyticsStore.cancel()
  analyticsStore.refresh(isEndChanged ? undefined : ['tree'])

  await repositoriesStore.updateTimeline(timeline, isEndChanged)
  analyticsStore.markAsDeprecated(isEndChanged ? [] : ['tree', 'irr_moic'])
}
</script>

<script lang="ts">
export default {
  name: 'DashboardTimeline',
}
</script>

<style lang="postcss">
.timeline {
  @apply flex items-center justify-end;
  @apply w-full;
  @apply mb-3 -mt-2;

  &__loader {
    @apply py-2;
    @apply mr-auto;
  }

  &__mobile {
    @apply flex items-center;
    @apply space-x-2;

    &__switcher {
      @apply !py-2.5;
    }

    &__period {
      @apply flex items-center;
      @apply gap-2;
      @apply cursor-pointer;

      &__date {
        @apply text-sm;
        @apply whitespace-nowrap;
      }

      &__toggler {
        @apply w-4 h-4;
        @apply shrink-0;
        @apply text-indigo-600;
      }
    }
  }

  &__dropdown {
    @apply flex items-center;
    @apply gap-2;
    @apply -mx-2 mb-2 -mt-2;
    @apply p-2;
    @apply bg-gray-50;
    @apply rounded-md;

    &__date {
      @apply flex-auto;
    }

    &__prefix {
      @apply text-xs;
      @apply text-gray-500;
    }
  }
}
</style>
