export const STEPS_LIST = [
  {
    name: 'Name & description',
  },
  {
    name: 'Cost',
  },
  {
    name: 'Asset tags',
    description: 'optional',
  },
  {
    name: 'Summary',
  },
]

export const ASSET_CLASS = 'Asset Class'

export const ASSET_CLASS_LIST = [{ value: 'Real Estate' }]

export const INVESTMENT_STRATEGY = 'Investment Strategy'
export const INVESTMENT_STRATEGY_LIST = [
  { value: 'Personal Property' },
  { value: 'Investment Property' },
]
