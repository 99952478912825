<template>
  <div
    ref="valueRef"
    v-tooltip.permanent="tooltip"
    class="ui-json-tree__value"
    :class="mainClasses"
  >
    <component :is="displayName" />
  </div>
</template>

<script setup lang="ts">
import { computed, h, ref, useTemplateRef } from 'vue'
import { useResizeObserver } from '@vueuse/core'

type Props = {
  value: string
  search?: string
}

const props = defineProps<Props>()

const valueRef = useTemplateRef('valueRef')
const isValueEllipsis = ref(false)

const mainClasses = computed(() => {
  switch (props.value) {
    case 'undefined':
      return 'ui-json-tree__value--undefined'
    case 'null':
      return 'ui-json-tree__value--null'
    default:
      if (props.value === '...' || /^\[[0-9]+\]$/.test(props.value)) {
        return 'ui-json-tree__value--black'
      } else if (props.value === `''`) {
        return 'ui-json-tree__value--undefined'
      }
      return isNumeric(props.value)
        ? 'ui-json-tree__value--number'
        : 'ui-json-tree__value--string'
  }
})

const tooltip = computed(() => {
  if (!isValueEllipsis.value) return
  return {
    content: props.value,
    handleResize: true,
  }
})

const displayName = computed(() => {
  let result: any
  if (props.search) {
    const start = props.value.toLowerCase().indexOf(props.search.toLowerCase())
    let end
    if (start < 0) {
      return h('span', props.value)
    } else {
      end = start + props.search.length
    }
    const part1 = props.value.slice(0, start)
    const part2 = props.value.slice(start, end)
    const part3 = props.value.slice(end)
    return h('span', [part1, h('mark', part2), part3])
  } else {
    result = props.value
  }
  return h('span', result)
})

useResizeObserver(valueRef, () => {
  if (!valueRef.value) return
  isValueEllipsis.value =
    valueRef.value.offsetHeight < valueRef.value.scrollHeight ||
    valueRef.value.offsetWidth < valueRef.value.scrollWidth
})

const isNumeric = (value: string) => {
  return /^-?[0-9.]+$/.test(value)
}
</script>

<script lang="ts">
export default {
  name: 'TreeJsonValue',
}
</script>

<style lang="postcss">
.ui-json-tree {
  &__value {
    @apply text-sm;
    @apply leading-tight;
    @apply truncate;

    &--black {
      @apply text-black dark:text-gray-200;
    }
    &--undefined {
      @apply text-gray-300;
    }
    &--null {
      @apply text-red-300;
    }
    &--number {
      @apply text-indigo-500;
    }
    &--string {
      @apply text-green-600;
    }
  }
}
</style>
