<template>
  <div class="ui-filter__groups">
    <div
      v-for="(group, groupIndex) in groups"
      :key="group.key"
      class="ui-filter__groups__container"
    >
      <Logic v-model="logic" v-bind="{ index: groupIndex, paramsLength: 2 }" />
      <div class="ui-filter__groups__data">
        <GroupsItem
          ref="groupRef"
          v-bind="{ fields, flat, group, groupIndex }"
          @remove:group="handleRemoveGroup"
          @update:group="handleUpdateGroup"
        />
      </div>
    </div>
    <div class="ui-filter__groups__buttons">
      <UIButton
        v-bind="{ size }"
        :label="t('Add filter')"
        data-refid="filter__add-item_button"
        :icon="PlusIcon"
        @click="handleAddGroup"
      />
      <UIButton
        v-if="isClearButtonVisible"
        v-bind="{ size }"
        :label="t('Clear all')"
        data-refid="filter__clear-all_button"
        variant="light"
        @click="handleClearGroup"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, onMounted, useTemplateRef } from 'vue'
import { cloneDeep } from 'lodash'

import { ButtonSizes } from '@types'
import { Filter, FilterField, FilterGroup } from '../utils/types'
import { I18nTranslate } from '@/plugins/i18n/utils/types'

import { FILTER_DEFAULT_MODEL } from './utils/const'

import { generateRandomKey } from './utils/helpers'

import { UIButton } from '@ui'
import { PlusIcon } from '@heroicons/vue/24/outline'
import Logic from './Logic.vue'
import GroupsItem from './GroupsItem.vue'

type Props = {
  fields?: FilterField[]
  flat?: boolean
}

defineProps<Props>()

const modelValue = defineModel<Filter>()

const size = inject<ButtonSizes>('size')
const t = inject<I18nTranslate>('t', (data: string) => data)

const groupRef = useTemplateRef<(typeof GroupsItem)[]>('groupRef')

const computedValue = computed({
  get() {
    if (modelValue.value) return modelValue.value
    const filter = cloneDeep<Filter>(FILTER_DEFAULT_MODEL)
    const defaultGroup = createGroup()
    filter.params.push(defaultGroup)
    return filter
  },
  set(value) {
    modelValue.value = value
  },
})

const logic = computed({
  get() {
    return computedValue.value.logic
  },
  set(logic) {
    computedValue.value = {
      ...computedValue.value,
      logic,
    }
  },
})

const groups = computed({
  get() {
    return computedValue.value.params
  },
  set(params) {
    computedValue.value = {
      ...computedValue.value,
      params,
    }
  },
})

const paramsLength = computed(() => groups.value.length)

const isClearButtonVisible = computed(() => paramsLength.value > 1)

const createGroup = () => {
  const group = cloneDeep<FilterGroup>(FILTER_DEFAULT_MODEL)
  const key = generateRandomKey()
  group.params = [{ key }]
  group.key = generateRandomKey()
  return group
}

const handleAddGroup = async () => {
  const newGroup = createGroup()
  groups.value = [...groups.value, newGroup]
  await nextTick()
  groupRef.value?.[paramsLength.value - 1]?.start()
}

const handleClearGroup = async () => {
  modelValue.value = undefined
  await nextTick()
  groupRef.value?.[0]?.start()
}

const handleRemoveGroup = (key: string) => {
  if (paramsLength.value === 1) {
    handleClearGroup()
    return
  }
  groups.value = groups.value.filter(group => group.key !== key)
}

const handleUpdateGroup = (key: string, data: Partial<FilterGroup>) => {
  groups.value = groups.value.map(group =>
    group.key === key ? { ...group, ...data } : group,
  )
}

onMounted(async () => {
  if (paramsLength.value > 1 || groups.value[0]?.params[0].field) return
  await nextTick()
  setTimeout(() => {
    groupRef.value?.[0]?.start()
  }, 200)
})
</script>

<script lang="ts">
export default {
  name: 'FilterGroups',
}
</script>

<style scoped lang="postcss">
.ui-filter__groups {
  @apply flex flex-col;
  @apply gap-2;

  &__container {
    @apply flex items-center;
    @apply gap-2;
  }

  &__data {
    @apply flex flex-col flex-auto;
    @apply gap-2;
  }

  &__buttons {
    @apply flex items-center justify-between;
    @apply gap-2;
    @apply mt-2;
  }
}
</style>
