<template>
  <div class="wizzard__form">
    <div class="grid grid-cols-2 gap-8">
      <component
        :is="transaction.getFormDate()"
        :instance="transaction"
        label="Date of purchase"
      />
      <component
        v-bind="{ focusOnLoad }"
        :is="transaction.getFormAmount2()"
        :instance="transaction"
        label="Cost"
        hide-sign
      />
    </div>
    <component
      :is="transaction.getFormDescription()"
      :instance="transaction"
      label="Transaction notes"
      placeholder="Optional"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetClass } from '@/entities/assets'

import { TransactionClass } from '..'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
}

const props = defineProps<Props>()

const focusOnLoad = computed(() => !props.transaction.entries[1]?.amount)
</script>

<script lang="ts"></script>

<style lang="postcss"></style>
