<template>
  <UIGridSkeleton
    v-if="isLoading"
    v-bind="{ collapseWidth, columns, sm }"
    message="Loading prices..."
  />
  <UIGrid
    v-else
    v-model:sort="sort"
    data-refid="assetPricesList"
    v-bind="{ collapseWidth, columns, editRowId, items, sm }"
    :lazy="50"
    scrollable
    editable-rows
  >
    <template #cellDate="{ editRow }">
      <div
        v-if="editRow"
        class="asset-tab__form-input"
        @mouseup.stop
        @mousedown.stop
      >
        <UILoading v-if="processing" left-aligned />
        <UIDatePickerField v-else v-model="formData.date" placeholder="Date" />
      </div>
    </template>
    <template #cellClose="{ editRow }">
      <div
        v-if="editRow"
        class="asset-tab__form-input"
        @mouseup.stop
        @mousedown.stop
      >
        <UILabeledField
          v-if="!processing"
          v-model="formData.close"
          type="number"
          placeholder="Close"
        />
      </div>
    </template>
    <template #cellSource="{ editRow, displayValue }">
      <div
        v-if="editRow"
        class="asset-tab__form-edit"
        @mouseup.stop
        @mousedown.stop
      >
        <div class="asset-tab__form-value">
          {{ displayValue }}
        </div>
        <template v-if="!processing">
          <UIButton
            title="Approve changes"
            label=""
            :icon="CheckIcon"
            :disabled="isApproveButtonDisabled"
            type="button"
            variant="light-secondary"
            @click="handleApproveEditClick"
          />
          <UIButton
            title="Cancel"
            label=""
            :icon="XMarkIcon"
            type="button"
            variant="light-red"
            @click="handleCancelEdit"
          />
        </template>
      </div>
    </template>
    <template v-if="!isReadonly" #actions="{ item, size }">
      <template v-if="item.source === AssetPriceSource.MANUAL">
        <UIButton
          v-bind="{ size }"
          label="Edit"
          :icon="PencilIcon"
          variant="light-secondary"
          @click="handleClickEdit(item)"
        />
        <UIButton
          v-bind="{ size }"
          label="Delete"
          :icon="TrashIcon"
          variant="light-red"
          @click="handleClickDelete(item)"
        />
      </template>
    </template>
  </UIGrid>
  <UIRemoveDialog
    v-model="removeDialog"
    v-bind="{
      title: removeDialogTitle,
      message: 'Are you sure you want to delete this price?',
      label: 'Delete',
    }"
    @remove="handleDelete"
  />
</template>

<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'

import { AssetClass } from '..'
import { AssetPrice } from '../utils/types'
import { AssetPriceSource } from './utils/enums'
import { AssetPriceCloseField, AssetPricePut, ReadonlyMode, Sort } from '@types'

import { READONLY_MODE } from '@/const/common'

import { priceFormatter } from '@/helpers/numbers'
import { handleCatchedError } from '@/helpers/common'
import { stringToLocalDateString } from '@/helpers/dates'

import useGridSort from '@/components/hooks/gridSort'

import { useAssetsStore } from '@/store/assets'

import {
  UIButton,
  UIDatePickerField,
  UIGrid,
  UIGridSkeleton,
  UILabeledField,
  UILoading,
  UIRemoveDialog,
} from '@ui'
import {
  XMarkIcon,
  CheckIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline'

type Props = {
  instance: AssetClass
  inputItems: AssetPrice[]
  loading: boolean
}

const props = defineProps<Props>()

const modelValue = defineModel<AssetPrice>()
const isCreatingMode = defineModel<boolean>('creating')

defineExpose({
  clear() {
    editRowId.value = undefined
  },
})

const assetsStore = useAssetsStore()

const editRowId = ref<string>()

const formData = reactive<Pick<AssetPrice, 'date' | 'close'>>({
  date: null,
  close: null,
})

const collapseWidth = 280

const removeDialog = ref(false)
const processing = ref(false)

const priceSource = ref(AssetPriceSource.ALL)

const removedPrice = ref<AssetPrice>()

const sort = ref<Sort[]>()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const isLoading = computed(
  () =>
    props.loading ||
    assetsStore.loadingPrices ||
    assetsStore.loadingSecurityMasterPrices ||
    assetsStore.loadingPricesAction,
)

const removeDialogTitle = computed(
  () => `Delete asset price '${removedPrice.value?.date}'`,
)

const isFormDirty = computed(
  () =>
    formData.date !== modelValue.value?.date ||
    formData.close !== modelValue.value.close,
)

const isApproveButtonDisabled = computed(
  () => !formData.date || formData.close === null || !isFormDirty.value,
)

const currentCloseField = computed<AssetPriceCloseField>(() =>
  priceSource.value === AssetPriceSource.SECURITY_MASTER_ADJ
    ? 'adjusted_close'
    : 'close',
)

const sm = '1fr 1fr'
const columns = computed(() => [
  {
    name: 'date',
    caption: 'Date',
    default: '2fr',
    formatter: stringToLocalDateString,
  },
  {
    name: currentCloseField.value,
    caption: 'Close',
    formatter: priceFormatter,
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
  },
  {
    name: 'source',
    caption: 'Source',
    cellClasses: 'ui-grid__cell--span-2',
    default: '105px',
  },
])

const inputItems = computed(() => props.inputItems)
const items = useGridSort<AssetPrice>(sort, columns, inputItems, ['close'])

const handleClickEdit = (item: AssetPrice) => {
  isCreatingMode.value = false
  editRowId.value = `${item.id}`
  formData.date = item.date
  formData.close = item.close
}

const handleClickDelete = (item: AssetPrice) => {
  isCreatingMode.value = false
  removedPrice.value = item
  removeDialog.value = true
}

const handleDelete = async () => {
  const id = removedPrice.value?.id
  if (!id) return
  try {
    removedPrice.value = undefined
    await assetsStore.deleteAssetPrice(id, false)
    const priceIndex = props.instance.prices?.findIndex(item => item.id === id)
    if (priceIndex !== undefined && priceIndex > -1) {
      // eslint-disable-next-line vue/no-mutating-props
      props.instance.prices?.splice(priceIndex, 1)
    }
  } catch (e) {
    handleCatchedError(e as string, { id })
  }
}

const handleCancelEdit = () => {
  editRowId.value = undefined
}

const handleApproveEditClick = async () => {
  if (!formData.date || formData.close === null || !editRowId.value) return
  processing.value = true
  setTimeout(async () => {
    const data = {
      id: editRowId.value,
      date: formData.date,
      close: formData.close,
    } as AssetPricePut
    try {
      const result = await assetsStore.updateAssetPrice(data, false)
      if (result) {
        if (props.instance.prices) {
          const priceIndex = props.instance.prices.findIndex(
            item => item.id === result.id,
          )
          // eslint-disable-next-line vue/no-mutating-props
          props.instance.prices[priceIndex] = result as AssetPrice
        }
        editRowId.value = undefined
      }
    } catch (e) {
      handleCatchedError(e as string, data)
    } finally {
      processing.value = false
    }
  })
}
</script>

<script lang="ts">
export default {
  name: 'AssetTabPrices',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
