<template>
  <div v-if="!isReadonly" class="asset-tab__form">
    <div class="asset-tab__form__input">
      <UIInputEditableDropdown
        v-model="tagName"
        v-model:search="tagNameSearch"
        data-refid="assetTagsFormName"
        v-bind="{
          data: tagsNames,
          disabled: isLoading,
          frozen: true,
          idKey: 'key',
          placeholder: 'Tag name',
        }"
        @create="handleTagNameCreate"
        @select="handleTagNameSelect"
      />
    </div>
    <div class="asset-tab__form__input">
      <UIInputEditableDropdown
        ref="tagValueRef"
        v-model="tagValue"
        v-model:search="tagValueSearch"
        data-refid="assetTagsFormValue"
        v-bind="{
          data: tagsValues,
          disabled: isLoading || !tagName,
          frozen: true,
          idKey: 'key',
          placeholder: tagValuePlaceholder,
        }"
        @create="handleTagValueCreate"
        @select="handleTagValueSelect"
      />
    </div>
    <UIButton
      ref="buttonRef"
      data-refid="assetTagsFormAdd"
      v-bind="{
        disabled: isButtonDisabled,
        icon: PlusIcon,
        label: '',
      }"
      @click="handleClickPlus"
    />
  </div>
  <UIGridSkeleton
    v-if="isLoading"
    v-bind="{ collapseWidth, columns, sm }"
    message="Loading tags..."
  />
  <UIGrid
    v-else
    v-model:sort="sort"
    data-refid="assetTagsList"
    v-bind="{ collapseWidth, columns, items, sm }"
  >
    <template v-if="!isReadonly" #actions="{ item, size }">
      <UIButton
        v-if="item"
        v-bind="{ size }"
        label="Delete"
        :icon="TrashIcon"
        variant="light-red"
        @click="handleClickDelete(item)"
      />
    </template>
  </UIGrid>
  <UIRemoveDialog
    v-model="removeDialog"
    v-bind="{
      title: removeDialogTitle,
      message: 'Are you sure you want to remove this tag?',
      label: 'Delete',
    }"
    @remove="handleRemove"
  />
</template>

<script setup lang="ts">
import { computed, inject, nextTick, ref, useTemplateRef, watch } from 'vue'

import { AssetClass } from '../'
import { TagClass } from '@/entities/tags'
import { ReadonlyMode, Sort, Tag } from '@types'

import { ASSET_FIELD } from '../utils/const'
import { TAG_FIELD } from '@/entities/tags/utils/const'
import { READONLY_MODE } from '@/const/common'

import { handleCatchedError, orderedList } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import useGridSort from '@/components/hooks/gridSort'

import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTagsStore } from '@/store/tags'

import { PlusIcon, TrashIcon } from '@heroicons/vue/24/outline'
import {
  UIButton,
  UIGrid,
  UIGridSkeleton,
  UIInputEditableDropdown,
  UIRemoveDialog,
} from '@ui'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

const tagsBunchStore = useTagsBunchStore()
const tagsStore = useTagsStore()

const { error } = useNotifications()

const tagName = ref<string>()
const tagNameSearch = ref<string>()
const tagValue = ref<string>()
const tagValueSearch = ref<string>()

const tagValueRef = useTemplateRef('tagValueRef')
const buttonRef = useTemplateRef('buttonRef')

const removeDialog = ref(false)
const loading = ref(false)

const sort = ref<Sort[]>()

const removedTag = ref<Tag>()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const tagsField = props.instance.field<string[]>(ASSET_FIELD.TAGS)

const isLoading = computed(
  () => loading.value || !tagsStore.initFlag || tagsStore.loadingAction,
)

const tagValuePlaceholder = computed(() =>
  tagName.value ? 'Tag value' : 'Select tag name first',
)

const isButtonDisabled = computed(
  () => isLoading.value || !tagName.value || !tagValue.value,
)

const assetId = computed(() => props.instance.id)
const tagsList = computed(() => tagsBunchStore.getCommonList)

const removeDialogTitle = computed(
  () =>
    `Remove tag '${removedTag.value?.tag_name}: ${removedTag.value?.tag_value}'`,
)

const collapseWidth = 300
const sm = '1fr'
const columns = computed(() => [
  {
    name: 'tag_name',
    caption: 'Tag name',
  },
  {
    name: 'tag_value',
    caption: 'Tag value',
  },
])

const inputItems = computed(() => {
  const result = []
  for (let i = 0; i < tagsField.value.length; i++) {
    const tagId = tagsField.value[i]
    const tagInstance = tagsBunchStore.getList.get(tagId)
    if (!tagInstance) continue
    result.push(tagInstance.get())
  }
  return result
})
const items = useGridSort(sort, columns, inputItems)

const getOriginalValues = (data: string[]) => orderedList([...new Set(data)])

const tagsNames = computed(() => {
  const data = Array.from(tagsList.value)
  const mappedData = data.map(
    ([, instance]) => instance.field<string>(TAG_FIELD.TAG_NAME).value,
  )
  return getOriginalValues(mappedData)
})

const handleTagNameCreate = async (name: string) => {
  tagName.value = name
  await nextTick()
  setTimeout(() => tagValueRef.value?.focus(), 0)
}

const handleTagNameSelect = async () => {
  await nextTick()
  tagValueRef.value?.focus()
}

const handleTagValueCreate = async (value: string) => {
  if (tagName.value) {
    await handleCreate({ value })
  }
}

const handleTagValueSelect = async () => {
  await nextTick()
  buttonRef.value?.focus()
}

const tagsValues = computed(() => {
  if (!tagName.value) return []
  const data = Array.from(tagsList.value)
  const filteredData = data.filter(
    ([, instance]) =>
      !tagName.value ||
      instance.field(TAG_FIELD.TAG_NAME).value === tagName.value,
  )
  const mappedData = filteredData.map(
    ([, instance]) => instance.field<string>(TAG_FIELD.TAG_VALUE).value,
  )
  return getOriginalValues(mappedData)
})

const handleClickDelete = (item: Tag) => {
  removedTag.value = item
  removeDialog.value = true
}

const handleRemove = async () => {
  const id = removedTag.value?.id
  removedTag.value = undefined
  if (!id) return
  loading.value = true
  try {
    tagsField.value = items.value
      .map(item => item.id)
      .filter(item => item !== id)
    await props.instance.update()
  } catch (e) {
    await error({
      message: 'There was a problem with the tag',
    })
    handleCatchedError(e as string, props.instance.get())
  } finally {
    loading.value = false
  }
}

const handleClickPlus = async () => {
  loading.value = true
  try {
    const tagInstance = tagsBunchStore.getElementByPair(
      tagName.value,
      tagValue.value,
    )
    if (tagInstance) {
      await handleAssign(tagInstance)
    } else {
      await error({
        message: 'There is no such tag',
      })
    }
  } catch (e) {
    await error({
      message: 'There was a problem with the tag',
    })
    handleCatchedError(e as string)
  } finally {
    loading.value = false
  }
}

const handleCreate = async (data?: { name?: string; value?: string }) => {
  const tag_name = data?.name || tagName.value
  const tag_value = data?.value || tagValue.value
  if (!tag_name || !tag_value) return
  const tag = tagsBunchStore.createElement()
  tag.set({ tag_name, tag_value, assets: [assetId.value] })
  await tag.store()
  handleAssign(tag)
}

const handleAssign = async (tag: TagClass) => {
  const assetTag = items.value.find(
    item => item.tag_name === tag.field(TAG_FIELD.TAG_NAME).value,
  )
  if (assetTag) {
    await error({
      message: 'A tag with this name already exists',
    })
    return
  }
  tagName.value = undefined
  tagNameSearch.value = undefined
  tagValue.value = undefined
  tagValueSearch.value = undefined
  tagsField.value.push(tag.id)
  await props.instance.update()
}

watch(tagName, () => {
  tagValue.value = undefined
})
</script>

<script lang="ts">
export default {
  name: 'AssetTabTags',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
