<template>
  <div class="ui-timeline">
    <Dropdown
      :shown="isDropdownShown"
      :disabled="!isMobile"
      :triggers="[]"
      :auto-hide="false"
      placement="bottom-end"
      strategy="fixed"
      auto-boundary-max-size
    >
      <div class="ui-timeline__container">
        <UIButton
          v-if="isMobile"
          v-bind="{ icon, label }"
          icon-pos="postfix"
          variant="secondary"
          size="small"
          @click="handleToggle"
        />
        <template v-else>
          <div class="ui-timeline__periods">
            <Periods v-bind="props" ref="periodsRef" v-model="periodsModel" />
            <Freq
              v-bind="props"
              ref="freqRef"
              v-model="freqModel"
              @update="handleUpdatePeriods"
            />
          </div>
          <div class="ui-timeline__label">Periods <br />Ending:</div>
          <End v-bind="props" v-model="endModel" @update="handleUpdateEnd" />
        </template>
      </div>
      <Slider
        v-if="!isMobile && !hideSlider"
        v-model="modelValue"
        v-bind="{ dates }"
      />
      <template v-if="isMobile" #popper>
        <div class="ui-timeline__dropdown">
          <div class="ui-timeline__periods">
            <Periods v-bind="props" v-model="periodsModel" />
            <Freq
              v-bind="props"
              ref="freqRef"
              v-model="freqModel"
              @update="handleUpdatePeriods"
            />
          </div>
          <div class="ui-timeline__container">
            <div class="ui-timeline__label">Periods <br />Ending:</div>
            <End v-bind="props" v-model="endModel" @update="handleUpdateEnd" />
          </div>
          <UIInputDropdown
            v-if="!hideSlider"
            v-model="modelValue"
            v-bind="{ data }"
            simple
          />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue'
import { isEqual, pick } from 'lodash'
import { Dropdown } from 'floating-vue'

import { DataFieldSizes, RepositoryTimeline } from '@types'

import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'

import { getCurrentDate, stringToLocalDateString } from '@/helpers/dates'
import { useResponsive } from '@/plugins/responsiveUI'

import { UIButton, UIInputDropdown } from '..'
import { ChevronDoubleDownIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import End from './components/End.vue'
import Freq from './components/Freq.vue'
import Slider from './components/Slider.vue'
import Periods from './components/Periods.vue'

type Props = {
  timeline: RepositoryTimeline
  dates: string[]
  disabled?: boolean
  size?: DataFieldSizes
  hideSlider?: boolean
}

type Emits = {
  'update:timeline': [data: RepositoryTimeline, isEndChanged?: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()

const { isMobile } = useResponsive()

const freqRef = useTemplateRef('freqRef')

const isInit = ref(true)

const isDropdownShown = ref(false)

const periodsModel = ref<RepositoryTimeline['periods']>()
const freqModel = ref<RepositoryTimeline['freq']>()
const endModel = ref<RepositoryTimeline['end']>()

const icon = computed(() =>
  isDropdownShown.value ? XMarkIcon : ChevronDoubleDownIcon,
)

const data = computed(() => props.dates.map(value => ({ value })))

const currentValue = computed(() => ({
  periods: periodsModel.value || props.timeline.periods || 1,
  freq: freqModel.value || props.timeline.freq || 'day',
  end: endModel.value || props.timeline.end || getCurrentDate({}),
}))

const newTimeline = computed(() => ({
  ...props.timeline,
  ...currentValue.value,
}))

const formattedPeriod = computed(
  () => `${periodsModel.value}${freqModel.value?.at(0)?.toUpperCase()}`,
)

const formattedDate = computed(() =>
  endModel.value
    ? stringToLocalDateString(endModel.value)
    : EMPTY_VALUE_PLACEHOLDER,
)

const label = computed(
  () => `${formattedPeriod.value} / ${formattedDate.value}`,
)

const handleUpdate = (isEndChanged?: boolean) => {
  if (!props.timeline) return
  const timelineValue = pick(props.timeline, 'periods', 'freq', 'end')
  if (isEqual(currentValue.value, timelineValue) && !isEndChanged) return
  emit('update:timeline', newTimeline.value, isEndChanged)
  isDropdownShown.value = false
}

const handleUpdatePeriods = () => {
  handleUpdate()
}

const handleUpdateEnd = () => {
  handleUpdate(true)
}

const initModels = async (data: RepositoryTimeline) => {
  isInit.value = true
  if (Object.keys(data).length === 0) return
  if (periodsModel.value !== data.periods) {
    periodsModel.value = data.periods
  }
  if (freqModel.value !== data.freq) {
    freqModel.value = data.freq
  }
  if (data.end_is_today === true) {
    endModel.value = getCurrentDate({})
  } else if (endModel.value !== data.end) {
    endModel.value = data.end
  }
  await nextTick()
  isInit.value = false
}

const handleToggle = () => {
  isDropdownShown.value = !isDropdownShown.value
}

watch(periodsModel, async () => {
  if (isInit.value) return
  await nextTick()
  setTimeout(() => {
    freqRef.value?.focus()
  })
})

watch(() => props.timeline, initModels, { immediate: true })
</script>

<script lang="ts">
export default {
  name: 'UITimeline',
}
</script>

<style scoped lang="postcss">
.ui-timeline {
  &__container {
    @apply flex items-center;
    @apply gap-2;
  }

  &__periods {
    @apply flex items-center;
    @apply gap-x-2;
  }

  &__label {
    @apply text-xs;
    @apply leading-4;
    @apply text-gray-500 dark:text-gray-400;
    @apply uppercase;
  }

  &__dropdown {
    @apply flex flex-col;
    @apply p-2 gap-4;
  }
}
</style>
