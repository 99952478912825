<template>
  <div v-tooltip="tooltip" class="timeline-slider">
    <div class="timeline-slider__points">
      <div
        v-for="index of max"
        :key="index"
        class="timeline-slider__points__item"
        :class="getPointClasses(index)"
      >
        {{ index }}
      </div>
    </div>
    <UIRangeSlider
      v-if="isRangeVisible"
      v-model="selectedIndex"
      v-bind="params"
      class="timeline-slider__input"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { isTheSameDates, stringToDateTime } from '@/helpers/dates'

import { UIRangeSlider } from '@ui'

type Props = {
  dates: string[]
}

const props = defineProps<Props>()

const modelValue = defineModel<string>()

const selectedIndex = computed({
  get() {
    return getIndex(modelValue.value) + 1
  },
  set(value) {
    updateAnalyticsDate(value - 1)
  },
})

const max = computed(() => props.dates.length)
const isRangeVisible = computed(() => max.value > 1)

const params = computed(() => ({ min: 1, max: max.value }))

const tooltip = computed(() => ({
  content: modelValue.value,
  placement: 'left',
  distance: 10,
  showTriggers: () => ['hover'],
  hideTriggers: () => ['hover'],
}))

const getIndex = (value?: string) => {
  const date = stringToDateTime(value)
  return date
    ? props.dates?.findIndex(item =>
        stringToDateTime(item)?.hasSame(date, 'day'),
      )
    : 0
}

const getPointClasses = (index: number) => ({
  'timeline-slider__points__item--active': index === selectedIndex.value,
  'timeline-slider__points__item--filled': index < selectedIndex.value,
})

const updateAnalyticsDate = (index: number) => {
  const value = props.dates?.[index]
  if (isTheSameDates(modelValue.value, value)) return
  modelValue.value = value
}
</script>

<script lang="ts">
export default {
  name: 'TimelineSlider',
}
</script>

<style lang="postcss">
.timeline-slider {
  @apply relative;
  @apply -mb-2 mt-1;

  &__input {
    @apply absolute inset-0;
  }

  &__points {
    @apply h-3;
    @apply relative;
    @apply flex justify-between;
    @apply pointer-events-none;
    @apply z-10;

    &__item {
      @apply w-5 h-3 flex items-center justify-center;
      @apply bg-gray-200 dark:bg-gray-700;
      @apply text-gray-400 dark:text-gray-300;
      @apply rounded-full;
      @apply text-[0.625rem];
      @apply leading-[1.2];
      @apply font-light;

      &--active {
        @apply bg-indigo-700 dark:bg-indigo-800;
        @apply text-white dark:text-gray-200;
      }

      &--filled {
        @apply bg-indigo-100 dark:bg-gray-500;
      }
    }
  }
}
</style>
