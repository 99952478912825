<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UISlidePanel
      v-bind="{ isMinimized, zIndex }"
      :title="name"
      size="lg"
      @hide="handleClose"
      @minimize="handleMinimize"
    >
      <template #title>
        <AssetSummaryTitle
          v-bind="{ type, name, ticker }"
          :show-date="isAsOfDateShown"
        />
      </template>
      <UITabs v-model="tab" :tabs="tabs" bottom-border>
        <div class="asset-summary__actions">
          <UIButton
            v-if="tab === TABS.TRANSACTIONS"
            label="Go to transactions"
            :size="buttonSize"
            variant="light"
            :full="isMobile"
            :icon="CircleStackIcon"
            @click="handleGoToTransactions"
          />
          <UIButton
            :label="editButtonLabel"
            :icon="editButtonIcon"
            :size="buttonSize"
            :full="isMobile"
            variant="secondary"
            @click="handleClickEditAsset"
          />
        </div>
      </UITabs>
      <div class="asset-summary__tabs" :class="tabClasses">
        <div
          v-if="isInitTab[TABS.TRANSACTIONS]"
          v-show="tab === TABS.TRANSACTIONS"
          class="asset-summary__tab"
        >
          <AssetSummaryTransactions
            v-bind="{ assetId, type }"
            :data="transactionData"
          />
        </div>
        <div
          v-if="isInitTab[TABS.HOLDERS]"
          v-show="tab === TABS.HOLDERS"
          class="asset-summary__tab"
        >
          <AssetSummaryHolders :assets="[assetId]" />
        </div>
        <div
          v-if="isInitTab[TABS.POSITIONS]"
          v-show="tab === TABS.POSITIONS"
          class="asset-summary__tab"
        >
          <AssetSummaryPositions :assets="[assetId]" />
        </div>
        <div
          v-if="isInitTab[TABS.VALUES]"
          v-show="tab === TABS.VALUES"
          class="asset-summary__tab"
        >
          <AssetSummaryValues v-bind="{ assetId, accountId }" />
        </div>
        <div
          v-if="instance && isInitTab[TABS.PRICES]"
          v-show="tab === TABS.PRICES"
          class="asset-summary__tab"
        >
          <AssetSummaryPrices v-bind="{ instance }" />
        </div>
      </div>
    </UISlidePanel>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeMount, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { get } from 'lodash'

import { AssetClass } from '@/entities/assets'
import { ModalClass, ModalState, ReadonlyMode } from '@types'

import { ASSET_FIELD } from '../utils/const'
import { ASSETS_WITH_PRICES_TYPES } from './utils/const'
import { ROUTE_NAME } from '@/const'
import {
  TRANSACTIONS_FILTER_KEY,
  TRANSACTIONS_SEARCH_KEY,
  TRANSACTIONS_SORT_KEY,
} from '@/views/Data/Transactions/utils/const'
import { READONLY_MODE } from '@/const/common'

import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsStore } from '@/store/transactions'

import { useResponsive } from '@/plugins/responsiveUI'

import { UIButton, UITabs } from '@ui'
import { TransitionRoot } from '@headlessui/vue'
import { CircleStackIcon, EyeIcon, PencilIcon } from '@heroicons/vue/24/outline'

import { UISlidePanel } from '@ui'

import AssetSummaryHolders from './AssetSummaryHolders.vue'
import AssetSummaryPositions from './AssetSummaryPositions.vue'
import AssetSummaryPrices from './AssetSummaryPrices.vue'
import AssetSummaryTitle from './AssetSummaryTitle.vue'
import AssetSummaryTransactions from './AssetSummaryTransactions.vue'
import AssetSummaryValues from './AssetSummaryValues.vue'

enum TABS {
  HOLDERS = 'Held by',
  POSITIONS = 'Positions',
  TRANSACTIONS = 'Transactions',
  VALUES = 'Values',
  PRICES = 'Prices',
}

type Props = {
  modal: ModalClass<AssetClass>
  instance: AssetClass
  accountId?: string
  leaf?: boolean
  amount?: number
  path?: string[]
}
const props = defineProps<Props>()

const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()
const transactionsStore = useTransactionsStore()

const router = useRouter()
const { isMobile } = useResponsive()

const type = props.instance.field<string>(ASSET_FIELD.TYPE)
const name = props.instance.field<string>(ASSET_FIELD.NAME)
const ticker = props.instance.field<string>(ASSET_FIELD.TICKER)

const tab = ref<TABS>(TABS.HOLDERS)

const isInitTab = reactive<{ [key in TABS]: boolean }>({
  [TABS.HOLDERS]: false,
  [TABS.POSITIONS]: false,
  [TABS.TRANSACTIONS]: false,
  [TABS.VALUES]: false,
  [TABS.PRICES]: false,
})

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const editButtonLabel = computed(() =>
  isReadonly?.value ? 'View asset' : 'Edit asset',
)
const editButtonIcon = computed(() =>
  isReadonly?.value ? EyeIcon : PencilIcon,
)

const buttonSize = computed(() => (isMobile.value ? 'small' : 'xsmall'))

const showPrices = computed(
  () => type.value && ASSETS_WITH_PRICES_TYPES.includes(type.value),
)

const tabs = computed(() =>
  Object.values(TABS)
    .filter(name => name !== TABS.PRICES || (showPrices.value && props.leaf))
    .filter(name => name !== TABS.POSITIONS || !props.leaf)
    .map(name => ({
      name,
    })),
)

const tabClasses = computed(() => ({
  'asset-summary__tabs--prices': tab.value === TABS.PRICES,
}))

const isAsOfDateShown = computed(() =>
  [TABS.HOLDERS, TABS.POSITIONS, TABS.VALUES].includes(tab.value),
)

const assetId = computed(() => props.instance.id)

const transactionData = computed(() => {
  return !props.path || props.path?.length === 2
    ? {
        account_id: assetId.value,
      }
    : {
        account_id: props.accountId,
        amount: props.amount,
        asset_id: assetId.value,
      }
})

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)
const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)
const zIndex = computed(() => props.modal.zIndex)

const handleClickEditAsset = async () => {
  const modalInstance = modalsStore.init(props.instance.id, props.instance)
  modalInstance?.open(modalsStore.getZIndex(), {
    'hide-search': true,
  })
}

const handleGoToTransactions = () => {
  handleClose()
  const uiSettings =
    repositoriesStore.getCurrentRepository?.user_repo_settings.ui_settings

  const currentSearch = get(uiSettings, TRANSACTIONS_SEARCH_KEY)
  const currentFilter = get(uiSettings, TRANSACTIONS_FILTER_KEY)
  const currentSort = get(uiSettings, TRANSACTIONS_SORT_KEY)

  if (
    currentSearch === name.value &&
    !currentFilter &&
    currentSort.length === 0 &&
    router.currentRoute.value.name === ROUTE_NAME.TRANSACTIONS
  )
    return

  repositoriesStore.updateUISettings(TRANSACTIONS_SEARCH_KEY, name.value)
  repositoriesStore.updateUISettings(TRANSACTIONS_FILTER_KEY, undefined)
  repositoriesStore.updateUISettings(TRANSACTIONS_SORT_KEY, [])
  transactionsStore.clear()
  router.push({ name: ROUTE_NAME.TRANSACTIONS })
}

const handleMinimize = (flag: boolean) => {
  if (flag) {
    props.modal.hide()
  } else {
    props.modal.show(modalsStore.getZIndex())
  }
}

const handleClose = () => {
  props.modal.close()
}

watch(
  tab,
  value => {
    isInitTab[value] = true
  },
  { immediate: true },
)

onBeforeMount(() => {
  tab.value = tabs.value[0].name
})
</script>

<script lang="ts">
export default {
  name: 'AssetSummary',
}
</script>

<style lang="postcss">
.asset-summary {
  &__actions {
    @apply sm:flex sm:items-center;
    @apply py-2 px-4 sm:p-0 sm:ml-auto;
    @apply space-y-2 sm:space-y-0 sm:space-x-2;
  }

  &__tabs {
    @apply flex flex-col flex-auto;
    @apply h-full mt-4;

    .ui-grid--wrapped {
      @apply lg:-mx-6;
    }
  }

  &__tab {
    @apply w-full;
    @apply flex flex-col flex-auto;
  }
}
</style>
