<template>
  <Block v-bind="{ title }" v-model="toggleValue">
    <component :is="component" v-if="toggleValue" v-model="modelValue" />
  </Block>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'

import { TransactionRule, TransactionRuleActionRuleType } from '@types'

import { ACTION_TYPE_TITLE } from './utils/const'

import Block from './components/Block.vue'
import ActionAccount from './components/ActionAccount.vue'
import ActionCategory from './components/ActionCategory.vue'
import ActionCounterparty from './components/ActionCounterparty.vue'
import ActionSign from './components/ActionSign.vue'
import ActionSubcategory from './components/ActionSubcategory.vue'
import ActionTag from './components/ActionTag.vue'

type Props = {
  name: TransactionRuleActionRuleType
}

const props = defineProps<Props>()

const modelValue = defineModel<TransactionRule['data']['actions'][number]>({
  default: {
    type: undefined,
    value: undefined,
    tag_ids: undefined,
  },
})

const toggleValue = ref(false)

const title = computed(
  () => ACTION_TYPE_TITLE[props.name as keyof typeof ACTION_TYPE_TITLE],
)

const component = computed(() => {
  switch (props.name) {
    case TransactionRuleActionRuleType.ADD_TAG:
      return ActionTag
    case TransactionRuleActionRuleType.SET_CATEGORY:
      return ActionCategory
    case TransactionRuleActionRuleType.SET_SUB_CATEGORY:
      return ActionSubcategory
    case TransactionRuleActionRuleType.SET_COUNTERPARTY:
      return ActionCounterparty
    case TransactionRuleActionRuleType.SET_SIGN:
      return ActionSign
    default:
      return ActionAccount
  }
})

watch(toggleValue, value => {
  if (value) return
  modelValue.value.value = undefined
  modelValue.value.tag_ids = undefined
})

onBeforeMount(() => {
  toggleValue.value =
    modelValue.value.value !== undefined ||
    modelValue.value.tag_ids !== undefined
})

defineOptions({
  name: 'LinkedDataRulesSettingsAction',
})
</script>
