<template>
  <nav class="app-breadcrumb" aria-label="Breadcrumb">
    <ol class="app-breadcrumb__list">
      <BreadcrumbItem
        v-for="item in items"
        v-bind="{ item }"
        :key="item.path.join('_')"
        @click:item="handleClickItem"
      />
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { get, omit } from 'lodash'

import { AnalyticsPreparedTree } from '@/store/analytics/tree/utils/types'

import { Breadcrumb, BreadcrumbChild } from './utils/type'

import BreadcrumbItem from './BreadcrumbItem.vue'

type Props = {
  tree?: AnalyticsPreparedTree
  path: string[]
}

type Emits = {
  'click:item': [data: string[]]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const items = computed(() => {
  if (!props.tree) return []
  const result: Breadcrumb[] = []
  for (let i = 0; i < props.path.length; i++) {
    const branch = props.path.slice(0, i + 1)
    const assetId = props.path[i]
    if (!assetId) continue
    const { path, ...assets } = omit(
      get(props.tree, branch),
      'amount',
      'balance',
    )
    const keys = Object.keys(assets)
    const children = keys.reduce((acc, assetId) => {
      const asset = assets[assetId]
      const children = omit(asset, 'amount', 'balance', 'path')
      if (Object.keys(children).length) {
        acc.push({
          assetId,
          path: asset.path,
        })
      }
      return acc
    }, [] as BreadcrumbChild[])
    result.push({
      assetId,
      path,
      children,
    })
  }
  return result
})

const handleClickItem = (path: string[]) => {
  if (JSON.stringify(path) === JSON.stringify(props.path)) return
  emit('click:item', path)
}
</script>

<script lang="ts">
export default {
  name: 'AppBreadcrumb',
}
</script>

<style lang="postcss">
.app-breadcrumb {
  @apply flex flex-auto;
  @apply py-2 mr-4;
  @apply overflow-x-auto;

  &__list {
    @apply flex items-center;
    @apply space-x-3;
    @apply whitespace-nowrap;
  }
}
</style>
