import { ref } from 'vue'
import { useTimeoutFn } from '@vueuse/core'

import { WS_STATUS } from './utils/enums'
import {
  WSAction,
  WSChunks,
  WSData,
  WSMessage,
  WSPostponed,
  WSService,
  WSSubscribeCallback,
} from './utils/types'

import { RECONNECT_DELAY } from './utils/const'

import { getChunkKey, getKey } from './utils/helpers'

import faro from '@/services/faro'

export default (url: string) => {
  const socket = ref<WebSocket>()
  const lastToken = ref<string>()
  const fatalErrorCallback = ref<() => Promise<void>>()

  const status = ref<WS_STATUS>(WS_STATUS.CONNECTING)

  const subscribes = ref<Record<string, WSSubscribeCallback<any>[]>>({})

  const chunks = ref<WSChunks>({})

  const sent = ref<Map<string, WSPostponed>>(new Map())
  const postponed = ref<WSPostponed[]>([])

  const authorizedClosure = ref(false)

  const { start: startReconnect, stop: stopReconnect } = useTimeoutFn(
    () => {
      connect()
    },
    RECONNECT_DELAY,
    { immediate: false },
  )

  const connect = (
    token = lastToken.value,
    errorCallback?: () => Promise<void>,
  ) => {
    return new Promise(resolve => {
      if (errorCallback) fatalErrorCallback.value = errorCallback

      if (!token) {
        stopReconnect()
        resolve(false)
        return
      }
      status.value = WS_STATUS.CONNECTING
      lastToken.value = token

      try {
        socket.value = new WebSocket(`${url}?token=${token}`)
      } catch {
        faro.log.error(['WS can`t connect'])
      }

      if (!socket.value) {
        resolve(false)
        return
      }

      socket.value.onerror = (e: any) => {
        status.value = WS_STATUS.ERROR
        stopReconnect()
        faro.log.error(['WS returned an error'], { error: JSON.stringify(e) })
        reset()
        fatalErrorCallback.value?.()
      }

      socket.value.onopen = () => {
        status.value = WS_STATUS.OPEN
        stopReconnect()
        if (!postponed.value.length) {
          resolve(true)
          return
        }
        postponed.value.forEach(({ service, action, request_id, data }) => {
          send(service, action, request_id, data)
        })
        postponed.value = []
        resolve(true)
      }

      socket.value.onclose = () => {
        status.value = WS_STATUS.CLOSED
        stopReconnect()
        if (authorizedClosure.value) {
          reset()
          return
        }
        faro.log.error(['WS disconnected'])
        postponed.value = Array.from(sent.value.values())
        sent.value.clear()
        startReconnect()
      }

      socket.value.onmessage = function (
        this: WebSocket,
        event: MessageEvent<string>,
      ) {
        let message: WSMessage
        try {
          message = JSON.parse(event.data)
        } catch {
          return
        }
        const {
          service,
          action,
          request_id,
          chunk_data,
          chunk_number,
          total_chunks,
          ...data
        } = message
        if (!chunk_data || !chunk_number || !total_chunks) {
          const key = getKey(service, action)
          if (subscribes.value[key]?.length) {
            subscribes.value[key].forEach(callback => {
              callback({ data }, request_id)
            })
          }
          return
        }
        const key = getKey(service, action)
        const chunkKey = getChunkKey(key, request_id)
        let mergedData: string
        if (total_chunks === 1) {
          mergedData = chunk_data
        } else if (chunk_number < total_chunks) {
          chunks.value[chunkKey][chunk_number - 1] = chunk_data
          return
        } else {
          chunks.value[chunkKey][chunk_number - 1] = chunk_data
          mergedData = chunks.value[chunkKey].join('')
        }
        delete chunks.value[chunkKey]
        sent.value.delete(request_id)
        if (subscribes.value[key]?.length) {
          subscribes.value[key].forEach(callback => {
            const data =
              typeof mergedData === 'string'
                ? JSON.parse(mergedData)
                : mergedData
            callback(data, request_id)
          })
        }
      }
    })
  }

  const disconnect = () => {
    status.value = WS_STATUS.CLOSED
    stopReconnect()
    const params = {
      service: 'general',
      action: 'disconnect',
    }
    socket.value?.send(JSON.stringify(params))
    authorizedClosure.value = true
    socket.value?.close()
  }

  const send = (
    service: WSService,
    action: WSAction,
    request_id: string,
    data: WSData<any>,
  ) => {
    const params = {
      service,
      action,
      request_id,
      data,
    }
    if (status.value !== WS_STATUS.OPEN) {
      postponed.value.push(params)
      return
    } else {
      sent.value.set(request_id, params)
    }
    const key = getKey(service, action)
    const chunkKey = getChunkKey(key, request_id)
    chunks.value[chunkKey] = []
    socket.value?.send(JSON.stringify(params))
  }

  const subscribe = (
    service: WSService,
    action: WSAction,
    callback: WSSubscribeCallback<any>,
  ) => {
    const key = getKey(service, action)
    if (!subscribes.value[key]) {
      subscribes.value[key] = []
    }
    subscribes.value[key].push(callback)
  }

  const reset = () => {
    lastToken.value = undefined
    status.value = WS_STATUS.CONNECTING
    subscribes.value = {}
    chunks.value = {}
    sent.value = new Map()
    postponed.value = []
  }

  return {
    connect,
    disconnect,
    close,
    send,
    subscribe,
    status,
  }
}
