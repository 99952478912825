<template>
  <AppSidebar
    v-model:is-open="isOpen"
    :is-short-view="!repositoriesStore.currentRepositoryId"
  >
    <MainTree />
  </AppSidebar>
</template>

<script setup lang="ts">
import { computed, inject, watch, WritableComputedRef } from 'vue'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'

import { AppSidebar } from '@app'

import MainTree from './MainTree.vue'

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()

const sidebarOpen = inject<WritableComputedRef<boolean, boolean>>('sidebarOpen')

const isOpen = computed({
  get() {
    return !!sidebarOpen?.value && !!repositoriesStore.currentRepositoryId
  },
  set(value) {
    sidebarOpen && (sidebarOpen.value = !!value)
  },
})

watch(
  () => sidebarOpen?.value,
  value => {
    if (value) {
      analyticsStore.setActive('MainSidebar', ['tree'])
    } else {
      analyticsStore.setInActive('MainSidebar')
    }
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'MainSidebar',
}
</script>
