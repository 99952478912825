import { UserPlanName } from './enums'

export const CUSTOM_ALLPOSIT_UUID_KEY = 'custom:allposit_uuid'
export const CUSTOM_PASSWORD_PROVIDED = 'custom:password_provided'

export const PLAN_FEATURES = {
  [UserPlanName.STANDARD]: [
    'one repo',
    'limited transactions types',
    'limited analytics widgets',
  ],
  [UserPlanName.PROFESSIONAL]: [
    '10 repos',
    'all transaction types',
    'all analytics widgets',
  ],
}
