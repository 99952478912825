<template>
  <div class="ui-prices-chart">
    <div v-if="lastDate" class="ui-prices-chart__actions">
      <slot />
      <UIButton
        v-for="range in RANGES"
        :key="range"
        :label="range"
        :variant="buttonVariant(range)"
        size="xxsmall"
        type="button"
        @click="handleChangeRange(range)"
      />
    </div>
    <EChart
      ref="chartRef"
      v-bind="{ option, selectedIndex }"
      @click:item="handleClickItem"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, useTemplateRef, watch } from 'vue'
import { DateTime } from 'luxon'
import { useLocalStorage } from '@vueuse/core'

import { EChartOption } from 'echarts'
import { ChartPricesRange } from './utils/enums'

import { BLUR_DIGITAL_VALUES } from '@/const/storage'

import { stringToDateTime } from '@/helpers/dates'

import EChart from './EChart.vue'
import { UIButton } from '@ui'

const RANGES = Object.values(ChartPricesRange)

type Props = {
  prices: EChartOption.SeriesLine[]

  nameFormatter?: (data: string) => string
  valueFormatter?: (data: number, fractionDigits?: number) => string
}

const props = defineProps<Props>()

const selectedIndex = defineModel<number>('index')
const currentRange = defineModel<ChartPricesRange>('range', {
  default: ChartPricesRange.YEAR,
})

const blurDigitalValues = useLocalStorage(BLUR_DIGITAL_VALUES, false)

const chartRef = useTemplateRef('chartRef')

const instance = computed(() => chartRef.value?.getInstance())

const option = computed(() => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      formatter: (
        params: EChartOption.Tooltip.Format | EChartOption.Tooltip.Format[],
      ) => {
        let date: string = ''
        const result = (params as EChartOption.Tooltip.Format[]).map(param => {
          const { color, name, seriesName, value } =
            param as EChartOption.Tooltip.Format
          if (!date) date = `${name}`
          const caption = props.nameFormatter?.(seriesName || '') || seriesName
          const amount = (value as any)?.[1] || 0
          const formattedValue = props.valueFormatter?.(amount) || amount
          return `<div style="color:${color}">${caption}: <span class="blurable-number">${formattedValue}</span></div>`
        })
        return `${date}${result.join('')}`
      },
    } as EChartOption.BaseTooltip,
    xAxis: {
      type: 'time',
      axisLabel: {
        interval: 0,
        lineHeight: 36,
        margin: 12,
        rotate: 45,
      },
    } as EChartOption.XAxis,
    yAxis: {
      position: 'right',
      scale: true,
      axisLabel: {
        formatter: (value: number) => {
          let fractionDigits = 0

          if (value < 5) fractionDigits = 1
          if (value < 1) fractionDigits = 2
          if (value <= 0.4) fractionDigits = Math.round(1 / value) - 1
          if (fractionDigits > 6) fractionDigits = 6

          return blurDigitalValues.value
            ? '? ??? ???'
            : props.valueFormatter?.(value, fractionDigits) || value
        },
      },
    } as EChartOption.YAxis,
    series: [
      {
        type: 'scatter',
        datasetIndex: 0,
      },
      ...props.prices.map((price, index) => ({
        type: 'line',
        showSymbol: false,
        ...price,
        datasetIndex: index + 1,
      })),
    ],
    grid: {
      height: '100%',
      top: '2%',
      right: '0%',
      bottom: '0%',
      left: '0%',
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'inside',
        realtime: true,
        zoomOnMouseWheel: false,
      },
    ],
  }
})

const lastDate = computed(() =>
  DateTime.max(
    ...(props.prices
      .filter(item => item.data?.length)
      .map(item => {
        const el = item.data?.at(-1)
        return stringToDateTime((el as any).value[0])
      }) as DateTime[]),
  ),
)

const buttonVariant = (range: ChartPricesRange) =>
  currentRange.value === range ? 'secondary' : 'light'

const handleClickItem = (index: number) => {
  selectedIndex.value = index
}

const handleChangeRange = (range: ChartPricesRange) => {
  currentRange.value = range
}

watch(
  () => [currentRange.value, instance.value, props.prices],
  async ([range, instance]) => {
    if (!instance) return
    let startValue
    let start
    switch (range) {
      case ChartPricesRange.WEEK:
        startValue = lastDate.value.minus({ weeks: 1 })
        break
      case ChartPricesRange.MONTH:
        startValue = lastDate.value.minus({ months: 1 })
        break
      case ChartPricesRange.MONTH6:
        startValue = lastDate.value.minus({ months: 6 })
        break
      case ChartPricesRange.YTD:
        startValue = lastDate.value.startOf('year')
        break
      case ChartPricesRange.YEAR:
        startValue = lastDate.value.minus({ years: 1 })
        break
      case ChartPricesRange.YEAR5:
        startValue = lastDate.value.minus({ years: 5 })
        break
      default:
        start = 0
        break
    }
    await nextTick()
    // @ts-ignore
    instance.dispatchAction({
      type: 'dataZoom',
      startValue,
      start,
    })
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'UIPricesChart',
}
</script>

<style lang="postcss">
.ui-prices-chart {
  @apply h-full;
  @apply relative;
  @apply flex flex-col;
  @apply gap-1;

  &__actions {
    @apply flex items-center;
    @apply gap-2;
    @apply z-10;
  }

  .e-chart {
    @apply flex-auto;
  }
}
</style>
