import { FilterComparison } from './enums'
import { Filter, FilterGroup, FilterParam } from './types'

import { generateRandomKey } from '../components/utils/helpers'

const isActiveParam = (param: FilterParam) => {
  const { comparison, value } = param
  return (
    !!value ||
    (comparison &&
      [FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(comparison))
  )
}

const isActiveGroup = (group: FilterGroup) => !!group.params.length

export const getActiveFilters = (storage: Filter) => {
  const groups = storage?.params
    ?.map(param => {
      const params = param.params
        .filter(isActiveParam)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ key, ...item }) => item)
      return {
        params,
        logic: param.logic,
      }
    })
    .filter(isActiveGroup)
  const logic = storage?.logic
  return groups?.length
    ? ({
        params: groups,
        logic,
      } as Filter)
    : undefined
}

export const normalizeOldData = (data: any) => {
  if (!data) return
  if (!Array.isArray(data.params)) {
    return {
      logic: data.logic,
      params: [
        {
          key: generateRandomKey(),
          logic: data.logic,
          params: [
            {
              ...data.params,
              key: generateRandomKey(),
            },
          ],
        },
      ],
    }
  } else if (data.params?.[0]?.field) {
    return {
      logic: data.logic,
      params: (data.params as any[]).map(param => ({
        key: generateRandomKey(),
        logic: data.logic,
        params: [
          {
            ...param,
            key: generateRandomKey(),
          },
        ],
      })),
    }
  } else {
    return data
  }
}
