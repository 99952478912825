<template>
  <TransactionFormAsset
    ref="fieldRef"
    v-bind="{ ...$attrs, instance }"
    :entry-index="2"
  />
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'
import { TransactionClass } from '..'

import TransactionFormAsset from './TransactionFormAsset.vue'

type Props = {
  instance: TransactionClass
}

defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = useTemplateRef('fieldRef')
</script>

<script lang="ts">
export default {
  name: 'TransactionFormAsset3',
}
</script>
