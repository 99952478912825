<template>
  <component
    :is="isCollapsed ? 'div' : EmptyWrapper"
    class="ui-grid__row"
    :class="rowClasses"
    :style="rowStyles"
  >
    <RowCell
      v-if="selectable"
      v-bind="{ active, editRow, isCollapsed, item }"
      is-first
      @click:menu="handleClickMenu"
    >
      <slot name="checkbox" v-bind="{ active, item }">
        <Checkbox
          ref="checkboxRef"
          v-model="selected"
          :true-value="[]"
          :value="itemKey"
        />
      </slot>
      <template #actions="slotProps">
        <slot name="actions" v-bind="slotProps" />
      </template>
    </RowCell>
    <RowCell
      v-for="(column, index) in columns"
      :key="column.name"
      ref="cellRef"
      v-bind="{ active, column, editRow, isCollapsed, item }"
      :is-first="!selectable && index === 0"
      :is-last="index === props.columns.length - 1"
      :edit-cell="(edit && index === edit.cell) || undefined"
      @click="handleClickCell"
      @dblclick="handleDblClickCell"
      @click:menu="handleClickMenu"
    >
      <template #actions="slotProps">
        <slot name="actions" v-bind="slotProps" />
      </template>
      <template #default="slotProps">
        <slot
          v-if="$slots[getSlotName(column.name, 'cell')]"
          :name="getSlotName(column.name, 'cell')"
          v-bind="slotProps"
        />
        <slot v-else name="cell" v-bind="slotProps" />
      </template>
    </RowCell>
  </component>
</template>

<script setup lang="ts" generic="T">
import { computed, inject, provide, ref, useTemplateRef } from 'vue'

import { GridColumn, GridEdit } from '../utils/types'

import { getSlotName } from '../utils/helpers'

import EmptyWrapper from './EmptyWrapper.vue'
import RowCell from './RowCell.vue'
import Checkbox from './Checkbox.vue'

type Props = {
  columns: GridColumn[]
  item: T
  itemKey: string | number

  active: boolean

  isCollapsed?: boolean

  editRow?: boolean

  edit?: GridEdit

  template?: string
}

type Emits = {
  'click:row': []
  'dblclick:cell': []
  'click:menu': [data: T]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const selected = defineModel<(string | number)[]>('selected')

const cellRef = useTemplateRef<any[]>('cellRef')
const checkboxRef = useTemplateRef('checkboxRef')

defineExpose({
  getCheckableItemKey() {
    return checkboxRef.value && props.itemKey
  },
  paste(index: number, data: string | number, check?: boolean) {
    if (!cellRef.value?.[index]) return
    cellRef.value[index].paste(data, check)
  },
})

const selectable = inject('selectable', false)

const isHovered = ref(false)
provide('isHovered', isHovered)

const rowStyles = computed(
  () => !props.isCollapsed && `grid-template-columns: ${props.template}`,
)

const checked = computed(() => selected.value?.includes(props.itemKey))
provide('isChecked', checked)

const rowClasses = computed(() => ({
  'ui-grid__row--highlighted': props.active,
}))

const handleClickCell = () => {
  emit('click:row')
}

const handleDblClickCell = () => {
  emit('dblclick:cell')
}

const handleClickMenu = () => {
  emit('click:menu', props.item)
}
</script>

<script lang="ts">
export default {
  name: 'GridRow',
}
</script>
<style scoped lang="postcss">
.ui-grid__row {
  @apply relative;
  @apply my-2 py-2;
  @apply bg-white dark:bg-gray-800;
  @apply border border-gray-200 dark:border-gray-600;
  @apply rounded-md;

  &--highlighted {
    @apply !bg-indigo-50 dark:!bg-indigo-950;

    :deep() .ui-grid__caption {
      @apply bg-indigo-100 dark:bg-indigo-950;
    }
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__row {
    @apply grid content-start;
  }
}
</style>
