<template>
  <UILabeledField
    ref="fieldRef"
    v-model="modelValue"
    v-bind="{
      ...$attrs,
      isAlwaysNegative,
      isAlwaysPositive,
      silentError,
      type,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef } from 'vue'

import { TransactionSettings } from '@types'

import { NEGATIVE_RULES, POSITIVE_RULES } from '@/helpers/validate'

import { UILabeledField } from '@ui'

type Props = {
  settings?: TransactionSettings['entries'][number]['amount']
}

const props = defineProps<Props>()

const modelValue = defineModel<number | null>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = useTemplateRef('fieldRef')

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const silentError = computed(() => !isSavedTransaction.value)

const type = computed(() =>
  props.settings?.rule === 'percent' ? 'percent' : 'number',
)

const isAlwaysNegative = computed(() => {
  const rule = props.settings?.rule
  return !!rule && NEGATIVE_RULES.includes(rule)
})

const isAlwaysPositive = computed(() => {
  const rule = props.settings?.rule
  return !!rule && POSITIVE_RULES.includes(rule)
})
</script>

<script lang="ts">
export default {
  name: 'TransactionAmountField',
}
</script>
