<template>
  <div class="welcome-screen">
    <WelcomeScreenRepositories v-if="isEmptyApp" />
    <WelcomeScreenAnalytics v-else-if="isEmptyAnalytics" />
    <WelcomeScreenConnectors v-else-if="isEmptyConnectors" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

import useAnalyticsStore from '@/store/analytics'

import WelcomeScreenAnalytics from './WelcomeScreenAnalytics.vue'
import WelcomeScreenConnectors from './WelcomeScreenConnectors.vue'
import WelcomeScreenRepositories from './WelcomeScreenRepositories.vue'

type Props = {
  isEmptyApp?: boolean
  isEmptyAnalytics?: boolean
  isEmptyConnectors?: boolean
}

defineProps<Props>()

const analyticsStore = useAnalyticsStore()

onMounted(() => {
  analyticsStore.setActive('WelcomeScreen', ['tree'])
})

onUnmounted(() => {
  analyticsStore.setInActive('WelcomeScreen')
})
</script>

<style lang="postcss">
.welcome-screen {
  @apply flex flex-auto items-center justify-center;
}
</style>
