export const PAGE_SIZE = 20
export const ASSET_TRANSACTIONS_PAGE_SIZE = 10

export const OVERLAY_DURATION = 500
export const NOTIFICATION_DELAY = 5000
export const ALERT_DELAY = 10000
export const DEBOUNCE_DELAY = 300
export const THROTTLE_DELAY = 500
export const SUCCESS_MESSAGE_DELAY = 1000

export const FRACTION_DIGITS = 2
export const FRACTION_DIGITS_MAX = 10
export const FRACTION_DIGITS_PRICE_MAX = 5

export const ENV_SHORT_NAMES = {
  development: 'dev',
  local: 'lcl',
  production: 'prod',
  staging: 'stg',
  testing: 'test',
}

export const ASSET_ID_PREFIX = 'asst_'
export const DOCUMENT_ID_PREFIX = 'doc_'
export const TRANSACTION_ID_PREFIX = 'txn_'
export const TAG_ID_PREFIX = 'atag_'
export const CONTACT_ID_PREFIX = 'acnt_'
export const REPOSITORY_ID_PREFIX = 'repo_'

export const UNKNOWN_VALUE = 'Unknown'
export const LOADING_TEXT = 'Loading...'

export const IS_PROD_MODE = 'isProdMode'
export const OWNER_MODE = 'ownerMode'
export const READONLY_MODE = 'readonlyMode'
export const READWRITE_MODE = 'readWriteMode'

export const CSV_SEPARATOR = ','

export const SIZES = [
  'default',
  'small',
  'xsmall',
  'xxsmall',
  'large',
  'xlarge',
]

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

export enum GridItemState {
  DELETED = 'delete',
  INVALID = 'invalid',
  NEW = 'add',
  CHANGED = 'update',
  SAVED = 'saved',
  ERRORS = 'errors',
}

export const SEARCH_TAG_ALL = 'All'
export const SEARCH_TAG_TRANSACTIONS = 'Transactions'

export const MIN_DATE_YEAR = 1970

export const TEXT_ANALYTICS_CALCULATING = 'Analytics calculating...'

export const EMPTY_VALUE_PLACEHOLDER = '---'
