import { DashboardWidgetName } from './emits'
import { DashboardWidgetParams } from './types'

import {
  Bars4Icon,
  ChartBarIcon,
  ChartPieIcon,
  EqualsIcon,
  QueueListIcon,
  RectangleGroupIcon,
  Squares2X2Icon,
  TableCellsIcon,
} from '@heroicons/vue/24/outline'

export const WIDGETS_LIST: DashboardWidgetParams[] = [
  {
    label: 'Linked Transactions',
    name: DashboardWidgetName.LinkedTransactions,
    section: 'Linked Data',
    icon: Bars4Icon,
  },
  {
    label: 'Linked Transactions Pivot',
    name: DashboardWidgetName.LinkedTransactionsPivot,
    section: 'Linked Data',
    icon: RectangleGroupIcon,
  },
  {
    label: 'Accounts By Type',
    name: DashboardWidgetName.AccountsByType,
    section: 'Value',
    icon: QueueListIcon,
  },
  {
    label: 'Value Pie Chart',
    name: DashboardWidgetName.ValueHistoryPieChart,
    section: 'Value',
    icon: ChartPieIcon,
  },
  {
    label: 'Value Table',
    name: DashboardWidgetName.ValueHistoryTable,
    section: 'Value',
    icon: TableCellsIcon,
  },
  {
    label: 'Value History',
    name: DashboardWidgetName.ValueHistoryBarsChart,
    section: 'Value',
    icon: ChartBarIcon,
  },
  {
    label: 'Value Pivot',
    name: DashboardWidgetName.ValuePivot,
    section: 'Value',
    icon: RectangleGroupIcon,
  },
  {
    label: 'Income Table',
    name: DashboardWidgetName.IncomeHistoryTable,
    section: 'Income',
    icon: TableCellsIcon,
  },
  {
    label: 'Income History',
    name: DashboardWidgetName.IncomeHistoryBarsChart,
    section: 'Income',
    icon: ChartBarIcon,
  },
  {
    label: 'Income Pivot',
    name: DashboardWidgetName.IncomePivot,
    section: 'Income',
    icon: RectangleGroupIcon,
  },
  {
    label: 'Performance Pivot',
    name: DashboardWidgetName.PerformancePivot,
    section: 'Other Analytics',
    icon: RectangleGroupIcon,
  },
  {
    label: 'Commitments',
    name: DashboardWidgetName.Commitments,
    section: 'Other Analytics',
    icon: TableCellsIcon,
  },
  {
    label: 'Value Change Snapshot',
    name: DashboardWidgetName.ValueChangeSnapshot,
    section: 'Mobile',
    icon: Squares2X2Icon,
  },
  {
    label: 'Income Change Snapshot',
    name: DashboardWidgetName.IncomeChangeSnapshot,
    section: 'Mobile',
    icon: Squares2X2Icon,
  },
  {
    label: 'Section Delimiter',
    name: DashboardWidgetName.SectionDelimiter,
    section: 'Additional',
    icon: EqualsIcon,
  },
]

export const KEY_SEPARATOR = '/'

export const CHART_MAX_SLICES = 7
