/* eslint-disable no-case-declarations */
import {
  ComputedRef,
  Ref,
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue'
import { onClickOutside, useThrottleFn } from '@vueuse/core'

type SelectionRange = { start?: number; end?: number }

const useSelectionService = (
  target: Ref<HTMLDivElement | null>,
  shift = 0,
  disabled?: ComputedRef<boolean>,
  callback?: () => void,
) => {
  const selectionStarted = ref(false)

  const range = reactive<SelectionRange>({
    start: undefined,
    end: undefined,
  })

  const hasSelected = computed(() => range.start !== undefined)

  const getCell = (target: HTMLDivElement) => {
    if (target.hasAttribute('[data-cell]')) {
      return target
    } else {
      return target.closest('[data-cell]') as HTMLDivElement
    }
  }

  const getCellIndex = (target: EventTarget) => {
    const cell = getCell(target as HTMLDivElement)
    if (cell === null) return
    const parent = cell?.parentElement
    if (!parent) return 0
    return Array.prototype.indexOf.call(
      parent.querySelectorAll('[data-cell]'),
      cell,
    )
  }

  const getGridElementColsCount = (target: Element) => {
    const computerStyle = window.getComputedStyle(target)
    return (
      computerStyle.getPropertyValue('grid-template-columns').split(' ')
        .length - shift
    )
  }

  const markAsSelected = (item?: Element) => {
    item?.setAttribute('data-selected', 'true')
  }

  const markSelectedAsCopied = () => {
    const items = target.value?.querySelectorAll('[data-selected]')
    if (!items) return
    for (let i = 0; i < items.length; i++) {
      items[i].setAttribute('data-copy', 'true')
    }
  }

  const resetSelectedCells = () => {
    const items = target.value?.querySelectorAll('[data-selected]')
    if (!items) return
    for (let i = 0; i < items.length; i++) {
      items[i].removeAttribute('data-selected')
      items[i].removeAttribute('data-copy')
    }
  }

  const updateRange = (range: SelectionRange) => {
    const gridCols = target.value ? getGridElementColsCount(target.value) : 0
    let rangeStart = Number(range.start) + 1
    let rangeEnd = Number(range.end) + 1
    const startTrace = rangeStart % gridCols
    let startRow = (rangeStart - startTrace) / gridCols + Number(startTrace > 0)
    const endTrace = rangeEnd % gridCols
    let endRow = (rangeEnd - endTrace) / gridCols + Number(endTrace > 0)
    if (startRow > endRow) {
      const tmp = rangeStart
      rangeStart = rangeEnd
      rangeEnd = tmp
      const rowTmp = startRow
      startRow = endRow
      endRow = rowTmp
    }
    const startCol = rangeStart - gridCols * (startRow - 1)
    const endCol = rangeEnd - gridCols * (endRow - 1)
    if (startCol > endCol) {
      const shift = startCol - endCol
      rangeStart -= shift
      rangeEnd += shift
    }
    const startShift = rangeStart % gridCols || gridCols
    const endShift = rangeEnd % gridCols || gridCols
    const cols = endShift - startShift + 1
    return {
      rangeStart: rangeStart - 1,
      rangeEnd: rangeEnd - 1,
      cols,
      step: gridCols - cols,
    }
  }

  const selectCells = (range: SelectionRange) => {
    resetSelectedCells()
    if (!target.value || range.start === undefined || range.end === undefined)
      return
    if (range.start === range.end) {
      const items = target.value?.querySelectorAll('[data-cell]')
      markAsSelected(items[range.start])
    } else if (range.end !== undefined) {
      const items = target.value?.querySelectorAll('[data-cell]')
      const { rangeStart, rangeEnd, cols, step } = updateRange(range)
      let shift = rangeStart + cols
      for (let i = rangeStart; i <= rangeEnd; i++) {
        if (i == shift) {
          i += step
          shift += step + cols
        }
        markAsSelected(items[i])
      }
    }
  }

  const handleMouseDown = (e: MouseEvent) => {
    callback?.()
    const isCtrl = e.metaKey || e.ctrlKey
    if (!e.target || isCtrl || !getCell(e.target as any)) return
    if (e.shiftKey) {
      range.end = getCellIndex(e.target)
      return
    }
    range.start = getCellIndex(e.target)
    range.end = undefined
    selectionStarted.value = true
  }

  const handleMouseUp = (e: MouseEvent) => {
    const isCtrl = e.metaKey || e.ctrlKey
    if (!e.target || isCtrl || !selectionStarted.value) return
    range.end = getCellIndex(e.target)
    selectionStarted.value = false
  }

  const handleMouseMove = useThrottleFn((e: MouseEvent) => {
    if (!e.target || !selectionStarted.value) return
    range.end = getCellIndex(e.target)
  }, 200)

  const clear = () => {
    resetSelectedCells()
  }

  const reset = () => {
    clear()
    range.start = undefined
    range.end = undefined
  }

  const convertSelectedToRows = () => {
    const rows: string[][] = []
    const items = target.value?.querySelectorAll('[data-selected]')
    if (!items) return
    let currentRow = 0
    let prevOffset = 0
    for (let i = 0; i < items.length; i++) {
      const cell = items[i] as HTMLDivElement
      if (i === 0) {
        prevOffset = cell.offsetTop
      } else if (prevOffset !== cell.offsetTop) {
        prevOffset = cell.offsetTop
        currentRow++
      }
      if (!rows[currentRow]) rows[currentRow] = []
      rows[currentRow].push(items[i].textContent || '')
    }
    return rows
  }

  const copySelected = () => {
    markSelectedAsCopied()
    const rows = convertSelectedToRows()
    if (!rows) return
    const html = rows.map(row => `<tr><td>${row.join('</td><td>')}</td></tr>`)
    const resultHtml = `<table>${html.join('')}</table>`
    const plain = rows.map(row => row.join('\t'))
    const resultPlain = plain.join('\r\n')
    const blobHTML = new Blob([resultHtml], { type: 'text/html' })
    const blobPlain = new Blob([resultPlain], { type: 'text/plain' })
    navigator.clipboard.write([
      new ClipboardItem({
        [blobHTML.type]: blobHTML,
        [blobPlain.type]: blobPlain,
      }),
    ])
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (disabled?.value || range.start === undefined) return
    const isCtrl = e.metaKey || e.ctrlKey
    const isShift = e.shiftKey
    const pos = range.end ? Math.min(range.start, range.end) : range.start
    const gridCols = target.value ? getGridElementColsCount(target.value) : 0
    const count = target.value?.querySelectorAll('[data-cell]').length || 0
    switch (e.key) {
      case 'Escape':
        reset()
        break
      case 'c':
        if (!isCtrl) return
        copySelected()
        break
      case 'Tab':
        e.preventDefault?.()
        if (isShift) {
          if (pos === 0) return
          range.start = pos - 1
          range.end = pos - 1
        } else {
          if (pos === count - 1) return
          range.start = pos + 1
          range.end = pos + 1
        }
        break
      case 'ArrowRight':
        const rightShift = pos + 1
        if (rightShift % gridCols === 0) return
        e.preventDefault?.()
        range.start = rightShift
        range.end = rightShift
        break
      case 'ArrowLeft':
        if (pos % gridCols === 0) return
        e.preventDefault?.()
        range.start = pos - 1
        range.end = pos - 1
        break
      case 'ArrowUp':
        e.preventDefault?.()
        const upShift = pos - gridCols
        if (upShift < 0) return
        range.start = upShift
        range.end = upShift
        break
      case 'ArrowDown':
        e.preventDefault?.()
        const downShift = pos + gridCols
        if (downShift >= count) return
        range.start = pos + gridCols
        range.end = pos + gridCols
        break
    }
  }

  const selectNext = () => {
    handleKeyDown({ key: 'Tab' } as KeyboardEvent)
  }

  const selectBellow = () => {
    handleKeyDown({ key: 'ArrowDown' } as KeyboardEvent)
  }

  watch(range, selectCells)

  onMounted(() => {
    document.addEventListener('keydown', handleKeyDown)
    target.value?.addEventListener('mousedown', handleMouseDown)
    target.value?.addEventListener('mousemove', handleMouseMove)
    target.value?.addEventListener('mouseup', handleMouseUp)
  })

  onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyDown)
    target.value?.removeEventListener('mousedown', handleMouseDown)
    target.value?.removeEventListener('mousemove', handleMouseMove)
    target.value?.removeEventListener('mouseup', handleMouseUp)
  })

  onClickOutside(target, event => {
    const path =
      (event as any).path || (event.composedPath && event.composedPath())
    if (
      path.find((item: HTMLElement) =>
        item.classList?.contains('v-popper__popper'),
      )
    )
      return
    reset()
  })

  return {
    hasSelected,
    range,
    getRangeCells: () => updateRange(range),
    selectNext,
    selectBellow,
    reset,
  }
}

export default useSelectionService
