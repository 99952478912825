<template>
  <form @submit.prevent="onSubmit">
    <div class="mb-4">
      <UILabeledField
        v-model="nameValue"
        v-bind="{ focusOnLoad, readonly }"
        :error="nameError"
        label="Name"
        data-refid="repositoryFormName"
        :disabled="isDisabled"
      />
    </div>
    <div class="mb-4">
      <UIInputDropdown
        v-model="currencyValue"
        v-bind="{ readonly }"
        :error="currencyError"
        label="Currency"
        data-refid="repositoryFormCurrency"
        :disabled="isDisabled"
        :data="currencies"
        id-key="key"
      />
    </div>
    <div v-if="!isInitDefaultTagsHidden" class="mb-4">
      <UICheckbox
        v-model="initDefaultTags"
        label="Create default Asset Class and Investment Strategy tags"
        class="repository-form__checkbox"
      />
    </div>
    <UIDraggableWrapper
      v-if="isPricePrecedenceVisible"
      label="Price precedence"
      class="mb-4"
    >
      <draggable
        v-model="pricePrecedence"
        handle=".handle"
        :disabled="isDisabled"
        :item-key="pricePrecedenceKey"
        tag="div"
        :animation="200"
      >
        <template #item="{ element }">
          <UIDraggableItem :item="element" />
        </template>
      </draggable>
    </UIDraggableWrapper>
    <div v-if="!isDisabled" class="buttons-wrapper">
      <UIButtonClose
        v-if="isCloseButtonVisible"
        :label="closeButtonLabel"
        @click="handleClose"
      />
      <UIButtonSave
        v-if="goToRepo"
        label="Go To Repo"
        data-refid="repositoryFormGoToRepo"
        type="button"
        :disabled="disabledGoToRepo"
        :loading="disabledGoToRepo"
        @click="hanleClickGoToRepo"
      />
      <UIButtonSave
        v-else-if="!readonly"
        :disabled="disabled || !(dirty && valid)"
        :loading="disabled"
        data-refid="repositoryFormSave"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useField, useForm } from 'vee-validate'
import draggable from 'vuedraggable'

import { Currency, PartialBy, Repository, RepositoryPut } from '@types'

import { orderedList } from '@/helpers/common'
import { rules } from '@/helpers/validate'

import { ACCESS_TYPE_OWNER } from '@/const/repositories'

import {
  UIButtonClose,
  UIButtonSave,
  UICheckbox,
  UIInputDropdown,
  UILabeledField,
} from '@ui'

import { UIDraggableItem, UIDraggableWrapper } from '@ui'

type RepositoryModel = PartialBy<RepositoryPut, 'id'>

type Props = {
  modelValue?: RepositoryModel
  currenciesList?: Currency[]
  disabled?: boolean
  readonly?: boolean
  disabledGoToRepo?: boolean
  goToRepo?: Repository
  focusOnLoad?: boolean
  wrapped?: boolean
}

type Emits = {
  close: []
  submit: [data: RepositoryModel]
  'active-again': []
  'click:go-to-repo': [data: string]
  'update:dirty': [data: boolean]
}

const { currenciesList = [], ...props } = defineProps<Props>()

const emit = defineEmits<Emits>()

const isPricePrecedenceVisible = computed(
  () => !props.readonly && pricePrecedence.value.length,
)

const isOwner = computed(
  () => props.modelValue?.user_repo_settings?.access_type === ACCESS_TYPE_OWNER,
)

const isDisabled = computed(() =>
  props.disabled || props.wrapped ? !isOwner.value : false,
)
const isInitDefaultTagsHidden = computed(
  () => typeof props.modelValue?.settings?.init_default_tags === 'boolean',
)
const isCloseButtonVisible = computed(() =>
  props.wrapped ? dirty.value : true,
)
const closeButtonLabel = computed(() => (props.wrapped ? 'Cancel' : 'Close'))

const currencies = computed(() =>
  orderedList(currenciesList, 'ticker', 'ticker'),
)

const pricePrecedenceKey = (item: string) => item

const { meta, handleSubmit, resetForm } = useForm<
  RepositoryModel & {
    initDefaultTags: boolean
    pricePrecedence: string[]
  }
>({
  initialValues: {
    name: props.modelValue?.name || '',
    currency: props.modelValue?.currency || 'USD',
    collaborators_count: props.modelValue?.collaborators_count || 0,
    settings: props.modelValue?.settings || undefined,
    user_repo_settings: props.modelValue?.user_repo_settings || undefined,
    initDefaultTags:
      typeof props.modelValue?.settings?.init_default_tags === 'boolean'
        ? props.modelValue?.settings?.init_default_tags
        : true,
    pricePrecedence: props.modelValue?.settings?.price_source_precedence || [],
  },
  validationSchema: {
    id: () => true,
    name: rules.required,
    currency: rules.required,
    collaborators_count: () => true,
    settings: () => true,
    user_repo_settings: () => true,
    currency_asset: () => true,
    initDefaultTags: () => true,
    pricePrecedence: () => true,
  },
})

const { value: nameValue, errorMessage: nameError } = useField<string>('name')
const { value: currencyValue, errorMessage: currencyError } =
  useField<string>('currency')
const { value: initDefaultTags } = useField<boolean>('initDefaultTags')
const { value: pricePrecedence } = useField<string[]>('pricePrecedence')

const handleClose = () => {
  props.wrapped ? resetForm() : emit('close')
}

const onSubmit = handleSubmit(values => {
  resetForm({ values })

  const { initDefaultTags, pricePrecedence, ...data } = values

  emit('submit', {
    ...data,
    settings: {
      ...data.settings,
      init_default_tags: initDefaultTags,
      price_source_precedence: pricePrecedence,
    },
  })
})

const hanleClickGoToRepo = () => {
  if (!props.goToRepo?.id) return
  emit('click:go-to-repo', props.goToRepo.id)
}

watch(meta, (value, old) => {
  if (!old.dirty && value.dirty) {
    emit('active-again')
  }
})

const valid = computed(() => meta.value.valid)
const dirty = computed(() => meta.value.dirty)
watch(
  dirty,
  value => {
    emit('update:dirty', value)
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'RepositoriesForm',
}
</script>

<style scoped lang="postcss">
.buttons-wrapper {
  @apply flex justify-end items-center space-x-2;
}

.repository-form__checkbox {
  :deep() .ui-checkbox__label {
    @apply !font-normal;
    @apply text-gray-400 dark:text-gray-500;
  }
}
</style>
