<template>
  <div v-if="isCaptionVisible" class="transaction__entry__caption">
    <div class="transaction__entry__caption__text">{{ entryTitle }}</div>
    <div v-if="entryDescription" class="transaction__entry__question">
      <QuestionMarkCircleIcon
        v-tooltip="entryDescription"
        class="transaction__entry__question__icon"
      />
    </div>
  </div>
  <div v-if="isAccountVisible" class="transaction__entry__asset">
    <component
      :is="accountComponent"
      ref="accountFieldRef"
      :data-refid="`transactionFormAccount${index}`"
      v-bind="{
        instance,
        label: accountLabel,
        readonly,
      }"
      show-summary-button
    />
  </div>
  <component
    :is="amountComponent"
    v-if="isAmountVisible"
    ref="amountFieldRef"
    :data-refid="`transactionFormAmount${index}`"
    v-bind="{
      instance,
      label: amountLabel,
      readonly,
    }"
  />
  <div v-if="isAssetVisible" class="transaction__entry__asset">
    <component
      :is="assetComponent"
      ref="assetFieldRef"
      :data-refid="`transactionFormAsset${index}`"
      v-bind="{
        instance,
        label: assetLabel,
        readonly,
      }"
      show-summary-button
    />
  </div>
</template>

<script setup lang="ts">
import {
  ComputedRef,
  Ref,
  computed,
  inject,
  provide,
  useTemplateRef,
} from 'vue'

import { TransactionClass } from '..'
import {
  TRANSACTION_ENTRY_FIELD,
  TRANSACTION_ENTRY_FIELD_LABEL,
} from '../utils/const'

import { QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
import { UIInputEditableDropdown, UILabeledField } from '@ui'

type Props = {
  instance: TransactionClass
  index: number
  readonly?: boolean
}

const props = defineProps<Props>()

const hidden = inject<ComputedRef<string[]>>('hidden')
const showHidden = inject<Ref<boolean>>('showHidden')

defineExpose({
  focus() {
    if (!props.instance.entries[entryIndex.value].account_id) {
      accountFieldRef.value?.focus()
    } else if (!props.instance.entries[entryIndex.value].amount) {
      amountFieldRef.value?.focus()
    } else if (!props.instance.entries[entryIndex.value].asset_id) {
      assetFieldRef.value?.focus()
    }
  },
})

const accountFieldRef =
  useTemplateRef<typeof UIInputEditableDropdown>('accountFieldRef')
const amountFieldRef = useTemplateRef<typeof UILabeledField>('amountFieldRef')
const assetFieldRef =
  useTemplateRef<typeof UIInputEditableDropdown>('assetFieldRef')

const entryIndex = computed(() => props.index - 1)

const entrySettings = computed(
  () => props.instance.settings?.entries[entryIndex.value],
)
provide('entrySettings', entrySettings)
const entryTitle = computed(() => entrySettings.value?.title)
const entryDescription = computed(() => entrySettings.value?.description)

const isAccountEmpty = computed(() => {
  const key = `emptyAccountId${props.index}` as keyof TransactionClass
  return props.instance[key]
})
const isAmountEmpty = computed(() => {
  const key = `emptyAmount${props.index}` as keyof TransactionClass
  return props.instance[key]
})
const isAssetEmpty = computed(() => {
  const key = `emptyAssetId${props.index}` as keyof TransactionClass
  return props.instance[key]
})

const accountComponent = computed(() => {
  const key = `getFormAccountId${props.index}` as keyof TransactionClass
  return (props.instance[key] as TransactionClass[`getFormAccountId1`])()
})
const amountComponent = computed(() => {
  const key = `getFormAmount${props.index}` as keyof TransactionClass
  return (props.instance[key] as TransactionClass[`getFormAmount1`])()
})
const assetComponent = computed(() => {
  const key = `getFormAssetId${props.index}` as keyof TransactionClass
  return (props.instance[key] as TransactionClass[`getFormAssetId1`])()
})

const accountLabel = computed(
  () =>
    entrySettings.value?.account?.title ||
    TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.ACCOUNT_ID],
)
const amountLabel = computed(
  () =>
    entrySettings.value?.amount?.title ||
    TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.AMOUNT],
)
const assetLabel = computed(
  () =>
    entrySettings.value?.asset?.title ||
    TRANSACTION_ENTRY_FIELD_LABEL[TRANSACTION_ENTRY_FIELD.ASSET_ID],
)
const isAccountVisible = computed(
  () =>
    !isAccountEmpty.value &&
    (showHidden?.value ||
      !hidden?.value.includes(
        `${TRANSACTION_ENTRY_FIELD.ACCOUNT_ID}${props.index}`,
      )),
)
const isAmountVisible = computed(
  () =>
    !isAmountEmpty.value &&
    (showHidden?.value ||
      !hidden?.value.includes(
        `${TRANSACTION_ENTRY_FIELD.AMOUNT}${props.index}`,
      )),
)
const isAssetVisible = computed(
  () =>
    !isAssetEmpty.value &&
    (showHidden?.value ||
      !hidden?.value.includes(
        `${TRANSACTION_ENTRY_FIELD.ASSET_ID}${props.index}`,
      )),
)
const isCaptionVisible = computed(
  () => isAccountVisible.value || isAmountVisible.value || isAssetVisible.value,
)
</script>

<script lang="ts">
export default {
  name: 'TransactionFormEntry',
}
</script>

<style scoped>
@import url('./styles/transaction.css');
</style>
