<template>
  <input
    ref="inputRef"
    :value="internalValue"
    @input="handleInput"
    @keydown.enter.prevent
  />
</template>

<script lang="ts" setup>
import { ref, useTemplateRef, watch } from 'vue'
import { TextFieldValue } from './utils/types'

const modelValue = defineModel<string>()

const internalValue = ref<TextFieldValue>()

const inputRef = useTemplateRef('inputRef')

defineExpose({
  blur() {
    inputRef.value?.blur()
  },
  focus() {
    inputRef.value?.focus()
  },
  select() {
    inputRef.value?.select()
  },
  begin() {
    if (!modelValue.value) return
    inputRef.value?.setSelectionRange(
      modelValue.value.length,
      modelValue.value.length,
    )
  },
})

const handleInput = (e: Event) => {
  modelValue.value = (e.target as HTMLInputElement).value
}

watch(
  modelValue,
  value => {
    internalValue.value = value
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'AppTextField',
}
</script>
