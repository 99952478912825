import { random, times } from 'lodash'

import { FilterField, FilterValue } from '../../utils/types'
import { FilterComparison } from '../../utils/enums'

import { FILTER_LAST_PERIODS } from './const'

import { getCurrentDate } from '@/helpers/dates'

export const generateRandomKey = () =>
  times(5, () => random(35).toString(36)).join('')

export const getDefaultComparison = (data?: FilterField) => {
  if (!data) return
  if (data.defaultComparison) {
    return data.defaultComparison
  } else if (data.isAmount) {
    return FilterComparison.GREATER
  } else if (data.isDate) {
    return FilterComparison.LAST
  } else if (data.isSimple) {
    return FilterComparison.EQ
  } else {
    return FilterComparison.CONTAINS
  }
}

const CONTAINS_COMPARISONS = [
  FilterComparison.CONTAINS,
  FilterComparison.NOTCONTAINS,
]

const EQ_COMPARISONS = [FilterComparison.EQ, FilterComparison.NOTEQ]

const GREATERLESS_COMPARISONS = [
  FilterComparison.GREATER,
  FilterComparison.LESS,
]

const DATES_COMPARISONS = [
  FilterComparison.BEFORE,
  FilterComparison.AFTER,
  FilterComparison.EQ,
  FilterComparison.NOTEQ,
]

export const getDefaultValue = (
  comparison?: FilterComparison,
  field?: FilterField,
  prevComparison?: FilterComparison,
  value?: FilterValue,
) => {
  if (
    comparison &&
    prevComparison &&
    ((CONTAINS_COMPARISONS.includes(comparison) &&
      CONTAINS_COMPARISONS.includes(prevComparison)) ||
      (EQ_COMPARISONS.includes(comparison) &&
        EQ_COMPARISONS.includes(prevComparison)) ||
      (GREATERLESS_COMPARISONS.includes(comparison) &&
        GREATERLESS_COMPARISONS.includes(prevComparison)) ||
      (field?.isDate &&
        DATES_COMPARISONS.includes(comparison) &&
        DATES_COMPARISONS.includes(prevComparison)))
  ) {
    return value
  }
  switch (comparison) {
    case FilterComparison.EQ:
      if (!field?.isDate) return
      return getCurrentDate({})
    case FilterComparison.LAST:
      return FILTER_LAST_PERIODS[0]
    case FilterComparison.AFTER:
    case FilterComparison.BEFORE:
      return getCurrentDate({})
    default:
      return
  }
}

export const getComparisonData = (data?: Partial<FilterField>) => {
  let comparison: FilterComparison[] = []
  if (data?.comparison) {
    comparison = [...data.comparison]
  } else if (data?.isAmount) {
    comparison = [
      FilterComparison.GREATER,
      FilterComparison.LESS,
      FilterComparison.EQ,
      FilterComparison.NOTEQ,
    ]
  } else if (data?.isArray) {
    comparison = [FilterComparison.CONTAINS, FilterComparison.NOTCONTAINS]
  } else if (data?.isDate) {
    comparison = [
      FilterComparison.LAST,
      FilterComparison.EQ,
      FilterComparison.NOTEQ,
      FilterComparison.BEFORE,
      FilterComparison.AFTER,
      // FilterComparison.RANGE,
    ]
  } else if (data?.isSimple) {
    comparison = [FilterComparison.EQ, FilterComparison.NOTEQ]
  } else if (data?.isSimpleString) {
    comparison = [FilterComparison.CONTAINS, FilterComparison.NOTCONTAINS]
  } else {
    comparison = [
      FilterComparison.CONTAINS,
      FilterComparison.NOTCONTAINS,
      FilterComparison.EQ,
      FilterComparison.NOTEQ,
    ]
  }
  comparison.push(FilterComparison.EMPTY, FilterComparison.NOTEMPTY)

  return comparison
}
