<template>
  <section class="default-layout">
    <slot />
  </section>
</template>

<script lang="ts">
export default {
  name: 'LayoutDefault',
}
</script>

<style scoped>
.default-layout {
  @apply min-h-[100dvh] flex flex-col justify-center;
  @apply bg-gray-100 dark:bg-gray-750;
}
</style>
