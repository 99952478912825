<template>
  <UIModal
    v-bind="{ isOpen, title }"
    :type="ModalType.DIALOG"
    @hide="handleHide"
  >
    <AddAccountForm
      placeholder="Chose csv file"
      :loader="LinkedDataManualLoader.ARCHLAB"
      accept=".csv"
      @hide="handleHide"
      @cancel="handleCancel"
    />
  </UIModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { LinkedDataManualLoader, ModalType } from '@types'

import { UIModal } from '@ui'
import AddAccountForm from './AddAccountForm.vue'

type Emits = {
  cancel: []
}

const emit = defineEmits<Emits>()

defineExpose({
  show(importFlag = false) {
    isImport.value = importFlag
    isOpen.value = true
  },
})

const isOpen = ref(false)
const isImport = ref(false)

const title = computed(
  () => `${isImport.value ? 'Import' : 'Add'} Archlab Accounts`,
)

const handleHide = () => {
  isOpen.value = false
}

const handleCancel = () => {
  handleHide()
  emit('cancel')
}

defineOptions({
  name: 'ConnectorsAddAccountArchlab',
})
</script>
