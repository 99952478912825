<template>
  <form @submit.prevent="handleSubmit">
    <div class="mb-4">
      <UIFileField
        v-model="json"
        v-model:error="errorMessage"
        v-model:file-name="fileName"
        v-model:loading="loading"
        v-bind="{ placeholder, accept }"
        :disabled="loading"
      />
    </div>
    <div class="flex items-center justify-end space-x-2">
      <UIButtonClose v-if="!json" @click="handleCancel" />
      <UIButtonSave
        v-else
        :disabled="loading"
        :loading="loading"
        label="Import"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { LinkedDataManualLoader, TaskStatus, TaskType } from '@types'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import { useFilesStore } from '@/store/files'
import { useLinkedDataStore } from '@/store/linkedData'
import { useTasksStore } from '@/store/tasks'

import { UIButtonClose, UIButtonSave, UIFileField } from '@ui'

type Props = {
  loader: LinkedDataManualLoader
  placeholder: string
  accept: string
}

type Emits = {
  hide: []
  cancel: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const filesStore = useFilesStore()
const linkedDataStore = useLinkedDataStore()
const tasksStore = useTasksStore()

const json = ref<FormData>()
const fileName = ref<string>()
const loading = ref(false)
const errorMessage = ref<string>()

const handleCancel = () => {
  emit('cancel')
}

const handleSubmit = async () => {
  const { progress, remove, error } = useNotifications()

  let progressId
  errorMessage.value = ''
  try {
    if (!json.value) {
      errorMessage.value = 'Nothing to import'
      return
    }
    loading.value = true
    emit('hide')

    progressId = await progress({
      message: 'Uploading account file to server',
      description: 'This may take several minutes',
    })

    const res = await filesStore.fetchUploadURL({ type: 'manual_sync' })

    const payload = new FormData()
    if (res.fields) {
      Object.entries(res.fields).forEach(([key, value]) => {
        payload.append(key, value)
      })
    }
    payload.append('file', json?.value?.get('file') as File)

    const uploadRes = await filesStore.uploadFile({
      url: res.url,
      payload,
    })

    if (!uploadRes || !res.fields.key) {
      await remove(progressId)
      await error({
        message: 'Error uploading account file to server',
        description:
          'Try to import the account again. If the error repeats, contact support',
      })
      return
    }
    tasksStore.pushTaskToList({
      status: TaskStatus.RUNNING,
      type: TaskType.SYNC_HOLDING_ACCOUNT,
    })
    await linkedDataStore.store(res.fields.key, props.loader)
    await remove(progressId)
    loading.value = false
  } catch (e) {
    handleCatchedError(e as string)
    progressId && (await remove(progressId))
    await error({
      message: 'Failed to upload file',
      description:
        'Try to import the account again. If the error repeats, contact support',
    })
    loading.value = false
  }
}

defineOptions({
  name: 'AddAccountForm',
})
</script>
