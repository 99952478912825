<template>
  <UIDropdown
    :model-value="currentRouteName"
    v-bind="{ items }"
    reverse
    placement="bottom-end"
    id-key="key"
    value-key="label"
    class="header-user"
    data-refid="headerUser"
  >
    <AppHeaderIcon :icon="UserCircleIcon" class="header-user__toggler" />
    <template #before="{ hide }">
      <div class="header-user__info">
        <div class="header-user__name">{{ userEmail }}</div>
        <div class="header-user__plan">
          <div>Your current plan is:</div>
          <div class="header-user__plan-name">{{ currentPlan }}</div>
          <router-link
            :to="{ name: ROUTE_NAME.SUBSCRIPTION }"
            class="header-user__plan-link"
            @click="hide"
          >
            Go to subscription
          </router-link>
        </div>
      </div>
    </template>
  </UIDropdown>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDark, useToggle } from '@vueuse/core'

import { ROUTE_NAME } from '@/const'

import { useUserStore } from '@/store/user'
import { useUserSubscriptionsStore } from '@/store/user/subscriptions'

import { AppHeaderIcon } from '@app'
import {
  ArrowRightEndOnRectangleIcon,
  Cog6ToothIcon,
  MoonIcon,
  SunIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline'
import { UIDropdown } from '@ui'

const userStore = useUserStore()
const userSubscriptionsStore = useUserSubscriptionsStore()

const route = useRoute()
const router = useRouter()

const isDark = useDark()
const toggleDark = useToggle(isDark)

const userEmail = inject<ComputedRef<string>>('userEmail')

const darkThemeIcon = computed(() => (isDark.value ? SunIcon : MoonIcon))

const currentPlan = computed(() => userSubscriptionsStore.getCurrentPlanName)

const items = computed(() => [
  {
    label: isDark.value ? 'Light Theme' : 'Dark Theme',
    icon: darkThemeIcon.value,
    action: (hide: () => void) => {
      hide()
      toggleDark()
    },
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: Cog6ToothIcon,
    action: (hide: () => void) => {
      hide()
      router.push({ name: ROUTE_NAME.SETTINGS })
    },
  },
  {
    label: 'Sign out',
    icon: ArrowRightEndOnRectangleIcon,
    action: async () => {
      await userStore.signOut()
    },
    attrs: {
      'data-refid': 'headerSignout',
    },
  },
])

const currentRouteName = computed(
  () =>
    (route.matched[0]?.name ||
      route.matched[0]?.path?.replace('/', '')) as string,
)
</script>

<script lang="ts">
export default {
  name: 'HeaderUser',
}
</script>

<style lang="postcss">
.header-user {
  &__toggler {
    @apply text-indigo-700 hover:text-indigo-600;
    @apply dark:text-indigo-300 dark:hover:text-indigo-400;
  }

  &__info {
    @apply w-72 md:w-52;
    @apply mb-1;
  }

  &__name {
    @apply px-4 py-2;
    @apply border-b border-gray-100 dark:border-gray-600;
    @apply text-gray-500 dark:text-gray-400;
    @apply text-sm;
    @apply text-right;
    @apply truncate;
  }

  &__plan {
    @apply flex flex-col items-end;
    @apply gap-1 px-4 pt-2 pb-3 mb-1;
    @apply border-b border-gray-100 dark:border-gray-600;
    @apply text-gray-500 dark:text-gray-400;
    @apply text-xs;
  }

  &__plan-name {
    @apply bg-indigo-500;
    @apply py-0.5 px-2;
    @apply rounded-full;
    @apply text-white uppercase;
  }

  &__plan-link {
    @apply text-indigo-500 dark:text-indigo-400;
    @apply underline hover:no-underline;
  }
}
</style>
