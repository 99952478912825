<template>
  <component :is="image" v-if="image" class="w-3" />
  <LinkIcon v-else class="w-3" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getUrlService } from '@/helpers/documents'

import { LinkIcon } from '@heroicons/vue/20/solid'

import box from '@/assets/images/services/box.svg'
import google from '@/assets/images/services/google.svg'
import dropbox from '@/assets/images/services/dropbox.svg'
import onedrive from '@/assets/images/services/onedrive.svg'

const images: Record<string, typeof box> = {
  'box.com': box,
  'drive.google.com': google,
  'docs.google.com': google,
  'dropbox.com': dropbox,
  'dl.dropboxusercontent.com': dropbox,
  '1drv.ms': onedrive,
  Box: box,
  'Google Drive': google,
  Dropbox: dropbox,
  OneDrive: onedrive,
}

type Props = {
  path: string
  isNotLink?: boolean
}

const props = defineProps<Props>()

const image = computed(
  () => images[getUrlService(props.path, props.isNotLink)] || null,
)
</script>

<script lang="ts">
export default {
  name: 'UIServiceIcon',
}
</script>
