import { defineStore } from 'pinia'

import { useUISettings } from '@/hooks/uiSettings'
import { computed } from 'vue'

export const useSettingsStore = defineStore('settings', () => {
  // INIT

  const gridExtraColumns = useUISettings<boolean>(
    ['common', 'grid_show_extra_columns'],
    false,
  )

  const analyticsDebugTabs = useUISettings<boolean>(
    ['common', 'analytics_debug_tab'],
    false,
  )

  // GETTERS

  const getGridExtraColumns = computed(() => gridExtraColumns.value)
  const getAnalyticsDebugTabs = computed(() => analyticsDebugTabs.value)

  // SETTERS

  const setGridExtraColumns = (value: boolean) => {
    gridExtraColumns.value = value
  }

  const setAnalyticsDebugTabs = (value: boolean) => {
    analyticsDebugTabs.value = value
  }

  return {
    getGridExtraColumns,
    getAnalyticsDebugTabs,

    setGridExtraColumns,
    setAnalyticsDebugTabs,
  }
})
