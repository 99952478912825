import { Intercom } from './type'

declare global {
  interface Window {
    Intercom: Intercom
  }
}

export const callFunction: Intercom = async (funcName, options, metadata) => {
  if (!window.Intercom) return
  return (await window.Intercom(funcName, options, metadata)) as string
}

export const getAppVersion = () => {
  const appVersion = __APP_VERSION__.match(/\d+.\d+.\d+/gm)?.[0].match(/\d+/gm)
  return appVersion?.length === 3
    ? +appVersion[1] * 1000000 + +appVersion[1] * 1000 + +appVersion[2]
    : Number.MAX_SAFE_INTEGER
}
