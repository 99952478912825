<template>
  <div class="select-repository-item">
    <div class="select-repository-item__name">
      <div class="select-repository-item__name-value">{{ value }}</div>
      <component
        :is="iconComponent"
        class="select-repository-item__shared-icon"
      />
    </div>
    <div class="select-repository-item__permissions">
      {{ displayPermissions }}
    </div>
    <SelectRepositoryItemMenu ref="menuRef">
      <UIButton
        v-if="isReadonly"
        v-bind="{ size }"
        label="View"
        :icon="EyeIcon"
        variant="light-secondary"
        @click="handleClickEdit"
      />
      <template v-else-if="item">
        <UIButton
          v-bind="{ size }"
          :icon="ArrowUpTrayIcon"
          :loading="exporting"
          :disabled="exporting"
          label="Export"
          variant="light-gray"
          @click="handleClickExport"
        />
        <UIButton
          v-bind="{ size }"
          :icon="PencilIcon"
          label="Edit"
          variant="light-secondary"
          @click="handleClickEdit"
        />
        <UIButton
          v-bind="{ size }"
          :icon="TrashIcon"
          label="Delete"
          variant="light-red"
          @click="handleClickDelete"
        />
      </template>
    </SelectRepositoryItemMenu>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, useTemplateRef } from 'vue'

import { Repository } from '@types'

import { ACCESS_TYPES, ACCESS_TYPE_READONLY } from '@/const/repositories'

import {
  UserGroupIcon,
  EyeIcon,
  ArrowUpTrayIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline'
import SelectRepositoryItemMenu from './SelectRepositoryItemMenu.vue'
import { UIButton } from '@ui'

type Props = {
  item: Repository
  value: string
}

type Emits = {
  export: [item: Repository, callback: () => void]
  edit: [item: Repository]
  delete: [item: Repository]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const size = 'small'

const menuRef = useTemplateRef('menuRef')
const exporting = ref(false)

const iconComponent = computed(() =>
  props.item.collaborators_count > 1 ? UserGroupIcon : undefined,
)

const displayPermissions = computed(
  () =>
    props.item.user_repo_settings?.access_type &&
    ACCESS_TYPES[props.item.user_repo_settings.access_type - 1],
)

const isReadonly = computed(
  () => props.item?.user_repo_settings?.access_type === ACCESS_TYPE_READONLY,
)

const handleClickExport = () => {
  exporting.value = true
  emit('export', props.item, () => {
    exporting.value = false
  })
}

const handleClickEdit = () => {
  emit('edit', props.item)
}

const handleClickDelete = () => {
  menuRef.value?.hide()
  emit('delete', props.item)
}
</script>

<script lang="ts">
export default {
  name: 'SelectRepositoryItem',
}
</script>

<style scoped lang="postcss">
.select-repository-item {
  @apply flex items-center justify-between;
  @apply flex-auto;
  @apply gap-x-1;
  @apply -mr-3;

  &__name {
    @apply w-[14rem];
    @apply flex items-center;
    @apply flex-auto;
    @apply gap-x-1;
  }

  &__name-value {
    @apply line-clamp-2;
  }

  &__shared-icon {
    @apply w-4 h-4;
    @apply shrink-0;
    @apply text-indigo-400 dark:text-indigo-300;
  }

  &__permissions {
    @apply text-xs;
    @apply text-gray-400 dark:text-gray-300;
  }
}
</style>
