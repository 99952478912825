<template>
  <UITree
    v-if="data"
    v-bind="{ data, formatter, getDescription }"
    :meta-fields="['amount', 'balance', 'path']"
    :root="ANALYTICS_REPO_ROOT"
    sort-field="balance"
    size="xxsmall"
    placeholder="Search assets..."
    wide
    class="app-tree"
    @click:name="handleClickName"
    @on:contextmenu="handleClickMenu"
  >
    <template #root>{{ repoName }}</template>
    <template #meta="{ amount, balance, isRoot }">
      <div class="app-tree__values">
        <div class="app-tree__balance blurable-number">
          {{ balanceFormatter(balance) }}
        </div>
        <div v-if="!isRoot" class="app-tree__amount blurable-number">
          {{ amountFormatter(amount) }}
        </div>
      </div>
    </template>
    <template #before-item="{ keyValue }">
      <UICheckbox
        v-if="keyValue !== ANALYTICS_REPO_ROOT"
        v-model="selected"
        :value="keyValue"
        class="app-tree__checkbox"
        :class="getCheckboxClasses(keyValue)"
      />
      <div v-else class="w-4" />
    </template>
    <template #actions="{ item, isLeaf, keyValue }">
      <div
        class="app-tree__action"
        :class="getConnectorInfo(keyValue) ? '' : ' app-tree__action--shifted'"
        @click="handleClickMenu(item, isLeaf)"
      >
        <EllipsisVerticalIcon
          class="app-tree__action-icon"
          aria-hidden="true"
        />
      </div>
      <div
        v-if="getConnectorInfo(keyValue)"
        v-tooltip.permanent="getConnectorInfo(keyValue)?.message"
        class="app-tree__action"
        :class="getConnectorClass(keyValue)"
        @click="handleClickConnector(item, isLeaf)"
      >
        <LinkIcon
          class="app-tree__action-icon app-tree__action-icon--connector"
          aria-hidden="true"
        />
      </div>
    </template>
    <template #after>
      <div v-if="isPanelVisible" class="app-tree__panel">
        <UIButton
          :icon="RectangleStackIcon"
          label="Group accouns"
          size="xsmall"
          @click="handleClickGroupAccounts"
        />
        <UIButton
          :icon="XMarkIcon"
          variant="simple"
          class="app-tree__panel-close"
          @click="handleClickClosePanel"
        />
      </div>
    </template>
  </UITree>
  <div v-else class="app-tree__no-data">
    <UILayoutNoData inline hide-question-icon>
      <template #text-description>Empty tree</template>
    </UILayoutNoData>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, markRaw, ref } from 'vue'

import {
  AnalyticsPreparedTree,
  TreeNodeFormatter,
  TreeNodeDescriptionGetter,
  LinkedDataConnectorStatus,
} from '@types'

import { ANALYTICS_REPO_ROOT } from '@/const'
import { CONNECTOR_SYNC_STATUS_TEXT } from '@/views/LinkedData/utils/const'

import { numberFormat } from '@/helpers/numbers'

import { useModalsStore } from '@/store/modals'

import {
  LinkIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
  RectangleStackIcon,
} from '@heroicons/vue/24/outline'
import { UIButton, UICheckbox, UILayoutNoData, UITree } from '@ui'
import AssetsGrouping from '@/views/Data/AssetsGrouping.vue'

type Props = {
  data?: AnalyticsPreparedTree
  repoName?: string
  formatter: TreeNodeFormatter
  getConnectorInfo: (key: string) =>
    | {
        status: LinkedDataConnectorStatus
        message: string
      }
    | undefined
  getDescription: TreeNodeDescriptionGetter
}

type Emits = {
  'click:menu': [item: AnalyticsPreparedTree, isLeaf: boolean]
  'click:name': [item: AnalyticsPreparedTree, isLeaf: boolean]
  'click:connector': [item: AnalyticsPreparedTree, isLeaf: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()

const currency = inject<ComputedRef<string>>('currency')

const selected = ref<string[]>([])

const isPanelVisible = computed(() => !!selected.value?.length)

const balanceFormatter = (value: number) =>
  numberFormat(value, { currency: currency?.value })

const amountFormatter = (value: number) => numberFormat(value)

const getCheckboxClasses = (id: string) => {
  return selected.value.includes(id) ? 'app-tree__checkbox--checked' : undefined
}

const getConnectorClass = (key: string) => {
  const status = props.getConnectorInfo(key)?.status
  if (!status) return
  const statusValue = CONNECTOR_SYNC_STATUS_TEXT[status]?.toLowerCase()
  return `app-tree__action-connector--${statusValue}`
}

const handleClickConnector = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:connector', item, isLeaf)
}

const handleClickMenu = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:menu', item, isLeaf)
}

const handleClickName = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:name', item, isLeaf)
}

const handleClickClosePanel = () => {
  selected.value = []
}

const handleClickGroupAccounts = () => {
  const modalInstance = modalsStore.init(
    'assets-grouping',
    markRaw(AssetsGrouping),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    ids: selected.value,
    onComplete: () => {
      selected.value = []
    },
  })
}
</script>

<script lang="ts">
export default {
  name: 'AppTree',
}
</script>

<style lang="postcss">
.app-tree {
  .ui-tree__search {
    @apply gap-x-1;
    @apply pt-2 mx-4;
  }

  .ui-tree__item {
    @apply justify-between;
    @apply pl-2;
    @apply hover:bg-gray-50 md:bg-gray-100 md:hover:bg-white;
    @apply dark:bg-gray-750 dark:hover:bg-gray-800;

    &:has(.app-tree__checkbox--checked) {
      @apply bg-indigo-100 dark:bg-gray-900;
    }
  }

  .ui-tree__item-area {
    @apply items-start justify-between;
    @apply pt-[0.45rem] pl-2 gap-x-2;

    &--root {
      @apply pb-2;
      @apply items-center;
    }
  }

  .ui-tree__name {
    @apply shrink;

    &:not([data-leaf]) {
      @apply py-1 px-1.5 -my-1 -mx-1.5;
      @apply hover:bg-indigo-50 dark:hover:bg-gray-700;
      @apply hover:text-indigo-600 dark:hover:text-indigo-300;
      @apply rounded;
      @apply cursor-pointer;
    }
  }

  .ui-tree__value {
    @apply shrink-0;
    @apply p-1 -m-1;
  }

  .ui-tree__actions {
    @apply -mr-2 md:-mr-3;
  }

  &__checkbox {
    @apply m-0 mt-2;
    @apply opacity-0 group-hover:opacity-100;

    .ui-checkbox__control {
      @apply border-indigo-500;
    }

    &--checked {
      @apply opacity-100;
    }
  }

  &__values {
    @apply flex-auto;
    @apply text-xs;
    @apply text-right;
  }

  &__balance {
    @apply text-gray-700 dark:text-gray-300;
  }

  &__amount {
    @apply text-gray-400 dark:text-gray-500;
    @apply font-light;
  }

  &__no-data {
    @apply flex flex-col flex-auto justify-center;
  }

  &__action {
    @apply w-5 h-8;
    @apply flex items-center justify-center;
    @apply shrink-0;
    @apply hover:bg-indigo-50 dark:hover:bg-indigo-900;
    @apply text-gray-500 hover:text-indigo-700;
    @apply dark:text-gray-400 dark:hover:text-indigo-300;
    @apply bg-opacity-80;
    @apply rounded-sm;
    @apply cursor-pointer;

    &--shifted {
      @apply mr-5;
    }

    &-icon {
      @apply w-5 h-5;

      &--connector {
        @apply w-4;
      }
    }
  }

  &__action-connector {
    &--error {
      @apply !text-red-800 dark:!text-red-300;
    }
    &--warning {
      @apply !text-yellow-800 dark:!text-yellow-300;
    }
  }

  &__panel {
    @apply sticky bottom-0;
    @apply flex items-center;
    @apply gap-2 py-2 px-4;
    @apply bg-gray-200 dark:bg-gray-850;
  }

  &__panel-close {
    @apply ml-auto;
  }
}
</style>
