<template>
  <UIInputEditableDropdown
    ref="inputRef"
    v-model="tagValue"
    v-bind="{ data }"
    :label="props.name"
    frozen
    @create="handleCreate"
  />
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, useTemplateRef } from 'vue'

import { InputDropdownItem } from '@/components/UI/Input/Dropdown/utils/types'

import { UIInputEditableDropdown } from '@ui'

type Props = {
  name: string
  list: InputDropdownItem[]
}

const props = defineProps<Props>()

const tagValue = defineModel<string>({ default: undefined })

const inputRef = useTemplateRef('inputRef')

const data = ref<InputDropdownItem[]>([])

const addValueToList = (value: string) => {
  if (data.value.find(item => item.value === value)) return
  data.value.push({ value })
}

const handleCreate = async (value: string) => {
  if (!value) return
  addValueToList(value)
  tagValue.value = value
  await nextTick()
  inputRef.value?.reset()
}

onMounted(() => {
  data.value = props.list
  if (tagValue.value) {
    addValueToList(tagValue.value)
  }
})
</script>

<script lang="ts"></script>

<style lang="postcss"></style>
