<template>
  <div class="contacts__fields">
    <component
      :is="instance.getFormName()"
      v-bind="{
        dataRefid: 'contactFormName',
        focusOnLoad: focusNameOnLoad,
        instance,
        label: CONTACT_FIELD_LABEL[CONTACT_FIELD.NAME],
        readonly,
      }"
    />
    <component
      :is="instance.getFormEmail()"
      v-bind="{
        dataRefid: 'contactFormEmail',
        focusOnLoad: focusEmailOnLoad,
        instance,
        label: CONTACT_FIELD_LABEL[CONTACT_FIELD.EMAIL],
        readonly,
      }"
    />
    <component
      :is="instance.getFormPhone()"
      v-bind="{
        dataRefid: 'contactFormPhone',
        focusOnLoad: focusPhoneOnLoad,
        instance,
        label: CONTACT_FIELD_LABEL[CONTACT_FIELD.PHONE],
        readonly,
      }"
    />
    <component
      :is="instance.getFormNotes()"
      v-bind="{
        dataRefid: 'contactFormNotes',
        focusOnLoad: focusNotesOnLoad,
        instance,
        label: CONTACT_FIELD_LABEL[CONTACT_FIELD.NOTES],
        readonly,
      }"
    />
    <div class="contacts__buttons">
      <UIButtonClose @click="handleClose" />
      <UIButtonSave
        v-if="!readonly"
        ref="buttonSaveRef"
        data-refid="contactFormSave"
        v-bind="{
          disabled,
          loading,
        }"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, provide, ref, useTemplateRef } from 'vue'

import { ReadonlyMode } from '@types'
import { ContactClass } from '..'
import { EntityState } from '@/entities/utils/enums'

import {
  CONTACT_FIELD,
  CONTACT_FIELD_LABEL,
  CONTACT_ID_PREFIX,
} from '../utils/const'
import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY, READONLY_MODE } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import { UIButtonClose, UIButtonSave } from '@ui'

type Props = {
  instance: ContactClass
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { progress, remove, update } = useNotifications()

const loading = ref(false)

const buttonSaveRef = useTemplateRef('buttonSaveRef')
provide('contact-save-focus', async () => {
  await nextTick()
  buttonSaveRef.value?.focus()
})

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const disabled = computed(
  () => ![EntityState.CHANGED, EntityState.NEW].includes(props.instance.state),
)

const isSavedContact = computed(() =>
  props.instance.id.startsWith(CONTACT_ID_PREFIX),
)
provide('isSavedContact', isSavedContact)

const focusNameOnLoad = computed(
  () =>
    !isSavedContact.value && !props.instance.field(CONTACT_FIELD.NAME).value,
)

const focusEmailOnLoad = computed(
  () =>
    !isSavedContact.value &&
    !focusNameOnLoad.value &&
    !props.instance.field(CONTACT_FIELD.EMAIL).value,
)

const focusPhoneOnLoad = computed(
  () =>
    !isSavedContact.value &&
    !focusNameOnLoad.value &&
    !focusEmailOnLoad.value &&
    !props.instance.field(CONTACT_FIELD.PHONE).value,
)

const focusNotesOnLoad = computed(
  () =>
    !isSavedContact.value &&
    !focusNameOnLoad.value &&
    !focusEmailOnLoad.value &&
    !focusPhoneOnLoad.value &&
    !props.instance.field(CONTACT_FIELD.NOTES).value,
)

const handleClose = () => {
  emit('close')
}

const handleSave = async () => {
  loading.value = true
  const isNew = props.instance.isNew
  const nid = await progress({
    message: `${isNew ? 'Creating' : 'Updating'} contact`,
  })
  try {
    if (isNew) {
      await props.instance.store()
    } else {
      await props.instance.update()
    }
    await update(
      nid,
      {
        type: 'success',
        message: `Contact ${props.instance.field(CONTACT_FIELD.NAME).value} ${
          isNew ? 'created' : 'updated'
        }`,
        link: {
          text: 'Open',
          to: {
            name: ROUTE_NAME.CONTACTS_ITEM,
            params: { id: props.instance.id },
          },
        },
      },
      NOTIFICATION_DELAY,
    )
    emit('close')
  } catch (e) {
    await remove(nid)
    handleCatchedError(e as string, props.instance.get())
  } finally {
    loading.value = false
  }
}
</script>

<script lang="ts">
export default {
  name: 'ContactForm',
}
</script>

<style scoped>
@import url('../styles/contacts.css');
</style>
