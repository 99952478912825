import { Ref, onMounted, onUnmounted, ref } from 'vue'

const useResizeService = (
  headerRef: Ref<HTMLDivElement | null>,
  resizerRef: Ref<HTMLDivElement | null>,
  onCellResize: (width: number) => void,
  onResizerDblClick: () => void,
) => {
  const resizeFlag = ref(false)
  const pageX = ref(0)
  const currentWidth = ref(0)

  const handleResizerMouseDown = (e: MouseEvent) => {
    if (!headerRef.value) return
    e.stopImmediatePropagation()
    currentWidth.value = headerRef.value.offsetWidth
    resizeFlag.value = true
    pageX.value = e.pageX
  }

  const handleResizerMouseMove = (e: MouseEvent) => {
    if (!resizeFlag.value) return
    e.stopImmediatePropagation()
    const diff = e.pageX - pageX.value
    const newWidth = currentWidth.value + diff
    if (newWidth > 0) {
      onCellResize(newWidth)
    }
  }

  const handleResizerMouseUp = (e: MouseEvent) => {
    if (!resizeFlag.value) return
    e.stopImmediatePropagation()
    resizeFlag.value = false
  }

  onMounted(() => {
    resizerRef.value?.addEventListener('dblclick', onResizerDblClick)
    resizerRef.value?.addEventListener('mousedown', handleResizerMouseDown)
    document.addEventListener('mousemove', handleResizerMouseMove)
    document.addEventListener('mouseup', handleResizerMouseUp)
  })

  onUnmounted(() => {
    resizerRef.value?.removeEventListener('dblclick', onResizerDblClick)
    resizerRef.value?.removeEventListener('mousedown', handleResizerMouseDown)
    document.removeEventListener('mousemove', handleResizerMouseMove)
    document.removeEventListener('mouseup', handleResizerMouseUp)
  })
}

export default useResizeService
