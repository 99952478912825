<template>
  <div class="wizzard__form">
    <component
      v-bind="{ focusOnLoad }"
      :is="asset.getFormName()"
      :instance="asset"
      label="Property name"
      placeholder="Ex. 10 Beach Lane"
    />
    <component
      :is="asset.getFormDescription()"
      :instance="asset"
      label="Description"
      placeholder="Optional"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetClass } from '@/entities/assets'

import { ASSET_FIELD } from '@/entities/assets/utils/const'

import { TransactionClass } from '..'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
}

const props = defineProps<Props>()

const focusOnLoad = computed(() => !props.asset.field(ASSET_FIELD.NAME).value)
</script>

<script lang="ts"></script>

<style lang="postcss"></style>
