<template>
  <div class="logo">
    <template v-if="!textOnly">
      <AllpositLogo v-if="substrate" :class="symbolClasses" alt="Allposit" />
      <span v-else class="logo__symbol" :class="symbolClasses">A</span>
    </template>
    <span v-if="showText" class="logo__text" :class="textClasses"
      >Allposit</span
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import AllpositLogo from '@/assets/images/logo.svg'
import { LogoSize } from './utils/enums'

type Props = {
  size: LogoSize
  withText?: boolean
  textOnly?: boolean
  darkBackground?: boolean
  substrate?: boolean
}

const { size = LogoSize.MIDDLE, ...props } = defineProps<Props>()

const showText = computed(() => props.withText || props.textOnly)

const symbolClasses = computed(() => {
  const classes = [`logo__symbol--${size}`]
  if (props.darkBackground) {
    classes.push('logo__symbol--dark')
  }
  return classes
})

const textClasses = computed(() => {
  const classes = [`logo__text--${size}`]
  if (props.darkBackground) {
    classes.push('logo__text--dark')
  }
  return classes
})
</script>

<script lang="ts">
export default {
  name: 'AppLogo',
}
</script>

<style scoped lang="postcss">
.logo {
  @apply flex items-center gap-2;

  &__symbol {
    @apply transform rotate-180 antialiased font-medium;
    @apply text-white;

    &--dark {
      @apply text-indigo-500;
    }
    &--small {
      @apply text-2xl h-8 w-8;
    }
    &--middle {
      @apply text-4xl h-10 w-10;
    }
    &--large {
      @apply text-6xl h-12 w-12;
    }
  }
  &__text {
    @apply antialiased;
    @apply text-gray-600 dark:text-gray-400;

    &--dark {
      @apply text-white;
    }
    &--small {
      @apply text-lg font-semibold;
    }
    &--middle {
      @apply text-2xl font-bold;
    }
    &--large {
      @apply text-3xl font-bold;
    }
  }
}
</style>
