<template>
  <div ref="dateWrapperRef" class="date-input__wrapper" :data-refid="dataRefid">
    <template v-for="(input, i) in sortedInputs" :key="i">
      <component
        v-bind="{ disabled, readonly }"
        :is="h('input', input.renderData)"
        ref="inputRef"
        autocomplete="off"
        class="date-input__item"
        :class="itemClasses"
        @mouseup.stop
        @mousedown.stop
      />
      <span v-if="i < 2" :class="separatorClasses">{{ dateDelimiter }}</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, h, ref, useTemplateRef, watch } from 'vue'
import { sortBy } from 'lodash'

import { DateFieldValue } from './utils/types'
import { getInputValue, getTextWidth, isNumber, padZero } from './utils/helpers'

import {
  dateFormat,
  objectDateTimeToString,
  getDaysInMonthCount,
  stringToDateTime,
} from '@/helpers/dates'
import { MILLENIUM_CHANGE_YEAR } from '@/helpers/dates/utils/const'

type Props = {
  dataRefid?: string
  disabled?: boolean
  readonly?: boolean
}

type Emits = {
  keydown: [data: KeyboardEvent]
  focus: []
  blur: [data: Event]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<DateFieldValue>({ required: true })

const dateWrapperRef = useTemplateRef('dateWrapperRef')

const inputRef = useTemplateRef<HTMLInputElement[]>('inputRef')

const isFocused = ref(false)
const focusedTimeout = ref<NodeJS.Timeout>()

defineExpose({
  blur() {
    inputRef.value?.[0]?.blur()
  },
  focus() {
    inputRef.value?.[2]?.focus()
  },
  select() {
    inputRef.value?.[0]?.select()
  },
  begin() {
    inputRef.value?.[2]?.select()
  },
})

const dateDay = ref<string | null>(null)
const dateMonth = ref<string | null>(null)
const dateYear = ref<string | null>(null)

const fontSize = computed(() => {
  const wrapper = dateWrapperRef.value
  if (!wrapper) return ''
  const computedStyle = getComputedStyle(wrapper)
  return computedStyle.fontSize || ''
})

const fontFamily = computed(() => {
  const wrapper = dateWrapperRef.value
  if (!wrapper) return ''
  const computedStyle = getComputedStyle(wrapper)
  return computedStyle.fontFamily || ''
})

const dayInputStyles = computed(() => {
  const width = getTextWidth(
    dateDay.value || 'dd',
    fontSize.value,
    fontFamily.value,
  )
  return {
    width: `${width}px`,
  }
})

const monthInputStyles = computed(() => {
  const width = getTextWidth(
    dateMonth.value || 'mm',
    fontSize.value,
    fontFamily.value,
  )
  return {
    width: `${width}px`,
  }
})

const yearInputStyles = computed(() => {
  const width = getTextWidth(
    dateYear.value || 'yyyy',
    fontSize.value,
    fontFamily.value,
  )
  return {
    width: `${width}px`,
  }
})

const fixMaxDayValue = () => {
  if (!dateDay.value) return

  const year = dateYear.value ? +dateYear.value : null
  const maxDay = dateMonth.value
    ? getDaysInMonthCount(+dateMonth.value, year)
    : 31
  const day = +dateDay.value

  if (day > maxDay) {
    dateDay.value = maxDay.toString()
  }
}

const itemClasses = computed(() => ({
  'date-input__item--readonly': props.readonly,
}))

const separatorClasses = computed(() => ({
  'date-input__separator--readonly': props.readonly,
}))

const dateDelimiter = computed(
  () => dateFormat.value.toLowerCase().replace(/[dmy]/g, '')[0],
)

const dayInputOrder = computed(() =>
  dateFormat.value.split(dateDelimiter.value).indexOf('dd'),
)
const monthInputOrder = computed(() =>
  dateFormat.value.split(dateDelimiter.value).indexOf('MM'),
)
const yearInputOrder = computed(() =>
  dateFormat.value.split(dateDelimiter.value).indexOf('yyyy'),
)

const sortedInputs = computed(() => {
  const array = [
    {
      order: dayInputOrder.value,
      renderData: {
        value: dateDay.value,
        style: dayInputStyles.value,
        placeholder: 'dd',
        'data-refid': props.dataRefid ? `${props.dataRefid}Day` : undefined,
        onFocus: () => handleFocus(dayInputOrder.value),
        onInput: handleDayInput,
        onKeypress: isNumber,
        onKeydown: handleKeyDown(dayInputOrder.value),
        onBlur: handleDayBlur,
      },
    },
    {
      order: monthInputOrder.value,
      renderData: {
        value: dateMonth.value,
        style: monthInputStyles.value,
        placeholder: 'mm',
        'data-refid': props.dataRefid ? `${props.dataRefid}Month` : undefined,
        onFocus: () => handleFocus(monthInputOrder.value),
        onInput: handleMonthInput,
        onKeypress: isNumber,
        onKeydown: handleKeyDown(monthInputOrder.value),
        onBlur: handleMonthBlur,
      },
    },
    {
      order: yearInputOrder.value,
      renderData: {
        value: dateYear.value,
        style: yearInputStyles.value,
        placeholder: 'yyyy',
        'data-refid': props.dataRefid ? `${props.dataRefid}Year` : undefined,
        onFocus: () => handleFocus(yearInputOrder.value),
        onInput: handleYearInput,
        onKeypress: isNumber,
        onKeydown: handleKeyDown(yearInputOrder.value),
        onBlur: handleYearBlur,
      },
    },
  ]

  return sortBy(array, 'order')
})

const handleFocus = (index: number) => {
  if (index > 2) return
  inputRef.value?.[index].focus()
  focusedTimeout.value && clearTimeout(focusedTimeout.value)
  if (isFocused.value) return
  isFocused.value = true
  emit('focus')
}

const handleSelect = (index: number) => {
  if (index > 2) return
  inputRef.value?.[index].select()
}

const dateObject = computed(() => {
  return {
    day: dateDay.value ? +dateDay.value : undefined,
    month: dateMonth.value
      ? +dateMonth.value < 13
        ? +dateMonth.value
        : 12
      : undefined,
    year: dateYear.value ? +dateYear.value : undefined,
  }
})

const updateDate = ({
  day,
  month,
  year,
}: {
  day: number | undefined
  month: number | undefined
  year: number | undefined
}) => {
  if (!day || !month || !year) return
  const date = objectDateTimeToString({ day, month, year }, { iso: true })
  modelValue.value = date
}

const handleKeyDown = (order: number) => (e: KeyboardEvent) => {
  if (
    (e.shiftKey && e.key === 'Tab' && order > 0) ||
    (e.key === 'Tab' && order < 2)
  ) {
    e.stopImmediatePropagation()
    return
  }
  emit('keydown', e)
}

const getYearValue = (value: string) => {
  if (value.length === 1) {
    return `200${value}`
  }
  if (value.length === 2) {
    return Number(value) > MILLENIUM_CHANGE_YEAR ? `19${value}` : `20${value}`
  }
  if (value.length === 3) {
    return `2${value}`
  }
  return value
}

const onBlur = () => {
  focusedTimeout.value = setTimeout(() => {
    isFocused.value = false
  }, 100)
}

const handleDayInput = (e: Event) => {
  const value = getInputValue(e)
  dateDay.value = value

  if (value.length > 2) {
    dateDay.value = value.slice(-2)
    handleSelect(dayInputOrder.value + 1)
  }
  if (value.length === 2 && dayInputOrder.value !== 2) {
    handleSelect(dayInputOrder.value + 1)
  }
}

const handleMonthInput = (e: Event) => {
  const value = getInputValue(e)
  dateMonth.value = value

  if (value.length >= 2) {
    dateMonth.value = value.slice(-2)
    handleSelect(monthInputOrder.value + 1)
  }
}

const handleYearInput = (e: Event) => {
  const value = getInputValue(e)
  if (value.length === 4 && yearInputOrder.value !== 2) {
    handleSelect(yearInputOrder.value + 1)
  }
}

const handleDayBlur = (e: Event) => {
  const value = getInputValue(e)
  if (value.length === 1) {
    dateDay.value = `0${value}`
  }

  if (value && +value < 1) {
    dateDay.value = '01'
  }

  if (dayInputOrder.value === 2) {
    emit('blur', e)
  }
  onBlur()
}

const handleMonthBlur = (e: Event) => {
  const value = getInputValue(e)
  if (value.length === 1) {
    dateMonth.value = `0${value}`
  }
  if (value && +value < 1) {
    dateMonth.value = '01'
  }
  onBlur()
}

const handleYearBlur = (e: Event) => {
  const value = getInputValue(e)
  dateYear.value = getYearValue(value)
  updateDate({
    ...dateObject.value,
    year: +dateYear.value,
  })

  if (yearInputOrder.value === 2) {
    emit('blur', e)
  }
  onBlur()
}

watch(
  modelValue,
  (value, prev) => {
    const date = stringToDateTime(value)
    const previousDate = stringToDateTime(prev)
    if (previousDate && date?.equals(previousDate)) return
    dateDay.value = padZero(date?.day || null)
    dateMonth.value = padZero(date?.month || null)
    dateYear.value = date?.year.toString() || null
  },
  { immediate: true },
)
watch([dateDay, dateMonth, dateYear], ([d, m, y]) => {
  fixMaxDayValue()
  updateDate(dateObject.value)
})
</script>

<script lang="ts">
export default {
  name: 'AppDateField',
  inheritAttrs: false,
}
</script>

<style>
@import './styles/data-field.input.css';
</style>
